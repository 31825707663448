
/*
    cardActionButtons: an array holding the card action related GenericButtons this manager should manage

    position: {
        x: x_value, 
        y: y_value, 
        z: depth
    }
    //{x: slot.x, y: slot.y, z: 10}

    buttonSpacing: num y pixels, spacing between center of consecutive buttons

    buttonStateCallback: callback that returns whether at the given time the 
        button in question should be 'INVISIBLE' | 'DISABLED' | 'ENABLED'
*/

const ButtonManager = (cardActionButtons, position, buttonSpacing, buttonStateCallback, endTurnButton, forfeitButton) => {
    
    const managerId = "button_manager";

    var firstButtonPosition = position;

    //array that will hold ALL buttons being managed here
    var managedButtons = [];
    if(cardActionButtons && cardActionButtons.length > 0){
        for(var i = 0; i < cardActionButtons.length; i++){
            managedButtons.push(cardActionButtons[i]);
        }
    }
    else{
        console.log('you have requested the Button Manager to manage an empty arraye (le sigh)');
        return;
    }

    function isEmpty(){
        return managedButtons.length <= 0;
    }

    function redrawButtons(){

        //Redraw the buttons in their newly determined states/positions:
        var nextAvailableYPosition = firstButtonPosition.y;
        managedButtons.forEach(managedButton => {

            var appearance = buttonAppearanceApplicator(managedButton);
            if (appearance === 'INVISIBLE'){
                return; //Invisible buttons shouldn't take up spacing
            }

            managedButton.setPosition(firstButtonPosition.x, nextAvailableYPosition);
            nextAvailableYPosition -= buttonSpacing;
        });

        //Perform the same actions but without positioning on non card-action buttons
        buttonAppearanceApplicator(endTurnButton);
        buttonAppearanceApplicator(forfeitButton);
    }

    function buttonAppearanceApplicator(button){
        var appearance = buttonStateCallback(button);
        switch (appearance) {
            case 'INVISIBLE':
                button.invisiblize();
                return 'INVISIBLE';
            case 'DISABLED':
                button.visiblize();
                button.disableButton();
                return 'DISABLED';
            case 'ENABLED':
                button.visiblize();
                button.enableButton();
                return 'ENABLED';
            default:
                return null;
        }
    }

    return {
        managerId: managerId,
        isEmpty: isEmpty,
        redrawButtons: redrawButtons
    };

}

export default ButtonManager;
