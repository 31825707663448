import React, { useState, useEffect } from 'react'

import { rarityColors } from "../helpers/GlobalStore"
import ArtLoader from "../assets/CardArtProvider"
import nextId from "react-id-generator";

const SlotChance = ({slot}) => {

    const width = 200
    const height = 320

    //Stores the bottom y positions of all the rect elements added so far for the svg.
    //Used to calculate the y position of the currently mapping item.
    var lastFillPos = 0
    var fillHeights = []
    var textYPositions = []

    //A boolean flag that indicates if a chance below 2% is found.
    //If so, adjustments to the layout need to be made to make it visible.
    const  [tinyChanceDetected, setTinyChanceDetected] = useState(false)

    const {getArt} = ArtLoader()
    const [hoveredIndex, setHoveredIndex] = useState(-1)
    const [hoveredPercent, setHoveredPercent] = useState("")
    const [hoveredColor, setHoveredColor] = useState()
    
    useEffect(() => {
        slot.colors.forEach(color => {
            if(color.item2 < 2 && !tinyChanceDetected)
                setTinyChanceDetected(true)
        })
    }, [])

    //Gives the y position that the rect should be placed at, as well as saving the very bottom of this elements y position.
    const getFillYPos = (color, index) => {

        let offset = 0

        if(tinyChanceDetected === true)
            offset = 8
        
        const percent = (parseFloat(color.item2) / 100)
        const yBottom = (percent * (height - 16 - offset))
        let yPlacement = lastFillPos
        lastFillPos += yBottom
        fillHeights.push(yBottom)
        textYPositions.push(lastFillPos)

        return `${yPlacement}`
    }

    const getFillHeight = (color) => {
        
        let offset = 0
        
        if(tinyChanceDetected === true)
            offset = 8

        let finalHeight = `${((parseFloat(color.item2) / 100) * (height - 16 - offset))}`

        if(color.item2 < 2)
            finalHeight = `${(slot.colors.length - 1) * 5}`

        return finalHeight
    }

    const getTextYPos = () => {
        const percentFloat = parseFloat(hoveredPercent)

        if(slot.colors.length === 1){
            return `${(height * (percentFloat / 100)) / 2}`
        }

        else{
            const topPos = textYPositions[hoveredIndex] - fillHeights[hoveredIndex]
            const bottomPos = textYPositions[hoveredIndex]

            //Hovered element is small and at the top
            if(topPos < 40 && bottomPos < 40)
                return "40"
            
            //Hovered element is small and at the bottom
            else if(topPos > height - 50 && bottomPos > height - 50)
                return `${height - 35}`
            

            else return `${textYPositions[hoveredIndex] - ((height * (percentFloat / 100)) / 2) + 16}`
        }
    }

    const handleBoxHoverEnter = (color, index) => {
        setHoveredPercent(color.item2)
        setHoveredIndex(index)
        setHoveredColor(`rgba(${rarityColors[color.item1]}, 1)`)
    }

    const handleBoxHoverExit = () => {
        setHoveredPercent(0)
        setHoveredIndex(-1)
        setHoveredColor("white")
    }

    const getTextXPos = () => {

        if(hoveredPercent !== ""){

            let padding = 35
            let offset = 0
            if(hoveredPercent.includes(".")){
                offset = 2
                padding = 22
            }
    
            const fullWidth = (22 * hoveredPercent.length - offset - 1) + padding 

            const xPos = (width / 2) - (fullWidth / 2) 
            return `${xPos}`
        }
        else return "40%"
    }

    const getTextBoxWidth = () => {

        let padding = 35
        let offset = 0
        if(hoveredPercent.includes(".")){
            offset = 2
            padding = 22
        }

        const fullWidth = (22 * hoveredPercent.length - offset - 1) + padding 

        return fullWidth
    }

    return (
        <div key = {`svg-${slot.slotId}-div`} style = {{backgroundImage: `url(${getArt("SlotBorder")})`, width: width, height: height, zIndex: 999}}>

            <svg xmlns="http://www.w3.org/2000/svg" 
            key = {`svg-${slot.slotId}-svgMain`} 
            transform={`translate(7, 8)`}
            height={height - 16} 
            width={width - 14} 
            id="svg" 
            style = {{
                clipPath: `polygon(6% 0%, 46.5% 0%, 50% 2%, 53.5% 0%, 94% 0%, 100% 4.5%, 100% 14.5%, 97.25% 16.5%, 100% 18.75%, 100% 37.5%, 96.85% 38.5%,  96.5% 40%, 96.5% 61%, 100% 62.75%, 100% 74.5%, 98% 76.2%, 100% 77.75%, 100% 97%, 95.5% 100%, 52.5% 100%, 50% 98%, 47% 100%, 4.5% 100%, 0% 97%, 0% 77.75%, 2.25% 76%, 0% 74.5%, 0% 62.5%, 3% 61.75%, 3.5% 60%, 3.5% 39.5%, 2.8% 38.25%, 0% 37.5%, 0% 18.75%, 3% 16.75%, 0% 14.5%, 0% 4.5%)`,
                zIndex: 800
            }}
            >
                {slot && slot.colors.length > 0 &&
                slot.colors.map((color, index) => (
                    <rect 
                    key = {`svg-${slot.slotId}-rectMain-${index}`} 
                    onMouseOver={() => handleBoxHoverEnter(color, index)} 
                    onMouseLeave={() => handleBoxHoverExit()} 
                    x="0" 
                    y={getFillYPos(color, index)} 
                    width={width - 14} 
                    height={getFillHeight(color)} 
                    fill={`rgba(${rarityColors[color.item1]}, 1)`} 
                    opacity={(hoveredIndex === index) ? "1" : "0.5"}
                    />
                    ))
                }
                
            {hoveredIndex > -1 &&
            <g style = {{pointerEvents: "none"}}>
                <rect
                x={getTextXPos() - 5} 
                y={getTextYPos() - 30} 
                rx="5"
                width={getTextBoxWidth()}
                height="40"
                fill="black"
                stroke="white"
                strokeWidth="2"
                style = {{
                    pointerEvents: "none",
                    clipPath: `polygon(0% 0%, 100% 0%, 95% 50%, 100% 100%, 0% 100%, 5% 50%)`
                }}
                />

                <text 
                x={getTextXPos()} 
                y={getTextYPos()} 
                fontSize="32" 
                fontWeight="bold" 
                fill={hoveredColor}
                style = {{pointerEvents: "none"}}
                >
                    {`${hoveredPercent}%`}
                </text>
            </g>
            }
            </svg>
        </div>
    )

}

export default SlotChance