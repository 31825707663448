import React from "react";
import { SocialIcon } from 'react-social-icons';
import { Grid } from '@material-ui/core';

const SocialMediaFooter = () => {
    return (
        <Grid
            className='footerStyles' 
            container 
            id="bottom-row"
            style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Grid item style={{ marginRight: "15px", justifyContent: 'center', alignItems: 'center' }}>
                <SocialIcon url="https://discord.gg/arenaofexiles" className="socialIcon" />
            </Grid>
            <Grid item style={{ marginRight: "15px", justifyContent: 'center', alignItems: 'center' }}>
                <SocialIcon url="https://www.instagram.com/arenaofexilesofficial/" className="socialIcon" />
            </Grid>
            <Grid item style={{ marginRight: "15px", justifyContent: 'center', alignItems: 'center' }}>
                <SocialIcon url="https://twitter.com/ArenaOfExiles" className="socialIcon" />
            </Grid>
            <Grid item style={{ justifyContent: 'center', alignItems: 'center' }}>
                <SocialIcon url="https://medium.com/@arenaofexiles/arena-of-exiles-nft-game-launch-563b7689cce0" className="socialIcon" />
            </Grid>
        </Grid>
    )
}

export default SocialMediaFooter; 
