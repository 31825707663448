
export const updates = [
    {
        title: 'New Attack Animations',
        content: 'Added attack animations for the major elements in the game: fire, ice, lightning, light, poison.',
        date: '03-23-2022',
    },
    {
        title: 'Game Mobile Layout',
        content: 'Added a mobile layout to the game for players on mobile and tablet!',
        date: '03-15-2022',
    },
    {
        title: 'Deck Builder 2.0',
        content: 'Updated deck builder to streamline deck building process. Added a componenet to show deck balance and improve deck modification. Improved mobile usability.',
        date: '02-28-2022',
    },
]