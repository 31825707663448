import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; 
import './App.css';
import { Grid } from '@material-ui/core';
import React from "react";
import { useEffect } from 'react';
//import debounce from 'lodash.debounce';
import { ContractAPIProvider } from './helpers/ContractAPI';
import { BackendAPIProvider } from './helpers/BackendAPI';
import { AccountsProvider } from './helpers/AccountsProvider';

import AdminPage from './pages/AdminPage.js';
import NavBar from './components/NavBar.js';
import InventoryPage from './pages/InventoryPage.js'
import HomePage from './pages/HomePage.js'
import GamePage from './pages/GamePage.js'
import StorePage from './pages/StorePage.js'
import DecksPage from './pages/DecksPage.js'
import ProfilePage from './pages/ProfilePage';
import LorePage from './pages/LorePage';
import GuidePage from './pages/GuidePage'
import { Web3ReactProvider } from '@web3-react/core';
import { getLibrary } from './helpers/web3ReactUtil';
import CreditsPage from './pages/CreditsPage';
import { useStore } from './helpers/GlobalStore';

const App = () => {

  const {isGameLoaded, gameLoading} = useStore()

  return (
    <Grid id="AppMainGrid" container style={{alignItems:'center', justifyContent:'center'}}>
      <AccountsProvider>
        <ContractAPIProvider>
          <BackendAPIProvider>
            <Router>
              <>
                {isGameLoaded === false && gameLoading === false &&
                  <Grid item xs={12} id="AppNavGrid" style={{ height: 65 }}>
                    <NavBar/>
                  </Grid>
                }
              </>
              <Grid item id="AppRouteSwitch">
                <Switch>
                  <Route path="/admin" component={AdminPage} />
                  <Route path="/inventory" component={InventoryPage}/>
                  <Route path="/decks" component={DecksPage} />
                  <Route path="/game" component={GamePage} />
                  <Route path="/store" component={StorePage} />
                  <Route path="/profile" component={ProfilePage} />
                  <Route path="/lore" component={LorePage} />
                  <Route path="/guide" component={GuidePage} />
                  <Route path="/credits" component={CreditsPage} />
                  <Route exact path="/" component={HomePage} />
                </Switch>
              </Grid>
            </Router>
          </BackendAPIProvider>
        </ContractAPIProvider>
      </AccountsProvider>
    </Grid>
  )
}

export default App;
