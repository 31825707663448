import React, { useState } from "react";
import { Button, makeStyles, Grid, Typography, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper, styled } from '@material-ui/core';
import { useBackendAPI } from '../helpers/BackendAPI';

const AdminBaseCardComponent = () => {
    const styles = useStyles();
    const { createBaseCard } = useBackendAPI();
    const [fileName, setFileName] = useState("")
    const [jsonData, setJsonData] = useState(undefined)

    //Prompts the user to select an image file stored locally on thier machine.
    //Selected file is read and buffered, then passed to the IPFS uploader.
    const captureFile = (param) => {
        var reader = new FileReader();

        if(param !== undefined)
            reader.readAsText(param)

        reader.onloadend = () => {
            try{
                setFileName(param.name)
                setJsonData(JSON.parse(reader.result))
            }
            catch(err) {
                console.log("Invalid JSON file selected!")
            }
        }

    }

    const handleUpload = async () => {
        jsonData.map(async card => {

            const apiBaseData = {
                CardName: card.name,
                Description: card.description,
                MediaHash: card.cardId,
                Season: card.season,
                Edition: card.edition,
                Rarity: card.rarity,
                MaxCap: card.maxCap,
                Type:(card.cardMetaData.type.toLowerCase() === "monster") ? 0 : 1
            }

            await createBaseCard(apiBaseData)
            .then(() => {
                setJsonData(undefined)
                setFileName("")
                console.log("API Upload of base cards successful")
            })
            .catch(err => {
                console.log(`API Upload failed for card:  ${card.name}`)
                console.log(err)
            })

        })
    }

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "white",
    },
    '&:nth-of-type(even)': {
        backgroundColor: "#adadad",
        },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    }));


    return (
        <Grid container justify = "center" spacing = {4}>

            <Grid item>
                <Button className = "buttonRibbon" component="label">
                    Select File
                    <input type='file' id='file' hidden onChange={(e) => captureFile(e.target.files[0])}/>
                </Button>
            </Grid>

            {jsonData !== undefined &&
            <Grid item>
                <Button className = "buttonRibbon" component="label" onClick = {() => handleUpload()}>
                    Upload Data
                </Button>
            </Grid>
            }

            {jsonData !== undefined &&
            <Grid 
            item 
            xs = {12}
            style = {{
                marginTop: 50,
                marginBottom: 25
            }}
            >
                <Typography variant = "h3" className = {`${styles.textWhite} ${styles.textCentered}`}>{fileName}</Typography>
            </Grid>
            }

            {jsonData !== undefined && 
            <Grid item xs = {12} className = {styles.tableContainer}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Card Id</TableCell>
                                <TableCell align="center">Name</TableCell>
                                <TableCell align="center">Description</TableCell>
                                <TableCell align="center">Season</TableCell>
                                <TableCell align="center">Edition</TableCell>
                                <TableCell align="center">Rarity</TableCell>
                                <TableCell align="center">Max Cap</TableCell>
                                <TableCell align="center">Type</TableCell>
                                <TableCell align="center">Summon Cost</TableCell>
                                <TableCell align="center">Health</TableCell>
                                <TableCell align="center">Attack</TableCell>
                                <TableCell align="center">Attack Cost</TableCell>
                                <TableCell align="center">Use Cost</TableCell>
                                <TableCell align="center">Health Gain</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {jsonData.map((row, index) => (
                            <StyledTableRow key={row.cardId}>
                                <TableCell align="left" index = {index}>{row.cardId}</TableCell>
                                <TableCell align="left" index = {index}>{row.name}</TableCell>
                                <TableCell align="left" index = {index}>{row.description}</TableCell>
                                <TableCell align="left" index = {index}>{row.season}</TableCell>
                                <TableCell align="left" index = {index}>{row.edition}</TableCell>
                                <TableCell align="left" index = {index}>{row.rarity}</TableCell>
                                <TableCell align="left" index = {index}>{row.maxCap}</TableCell>
                                <TableCell align="left" index = {index}>{row.cardMetaData.type}</TableCell>
                                <TableCell align="left" index = {index}>{row.cardMetaData.summonCost}</TableCell>
                                <TableCell align="left" index = {index}>{row.cardMetaData.health}</TableCell>
                                <TableCell align="left" index = {index}>{row.cardMetaData.attack}</TableCell>
                                <TableCell align="left" index = {index}>{row.cardMetaData.attackCost}</TableCell>
                                <TableCell align="left" index = {index}>{row.cardMetaData.useCost}</TableCell>
                                <TableCell align="left" index = {index}>{row.cardMetaData.healthGain}</TableCell>
                            </StyledTableRow >
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            }
        </Grid>
    )

}

const useStyles = makeStyles((theme) => ({
    textWhite: {
        color: "white"
    },

    textCentered: {
        textAlign: "center"
    },

    gridItemCentered:{
        display: "flex",
        justifyContent: "center",
        justifyItems: "center"
    },

    tableContainer: {
        width: "auto",
        maxWidth: "75vw",
        height: "auto",
        maxHeight: "50vh",
        overflowX: "scroll",
        overflowY: "scroll"
    }
}))

export default AdminBaseCardComponent;