import React, { createContext, useContext, useState } from "react";

export const AccountsContext = createContext();

export const AccountsProvider = ({children}) => {
    const [hasAccountChanged, setHasAccountChanged] = useState(false)
    
    const refreshAccounts = async (accounts) => {
        setHasAccountChanged(true)
    }

    if(window.ethereum !== undefined && window.ethereum !== null) {
        window.ethereum.on('accountsChanged', refreshAccounts)
    }

    return (
    <AccountsContext.Provider value = {
    {
        hasAccountChanged, setHasAccountChanged
    }}>

        {children}

    </AccountsContext.Provider>
    )

}
export const useAccount = () => useContext(AccountsContext);