import HashMap from 'hashmap';

//Cards
import ahktcha from './images/BaseCards/QmdWiR4Z5ghtt8rF1h5oGyunLgbhfvdyXUdzCfgMB4nU84.png';
import aivar from './images/BaseCards/Qme8ZniwXBCcZFWirVG8r36MnEhd2dNu4i4zP44ENhodxm.png';
import apparition from './images/BaseCards/QmPy2T1DQqGngp1HaCPYJd5DCSC3utHxej671deyTRNTqk.png';
import astershade from './images/BaseCards/QmQ1eB7mVx6ABjbTCGfm78WnAKt2gw6CaB9T4Px63MKK15.png';
import astra from './images/BaseCards/QmZJnzfxr46d9rnxa6g25uMqkAoyguSrJg543pQ1Etu3xG.png';
import avna from './images/BaseCards/QmU8W9DQujCjfYqcL5gMwPVvnYwUSYCphT6oVvaHPFEHL1.png';
import bjorn from './images/BaseCards/Qmd2zih9QVi5wnKXDZS77ki3icEsuWhbRkKfKVGxTHGhey.png';
import blindmystic from './images/BaseCards/QmRAYzqb1WuJy6RwSxcLPa3PSE6HenJ2ECsyXWsSAc2hGR.png';
import duho from './images/BaseCards/QmX8qsvdFstmdg2XMKE7NczQswSFppizuHcCA2riHiVYkN.png';
import edgar from './images/BaseCards/QmNQHjQfu4fNT49Xw3gvDALhxfQ2eiaxKpGb38XjrCdrvm.png';
import evalyn from './images/BaseCards/QmZ2eeXb9Mf6qNoTSbXJ61qQcwcv12FdzXGr96Qt9U7tgE.png';
import exilestonic from './images/BaseCards/QmXRwNhEnkrL25kwa16MBc12VLpN3X83MW9bxstG7TbkQ4.png';
import flamebones from './images/BaseCards/QmWHdQKZkq9KfTe5jeG8xSr4HxJJAk3FAFVDRrsiAc8iza.png';
import harpy from './images/BaseCards/QmXeJqx226dSLVgwas6z1Pwq1LTTd4qiWLj86tRA2TuSEu.png';
import igmund from './images/BaseCards/Qmdk9RASMJ254B2fD8CAYXU5apwUfqbp19qTCWoQaQY7Nt.png';
import ingdoom from './images/BaseCards/QmdMuEGSmaVnUWZnwycX3z6LCJUQwzraaU95vyE1vaXzKC.png';
import juveniledragon from './images/BaseCards/QmZ1Bk8LSY9zUsPhGRJLQVFLzF1XUMcpS7dyhx6cZ3aWnY.png';
import karaketh from './images/BaseCards/QmNrn7HDZ6ngDQRkP2dxfAjkSojfXGyMbrukLwHgdSkXAE.png';
import kragus from './images/BaseCards/QmaxjW3S4wQHEPuYNLmKBJa5xUMBnyMdXmHgJJcGE1ntQz.png';
import litheu from './images/BaseCards/QmQvmjYa99pRuRUsgeZKfdRgu8PFxW2Y7yaPj92zbMKHdm.png';
import mediumtonic from './images/BaseCards/QmWVYuTD3u5dfYoVaHcTeBFiCiiYxL7FEnvxP68sQJHTXS.png';
import melanna from './images/BaseCards/QmU8uS49836sahwWDDdXPBiAGa6UmM4Buq8SziKY7V18PB.png';
import mogath from './images/BaseCards/QmWr79GpCShx3QCGVaWqscXmmgArpGoKM17coUqzgs6jrc.png';
import mugong from './images/BaseCards/Qma7nF1u8qzQ6x64fX7xW3Vf8p9Z8ogR2vb8xAW1W8vB7x.png';
import mummy from './images/BaseCards/QmeGHwxKyzXenxQ7phRad3SGN6BKzsyixGH6tqBb8raEVb.png';
import odra from './images/BaseCards/Qmatvsrw5QfW2Q3do4HFsndtJUdQYrhzHaFZbCYpkdHNhb.png';
import phia from './images/BaseCards/QmWp8tdHMcCFyMdsEmseKKCLqmYs75qfbKsjrWttqh96nF.png';
import raide from './images/BaseCards/Qmaz2KDWWHF39KGo65c6ZjaoejpKwrvxqAtZh3ufzWYJQr.png';
import shaira from './images/BaseCards/QmNSLH5GKSE5t3ezdeXrkKbzYAGqrn6uEUTRvje6conJUm.png';
import simpletonic from './images/BaseCards/Qmeawa3G3e1BNVq7CJ5yb697GrN4tYXeouv9oEopJd78xd.png';
import sketh from './images/BaseCards/QmSQBmBpfzzGU99dnJ7DpVsah9zSC36zjDbGUdQqxpob1b.png';
import solomonsalazar from './images/BaseCards/QmNvMiCJqMVfXdgH7EHyF9rFPx5BV9Jm6j6xdSvZXegRNL.png';
import sukra from './images/BaseCards/QmUNEVH6uuchYLpeSrFFg5hDDQkKpd5BdBusSYVfMUDKYQ.png';
import theherald from './images/BaseCards/QmefBu7FK1Zow9gCtpd4575J91gQEzwh8uCNZFVbiM1GiR.png';
import tribalchief from './images/BaseCards/QmQt6kmt1ZwuJNkjBRR3bQzcrYXst2kPicVHCR5uxgyZsC.png';
import undeadknight from './images/BaseCards/QmSbgdss6oKU97pmvVQLoCAibek26BgrvYXeyiiaDDc3Er.png';
import vierlorn from './images/BaseCards/QmQLE2QiV85BphqSCaQagD4UXMuLG2xDrtuQHqvQEp5bwu.png';
import werewolf from './images/BaseCards/QmZfMozUEWnhPav8D6UJdibEgQdGv8hFWdBJ9PFM54aqkr.png';
import wraith from './images/BaseCards/QmPZsFMwiwXWpM1JK2YP2cjPesogYViu3Ye29YZfniHHp5.png';
import yegno from './images/BaseCards/QmPGuKpWHubSteakXnPS7UVmptaHgUQ8JU5571G9N8fvp5.png';

//Promo Pack
import akhtchapromo from './images/BaseCards/QmXyTDGE5eGnBFi1zEEhRfPUjpdSrRoHDjgKm9gvuexB3F.png';
import apparitionpromo from './images/BaseCards/QmRbfUahorAbJTFWAB1YzmUGUdHfhtoKSsU3t9JZ2guuTV.png';
import avnapromo from './images/BaseCards/QmZGj9vNphpCDCNy93diD875VQ14aechxdBKYNQmx7ir8d.png';
import duhopromo from './images/BaseCards/QmQ6j7FWS6K9AQrVbxvogd6svWXACjxoLXG59jye7QTqv3.png';
import heraldpromo from './images/BaseCards/QmaiRjUowBhgt9gUUn7BjbNRVgjGQQNBDgdAio3wicSDVZ.png';
import ingdoompromo from './images/BaseCards/Qme58K4usYU78veauTVVNtg8zJUdgTiDeKgPMKV7ohTM1n.png';
import dragonpromo from './images/BaseCards/QmUjai4HYBmDzSxJ6BkaMGCCsN99xsqJ49SgimrnSg5xQ5.png';
import litheupromo from './images/BaseCards/QmYMtF8TXycWCDDb9vYyd176QKvEQqu8r5Dt3DqnfNzpyJ.png';
import vierlornpromo from './images/BaseCards/QmSUBUXt5kcDkwiJx3VX3za1pFPnWq4JTnZJKtw8T3j3cS.png';
import werewolfpromo from './images/BaseCards/QmZgJ7jMu9tGPGYqmfhfie7NSw6z3RS5ruyLZgwLdzmp58.png';

//Tundra Pack
import frozentundra1 from './images/NonPlayableCards/QmcrST79ZqVnQ3EJBUaiapGFhBTf4dSet8eTKFATLfFPdE.png';
import frozentundra2 from './images/NonPlayableCards/QmZAEiQ2uQtZ87gA76HeMWFtfPAfBFBkQFNYCF4Jbgzs4m.png';
import frozentundra3 from './images/NonPlayableCards/QmeLboDGazXh2G1TKdDASyeB5XDMaARppZukcvtsQcD918.png';
import frozentundra4 from './images/NonPlayableCards/QmNuHGLhG9qN8EYMc3hc8azC6oFYMmgXfDBVaEd2pkhJJi.png';
import frozentundra5 from './images/NonPlayableCards/QmeunUcEJYtL6i6RcHgE4vbHEm76nb99aegmeyjN9yoDHt.png';
import frozentundra6 from './images/NonPlayableCards/QmU9NxD8nK7wYFRhXLPpYCRBTNJCedNYR2q7KvRR7HVvMF.png';
import frozentundra7 from './images/NonPlayableCards/QmS6qfEDPHY2XuG8MeNoSwUQm8qWeoUxjJz2gM5dwszvDD.png';
import frozentundra8 from './images/NonPlayableCards/QmTq3i4FZUtPGK7ZsrFSYJ6cZNLuhkEiuZYPfoS1hHhyL9.png';
import frozentundra9 from './images/NonPlayableCards/QmUuH3yGXaP835jrq1dM2gyY4Dxa1y34aGerps79Uquogd.png';
import frozentundra10 from './images/NonPlayableCards/QmRDdMLXF8THTakADnec9Q3GLJ7o2wV51teMQrSdXyxzvG.png';
import frozentundra11 from './images/NonPlayableCards/QmZx95kMFnUgEntaLNW6YdcPQKALbr8tvm9xH6v8VvcTSd.png';
import frozentundra12 from './images/NonPlayableCards/Qmev53EWuQgZW2gudCmZuAnYkrJkbGeSR7CV7WE1mpZx9E.png';

//Card Backs
import commoncardback from "./images/CardPackImages/common-card-back.png"
import uncommoncardback from "./images/CardPackImages/uncommon-card-back.png"
import rarecardback from "./images/CardPackImages/rare-card-back.png"
import epiccardback from "./images/CardPackImages/epic-card-back.png"
import legendarycardback from "./images/CardPackImages/legendary-card-back.png"

//Card Packs
import commonpack from "./images/CardPackImages/AoE-Common-Pack-Small.png"
import uncommonpack from "./images/CardPackImages/AoE-Uncommon-Pack-Small.png"
import rarepack from "./images/CardPackImages/AoE-Rare-Pack-Small.png"
import epicpack from "./images/CardPackImages/AoE-Epic-Pack-Small.png"
import legendarypack from "./images/CardPackImages/AoE-Legendary-Pack-Small.png"
import feelinglucky from "./images/CardPackImages/Feeling-Lucky-Small.png"
import promopackbeta from "./images/CardPackImages/Promo-Pack-Beta.png"
import promopacksketch from "./images/CardPackImages/Promo-Pack-Sketch.png"

//Backgrounds
import GamePageBackgroundImg from "../assets/images/Backgrounds/game-page-bg.png";
import LorePageBackgroundImg from "../assets/images/Backgrounds/lore-page-bg.png";
import StorePageBackgroundImg from "../assets/images/Backgrounds/store-page-bg.png";
import InventoryBG from "../assets/images/Backgrounds/inventory-page-bg.png";
import ProfileBG from "../assets/images/Backgrounds/profile-page-bg.png";
import DeckPageBG from "../assets/images/Backgrounds/deck-page-bg.png";

//Website Art Assets
import BuyOpenInfographic from "../assets/images/buy-open-infographic.png";
import BuySellInfographic from "../assets/images/buy-sell-infographic.png";
import PlayEarnInfographic from "../assets/images/play-earn-infographic.png";
import ArtByInfographic from "../assets/images/art-done-by-infographic.png";
import ArtByInfographicMobile from "../assets/images/art-done-by-info-mobile.png";
import MilestonesImg from "../assets/images/milestones.png";
import MilestonesImgMobile from "../assets/images/milestones-mobile.png";
import TheUnforgivenImg from "../assets/images/Backgrounds/the-unforgiven.png";
import ComingSoon from "../assets/images/coming-soon.png";
import DownForMaintenance from "../assets/images/down-for-maintenence.png";
import SlotBorder from "../assets/images/SlotBorder.png";
import AppbarLogoFull from "../assets/images/appbar-logo-full.png"
import AppbarLogoMedium from "../assets/images/appbar-logo-medium.png"
import AppbarLogoSmall from "../assets/images/appbar-logo-small.png"

//Homepage art assets
import Segment1BG from "../assets/images/segment-1-bg.png"
import Segment2BG from "../assets/images/segment-2-bg.png"
import Segment3BG from "../assets/images/segment-3-bg.png"
import Segment4BG from "../assets/images/segment-4-bg.png"
import MugongFPHover from "../assets/images/mugongHover.webm"
import BjornFPHover from "../assets/images/bjornHover.webm"
import AstershadeFPHover from "../assets/images/astershadeHover.webm"
import HarpyFPHover from "../assets/images/harpyHover.webm"
import RaideFPHover from "../assets/images/raideHover.webm"
import DeckBack from "../assets/images/deck-back.png"

const CardArtProvider = () => {
    const artMap = new HashMap()

    //Base cards
    artMap.set("QmdWiR4Z5ghtt8rF1h5oGyunLgbhfvdyXUdzCfgMB4nU84", ahktcha)
    artMap.set("Qme8ZniwXBCcZFWirVG8r36MnEhd2dNu4i4zP44ENhodxm", aivar)
    artMap.set("QmPy2T1DQqGngp1HaCPYJd5DCSC3utHxej671deyTRNTqk", apparition)
    artMap.set("QmQ1eB7mVx6ABjbTCGfm78WnAKt2gw6CaB9T4Px63MKK15", astershade)
    artMap.set("QmZJnzfxr46d9rnxa6g25uMqkAoyguSrJg543pQ1Etu3xG", astra)
    artMap.set("QmU8W9DQujCjfYqcL5gMwPVvnYwUSYCphT6oVvaHPFEHL1", avna)
    artMap.set("Qmd2zih9QVi5wnKXDZS77ki3icEsuWhbRkKfKVGxTHGhey", bjorn)
    artMap.set("QmRAYzqb1WuJy6RwSxcLPa3PSE6HenJ2ECsyXWsSAc2hGR", blindmystic)
    artMap.set("QmX8qsvdFstmdg2XMKE7NczQswSFppizuHcCA2riHiVYkN", duho)
    artMap.set("QmNQHjQfu4fNT49Xw3gvDALhxfQ2eiaxKpGb38XjrCdrvm", edgar)
    artMap.set("QmZ2eeXb9Mf6qNoTSbXJ61qQcwcv12FdzXGr96Qt9U7tgE", evalyn)
    artMap.set("QmXRwNhEnkrL25kwa16MBc12VLpN3X83MW9bxstG7TbkQ4", exilestonic)
    artMap.set("QmWHdQKZkq9KfTe5jeG8xSr4HxJJAk3FAFVDRrsiAc8iza", flamebones)
    artMap.set("QmXeJqx226dSLVgwas6z1Pwq1LTTd4qiWLj86tRA2TuSEu", harpy)
    artMap.set("Qmdk9RASMJ254B2fD8CAYXU5apwUfqbp19qTCWoQaQY7Nt", igmund)
    artMap.set("QmdMuEGSmaVnUWZnwycX3z6LCJUQwzraaU95vyE1vaXzKC", ingdoom)
    artMap.set("QmZ1Bk8LSY9zUsPhGRJLQVFLzF1XUMcpS7dyhx6cZ3aWnY", juveniledragon)
    artMap.set("QmNrn7HDZ6ngDQRkP2dxfAjkSojfXGyMbrukLwHgdSkXAE", karaketh)
    artMap.set("QmaxjW3S4wQHEPuYNLmKBJa5xUMBnyMdXmHgJJcGE1ntQz", kragus)
    artMap.set("QmQvmjYa99pRuRUsgeZKfdRgu8PFxW2Y7yaPj92zbMKHdm", litheu)
    artMap.set("QmWVYuTD3u5dfYoVaHcTeBFiCiiYxL7FEnvxP68sQJHTXS", mediumtonic)
    artMap.set("QmU8uS49836sahwWDDdXPBiAGa6UmM4Buq8SziKY7V18PB", melanna)
    artMap.set("QmWr79GpCShx3QCGVaWqscXmmgArpGoKM17coUqzgs6jrc", mogath)
    artMap.set("Qma7nF1u8qzQ6x64fX7xW3Vf8p9Z8ogR2vb8xAW1W8vB7x", mugong)
    artMap.set("QmeGHwxKyzXenxQ7phRad3SGN6BKzsyixGH6tqBb8raEVb", mummy)
    artMap.set("Qmatvsrw5QfW2Q3do4HFsndtJUdQYrhzHaFZbCYpkdHNhb", odra)
    artMap.set("QmWp8tdHMcCFyMdsEmseKKCLqmYs75qfbKsjrWttqh96nF", phia)
    artMap.set("Qmaz2KDWWHF39KGo65c6ZjaoejpKwrvxqAtZh3ufzWYJQr", raide)
    artMap.set("QmNSLH5GKSE5t3ezdeXrkKbzYAGqrn6uEUTRvje6conJUm", shaira)
    artMap.set("Qmeawa3G3e1BNVq7CJ5yb697GrN4tYXeouv9oEopJd78xd", simpletonic)
    artMap.set("QmSQBmBpfzzGU99dnJ7DpVsah9zSC36zjDbGUdQqxpob1b", sketh)
    artMap.set("QmNvMiCJqMVfXdgH7EHyF9rFPx5BV9Jm6j6xdSvZXegRNL", solomonsalazar)
    artMap.set("QmUNEVH6uuchYLpeSrFFg5hDDQkKpd5BdBusSYVfMUDKYQ", sukra)
    artMap.set("QmefBu7FK1Zow9gCtpd4575J91gQEzwh8uCNZFVbiM1GiR", theherald)
    artMap.set("QmQt6kmt1ZwuJNkjBRR3bQzcrYXst2kPicVHCR5uxgyZsC", tribalchief)
    artMap.set("QmSbgdss6oKU97pmvVQLoCAibek26BgrvYXeyiiaDDc3Er", undeadknight)
    artMap.set("QmQLE2QiV85BphqSCaQagD4UXMuLG2xDrtuQHqvQEp5bwu", vierlorn)
    artMap.set("QmZfMozUEWnhPav8D6UJdibEgQdGv8hFWdBJ9PFM54aqkr", werewolf)
    artMap.set("QmPZsFMwiwXWpM1JK2YP2cjPesogYViu3Ye29YZfniHHp5", wraith)
    artMap.set("QmPGuKpWHubSteakXnPS7UVmptaHgUQ8JU5571G9N8fvp5", yegno)

    //Promo Cards
    artMap.set("QmXyTDGE5eGnBFi1zEEhRfPUjpdSrRoHDjgKm9gvuexB3F", akhtchapromo)
    artMap.set("QmRbfUahorAbJTFWAB1YzmUGUdHfhtoKSsU3t9JZ2guuTV", apparitionpromo)
    artMap.set("QmZGj9vNphpCDCNy93diD875VQ14aechxdBKYNQmx7ir8d", avnapromo)
    artMap.set("QmQ6j7FWS6K9AQrVbxvogd6svWXACjxoLXG59jye7QTqv3", duhopromo)
    artMap.set("QmaiRjUowBhgt9gUUn7BjbNRVgjGQQNBDgdAio3wicSDVZ", heraldpromo)
    artMap.set("Qme58K4usYU78veauTVVNtg8zJUdgTiDeKgPMKV7ohTM1n", ingdoompromo)
    artMap.set("QmUjai4HYBmDzSxJ6BkaMGCCsN99xsqJ49SgimrnSg5xQ5", dragonpromo)
    artMap.set("QmYMtF8TXycWCDDb9vYyd176QKvEQqu8r5Dt3DqnfNzpyJ", litheupromo)
    artMap.set("QmSUBUXt5kcDkwiJx3VX3za1pFPnWq4JTnZJKtw8T3j3cS", vierlornpromo)
    artMap.set("QmZgJ7jMu9tGPGYqmfhfie7NSw6z3RS5ruyLZgwLdzmp58", werewolfpromo)

    //BG Cards
    artMap.set("QmcrST79ZqVnQ3EJBUaiapGFhBTf4dSet8eTKFATLfFPdE", frozentundra1)
    artMap.set("QmZAEiQ2uQtZ87gA76HeMWFtfPAfBFBkQFNYCF4Jbgzs4m", frozentundra2)
    artMap.set("QmeLboDGazXh2G1TKdDASyeB5XDMaARppZukcvtsQcD918", frozentundra3)
    artMap.set("QmNuHGLhG9qN8EYMc3hc8azC6oFYMmgXfDBVaEd2pkhJJi", frozentundra4)
    artMap.set("QmeunUcEJYtL6i6RcHgE4vbHEm76nb99aegmeyjN9yoDHt", frozentundra5)
    artMap.set("QmU9NxD8nK7wYFRhXLPpYCRBTNJCedNYR2q7KvRR7HVvMF", frozentundra6)
    artMap.set("QmS6qfEDPHY2XuG8MeNoSwUQm8qWeoUxjJz2gM5dwszvDD", frozentundra7)
    artMap.set("QmTq3i4FZUtPGK7ZsrFSYJ6cZNLuhkEiuZYPfoS1hHhyL9", frozentundra8)
    artMap.set("QmUuH3yGXaP835jrq1dM2gyY4Dxa1y34aGerps79Uquogd", frozentundra9)
    artMap.set("QmRDdMLXF8THTakADnec9Q3GLJ7o2wV51teMQrSdXyxzvG", frozentundra10)
    artMap.set("QmZx95kMFnUgEntaLNW6YdcPQKALbr8tvm9xH6v8VvcTSd", frozentundra11)
    artMap.set("Qmev53EWuQgZW2gudCmZuAnYkrJkbGeSR7CV7WE1mpZx9E", frozentundra12)

    //Card Packs    
    artMap.set("AoE-Common-Pack-Small", commonpack)
    artMap.set("AoE-Uncommon-Pack-Small", uncommonpack)
    artMap.set("AoE-Rare-Pack-Small", rarepack)
    artMap.set("AoE-Epic-Pack-Small", epicpack)
    artMap.set("AoE-Legendary-Pack-Small", legendarypack)
    artMap.set("Feeling Lucky", feelinglucky)
    artMap.set("Promo Pack - Beta", promopackbeta)
    artMap.set("Promo Pack - Sketch", promopacksketch)

    //Card Backs    
    artMap.set("common-card-back", commoncardback)
    artMap.set("uncommon-card-back", uncommoncardback)
    artMap.set("rare-card-back", rarecardback)
    artMap.set("epic-card-back", epiccardback)
    artMap.set("legendary-card-back", legendarycardback)

    //Backgrounds
    artMap.set("GamePageBG", GamePageBackgroundImg)
    artMap.set("LorePageBG", LorePageBackgroundImg)
    artMap.set("StorePageBG", StorePageBackgroundImg)
    artMap.set("InventoryBG", InventoryBG )
    artMap.set("ProfileBG", ProfileBG)
    artMap.set("DeckPageBG", DeckPageBG)

    //Website Art Assets
    artMap.set("BuyOpenInfographic", BuyOpenInfographic)
    artMap.set("BuySellInfographic", BuySellInfographic)
    artMap.set("PlayEarnInfographic", PlayEarnInfographic)
    artMap.set("ArtByInfographic", ArtByInfographic)
    artMap.set("ArtByInfographicMobile", ArtByInfographicMobile)
    artMap.set("Milestones", MilestonesImg)
    artMap.set("MilestonesMobile", MilestonesImgMobile)
    artMap.set("TheUnforgiven", TheUnforgivenImg)
    artMap.set("ComingSoon", ComingSoon)
    artMap.set("DownForMaintenance", DownForMaintenance)
    artMap.set("SlotBorder", SlotBorder)
    artMap.set("AppbarLogoFull", AppbarLogoFull)
    artMap.set("AppbarLogoMedium", AppbarLogoMedium)
    artMap.set("AppbarLogoSmall", AppbarLogoSmall)
    
    //Homepage Art Assets
    artMap.set("HomepageSegment1BG", Segment1BG)
    artMap.set("HomepageSegment2BG", Segment2BG)
    artMap.set("HomepageSegment3BG", Segment3BG)
    artMap.set("HomepageSegment4BG", Segment4BG)
    artMap.set("HomepageMugongHover", MugongFPHover)
    artMap.set("HomepageBjornHover", BjornFPHover)
    artMap.set("HomepageAstershadeHover", AstershadeFPHover)
    artMap.set("HomepageHarpyHover", HarpyFPHover)
    artMap.set("HomepageRaideHover", RaideFPHover)
    artMap.set("DeckBack", DeckBack)
  
    
    function getArt(artName) {
        return artMap.get(artName)
    }

    return ({
        getArt: getArt
    })
}

export default CardArtProvider
