import { useRef } from 'react'
import { Zoom, Slide, Fade, Grow } from '@material-ui/core';
import { useInViewport } from 'react-in-viewport';

const WatchedElement = ({child, index, animationType, direction}) => {
    const childRef = useRef();
    const {
        inViewport,
        enterCount,
        leaveCount,
    } = useInViewport(
        childRef,
    );
    
    switch(animationType){
        case 'zoom':
            return(
                <div ref={childRef} style={{padding: 0, margin: 0, color:'white'}}>
                    <Zoom
                    in={enterCount>=1}
                    style = {{
                        transitionDelay: `${250 + (index * 100) }ms`
                    }}
                    >
                        {child} 
                    </Zoom>
                </div>
            )
        case 'slide':
            return(
                <div ref={childRef} style={{padding: 0, margin: 0, color:'white'}}>
                    <Slide
                    in={enterCount>=1}
                    easing='ease'
                    direction={direction}
                    style = {{
                        transitionDelay: `${250 + (index * 100) }ms`
                    }}
                    >
                        {child} 
                    </Slide>
                </div>
            )
        case 'fade':
            return(
                <div ref={childRef} style={{padding: 0, margin: 0, color:'white'}}>
                    <Fade
                    in={enterCount>=1}
                    easing='ease'
                    style = {{
                        transitionDelay: `${250 + (index * 100) }ms`
                    }}
                    >
                        {child} 
                    </Fade>
                </div>
                )
        case 'grow':
            return(
                <div ref={childRef} style={{padding: 0, margin: 0, color:'white'}}>
                    <Grow
                    in={enterCount>=1}
                    easing='ease'
                    style = {{
                        transitionDelay: `${250 + (index * 100) }ms`
                    }}
                    >
                        {child} 
                    </Grow>
                </div>
                )
        default:
            return(
                <div ref={childRef} style={{padding: 0, margin: 0, color:'white'}}>
                    <Zoom
                    in={enterCount>=1}
                    easing='ease'
                    style = {{
                        transitionDelay: `${250 + (index * 100) }ms`
                    }}
                    >
                        {child} 
                    </Zoom>
                </div>
            )
    }
}

export default WatchedElement;