import React, { useState, useEffect, useReducer, useRef } from 'react'
import { useHistory } from "react-router-dom";
import { Grid, Typography, makeStyles } from '@material-ui/core';
import SocialMediaFooter from '../components/SocialMediaFooter';
import ArtLoader from "../assets/CardArtProvider"
import SvgButton from "../components/SvgButton"
import { useWindowDimensions } from '../helpers/windowDimentionProvider';
import WatchedElement from '../components/WatchedElement';
import { useStore } from '../helpers/GlobalStore';
import { updates } from '../assets/content/updates';
import {arenaFuture} from '../assets/content/arenaFuture'

const { getArt } = ArtLoader();

const getCardWidth = (width) => {
    if(width > 2900){
        return 350
    }
    else if(width > 2220 && width <= 2900){
        return 350
    }
    else if(width >= 1920 && width <= 2220){
        return 300
    }
    else if(width >= 1600 && width < 1920){
        return 300
    }
    else if(width >= 1300 && width < 1600){
        return 250
    }
    else if(width >= 900 && width < 1200){
        return 250
    }
    else{
        return 250
    }
}



const HomePage = () => {

    const styles = useStyles();
    const history = useHistory();
    const { userRoles } = useStore();
    const { width, height } = useWindowDimensions();

    const highlightedChampions = [
        "HomepageMugongHover",
        "HomepageBjornHover",
        "HomepageAstershadeHover",
        "HomepageHarpyHover",
        "HomepageRaideHover"
    ]

    const trackedElements = [
        "HomepageMugongHover",
        "HomepageBjornHover",
        "HomepageAstershadeHover",
        "HomepageHarpyHover",
        "HomepageRaideHover"
    ]




    return (

        <Grid id="HomeMainGrid" container style={{width: '100vw', margin: '0px',overflowX: 'hidden', padding: '0px', justifyContent: 'center'}}>
            
            <Grid container style={{margin:'0px', padding:'0px', justifyContent: 'center', alignItems: 'center', width:'100vw'}}>
                <video autoPlay loop muted id="homeVideo" src={`/videos/sizzle-final.webm`} style={{  zIndex: -1, height: "auto", width: "100vw", opacity: "0.8", maxWidth:'2160px'}}/>
            </Grid>
            <Grid container style={{margin:'0px', padding:'0px', justifyContent: 'center', alignItems: 'center', width:'100vw'}}>
         
                <div className='topSectionText'  >Incredibly Fun, Highly Competitive, Strategic Combat NFT TCG</div>
            </Grid>

                <Grid 
                    item 
                    xs={12} 
                    style={{
                        zIndex: 300,

                        paddingTop: '2vh',
                        paddingBottom: '4vh', 
                        backgroundImage: `url(${getArt('HomepageSegment1BG')})`,
                        backgroundRepeat: 'no-repeat', 
                        objectFit: "stretch", 
                        backgroundSize: 'cover',

                    }}
                >       
                    <Grid container className='sectionHeaders' style={{justifyContent:'center', alignItems:'center'}}>
                        Employ Powerful Champions
                    </Grid>
                    <Grid container style={{justifyContent:'center', alignItems:'center'}}>
                    {
                    highlightedChampions.map((champion, index)=> 
                        <WatchedElement 
                        key={champion}
                        animationType={width<height?'slide':'zoom'}
                        direction={index % 2 === 0?'left':'right'}
                        index={width<height?0:index}
                        child={
                            <Grid item  style={{margin: '5px', padding: '0px', width: getCardWidth(width)}}>
                                <video 
                                    onMouseOver={event => { event.target.loop = true; event.target.play() }}
                                    onMouseOut={event => { event.target.loop = false; event.target.play() }} 
                                    autoPlay={false} 
                                    loop 
                                    muted 
                                    id={champion} 
                                    src={getArt(champion)}  
                                    style={{width: getCardWidth(width)}}
                                />
                            </Grid> 
                        }/>
                    )
                    }
                    </Grid>
                    <Grid container style={{width:'100%', justifyContent:'center', alignItems:'center'}}>
                    <WatchedElement 
                        animationType='fade'
                        index={0}
                        child={
                            <Grid item style={{height:'100px', width:width>600?'600px':'85vw', justifyContent:'center', alignItems:'center' }}>
                                <SvgButton onClick={()=>history.push("/inventory")} displayText='Meet The Champions' fontSize='28pt' buttonWidth={600} buttonHeight={100}/>
                            </Grid>
                        }
                    />

                    </Grid>
                </Grid>

                <Grid 
                    item 
                    xs={12} 
                    style={{
                        zIndex: 300, 
                        paddingTop: '2vh', 
                        paddingBottom: '4vh', 
                        backgroundImage: `url(${getArt('HomepageSegment2BG')})`, 
                        backgroundRepeat: 'no-repeat', 
                        objectFit: "stretch", 
                        backgroundSize: 'cover'
                    }}
                >       
                    <Grid container style={{width:'100%', justifyContent:'center', alignItems:'center', marginTop: '50px'}}>
                        <WatchedElement 
                            animationType='fade'
                            index={0}
                            child={
                                <Grid container className='sectionHeaders' style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginBottom: '50px'}}>
                                    Pit your 15 card deck against other exiles 
                                </Grid>
                            }
                        />
                    </Grid>
                    <Grid container style={{justifyContent:'center', alignItems:'center', marginLeft: width < 550?'-15px':'auto'}}>
                    {
                    highlightedChampions.map((champion, index)=> 
                        <WatchedElement 
                        key={champion}
                        animationType={'slide'}
                        direction={'left'}
                        index={width<height?0:index}
                        child={
                            <Grid 
                                item  
                                style={{
                                    marginLeft: width < 550? `-${((getCardWidth(width))/6)}px`:`-${(getCardWidth(width)/3)}px`, 
                                    marginRight:  width < 550? `-${((getCardWidth(width))/6)}px`:`-${(getCardWidth(width)/3)}px`, 
                                    padding: '0px', 
                                    width: width < 550?(getCardWidth(width)*0.5):(getCardWidth(width))}}
                            >
                                <img 
                                    id={champion} 
                                    src={getArt('DeckBack')}  
                                    style={{
                                        width: width < 550?(getCardWidth(width)*0.6):(getCardWidth(width)), 
                                        transform: `rotate(${(-2 + index) * 10}deg)`,
                                        marginTop:`${ ((index) % 4 === 0? 0: index % 2 === 0? -75:-50)}px`, 
                                        filter: 'drop-shadow(0 0 10px #000)'
                                    }}
                                />
                            </Grid> 
                        }/>
                    )
                    }
                    </Grid>
                    <Grid container style={{width:'100%', justifyContent:'center', alignItems:'center', marginTop: '50px'}}>
                        <WatchedElement 
                            animationType='fade'
                            index={0}
                            child={
                                <Grid item style={{height:'100px', width:'100%', fontSize: '16pt', justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,.3)', padding:'10px' }}>
                                    First time users receive 20 free non-NFT cards to try the game!
                                </Grid>
                            }
                        />
                    </Grid>
                    <Grid container style={{width:'100%', justifyContent:'center', alignItems:'center', marginTop: '50px'}}>
                        <WatchedElement 
                            animationType='fade'
                            index={0}
                            child={
                                <Grid item style={{height:'100px', width:width>600?'600px':'85vw', justifyContent:'center', alignItems:'center' }}>
                                {(userRoles !== undefined && userRoles !== null && userRoles !== "")? 
                                    <SvgButton onClick={()=>history.push("/decks")} displayText='Build a Deck' fontSize='28pt' buttonWidth={600} buttonHeight={100}/>:
                                    <SvgButton onClick={()=>history.push("/decks")} disabled={true} displayText='Login to Build a Deck' fontSize='24pt' buttonWidth={600} buttonHeight={100}/>
                                }
                                </Grid>
                            }
                        />
                    </Grid>
                </Grid>


                <Grid 
                    item 
                    xs={12} 
                    style={{
                        zIndex: 300, 
                        paddingTop: '2vh',
                        paddingBottom: '4vh', 
                        backgroundImage: `url(${getArt('HomepageSegment3BG')})`,
                        backgroundRepeat: 'no-repeat', 
                        objectFit: "stretch", 
                        backgroundSize: 'cover'
                    }}
                >       
                    <Grid container className='sectionHeaders' style={{justifyContent:'center', alignItems:'center'}}>
                        Updates and Patch Notes
                    </Grid>
                    <Grid container style={{justifyContent:'center',margin: width<1000?'0px':'30px'}}>
                    {
                    updates.map((update, index)=>
                   
                        <WatchedElement 
                            key={update.date}
                            animationType={'slide'}
                            direction={'right'}
                            index={width<height?0:index}
                            child={
                                <Grid item className='updateCard' style={{marginRight: width<1000?'0px':'10px',  marginLeft: width<1000?'0px':'10px', width: width<1000?'95vw':'300px', marginTop:'10px', overflow:'hidden'}} >
                                    <Grid 
                                        item 
                                        style={{
                                            width: '100%', 
                                            fontWeight:'bold', 
                                            fontSize:'16pt', 
                                            textAlign:'center',
                                            backgroundColor:'rgba(0,30,70,.65)',
                                            marginBottom:'10px', 
                                            paddingBottom:'5px', 
                                            paddingTop:'5px',
                                            overflow:'hidden'
                                        }}
                                    > 
                                        {update.title}  
                                    </Grid>
                                    <Grid item style={{padding: '10px', width: '100%'}}> {update.content}  </Grid>
                                    <Grid item style={{padding: '10px', width: '100%'}}> {update.date}  </Grid>

                                </Grid> 
                            }
                        />
                    
                    )
                    }
                    </Grid>
                    <Grid container style={{width:'100%', justifyContent:'center', alignItems:'center'}}>
                    {/* <WatchedElement 
                        animationType='fade'
                        index={0}
                        child={
                            <Grid item style={{height:'100px', width:width>600?'600px':'85vw', justifyContent:'center', alignItems:'center', marginTop:'20px' }}>
                                <SvgButton onClick={()=>history.push("/inventory")} displayText='News and Updates' fontSize='28pt' buttonWidth={600} buttonHeight={100}/>
                            </Grid>
                        }
                    /> */}
                    </Grid>
                </Grid>

                <Grid 
                    item 
                    xs={12} 
                    style={{
                        zIndex: 300, 
                        paddingTop: '2vh',
                        paddingBottom: '4vh', 
                        backgroundImage: `url(${getArt('HomepageSegment4BG')})`,
                        backgroundRepeat: 'no-repeat', 
                        objectFit: "stretch", 
                        backgroundSize: 'cover',
                        marginBottom:'0px'
                    }}
                >       
                    <Grid container className='sectionHeaders' style={{justifyContent:'center', alignItems:'center'}}>
                        Future of the Arena
                    </Grid>
                    <Grid container style={{justifyContent:'center',margin: width<1000?'0px':'30px'}}>
                    {
                    arenaFuture.map((update, index)=>
                   
                        <WatchedElement 
                            key={update.date}
                            animationType={'slide'}
                            direction={'right'}
                            index={width<height?0:index}
                            child={
                                <Grid item className='updateCard' style={{marginRight: width<1000?'0px':'10px',  marginLeft: width<1000?'0px':'10px', width: width<1000?'95vw':'300px', marginTop:'10px', overflow:'hidden'}} >
                                   
                                   <Grid 
                                        container  
                                        style={{
                                            width: '100%', 
                                            fontWeight:'bold', 
                                            fontSize:'16pt', 
                                            textAlign:'center',
                                            alignItems:'center',
                                            justifyContent:'center',
                                            backgroundColor:'rgba(0,30,70,.65)', 
                                            paddingTop:'5px',
                                            overflow:'hidden'
                                        }}
                                    >
                                            <img style={{width:'50px', height:'50px' }} src={`${index+1}.png`}/></Grid>
                                    <Grid 
                                        item 
                                        style={{
                                            width: '100%', 
                                            fontWeight:'bold', 
                                            fontSize:'16pt', 
                                            textAlign:'center',
                                            backgroundColor:'rgba(0,30,70,.65)',
                                            marginBottom:'10px', 
                                            paddingBottom:'5px', 
                                            paddingTop:'5px',
                                            overflow:'hidden'
                                        }}
                                    > 
                                        
                                        {update.title}  
                                    </Grid>
                                    <Grid item style={{padding: '10px', width: '100%'}}> {update.content}  </Grid>
                                    <Grid item style={{padding: '10px', width: '100%'}}> {update.date}  </Grid>

                                </Grid> 
                            }
                        />
                    
                    )
                    }
                    </Grid>
                    <Grid container style={{width:'100%', justifyContent:'center', alignItems:'center'}}>
                    {/* <WatchedElement 
                        animationType='fade'
                        index={0}
                        child={
                            <Grid item style={{height:'100px', width:width>600?'600px':'85vw', justifyContent:'center', alignItems:'center', marginTop:'20px' }}>
                                <SvgButton onClick={()=>history.push("/inventory")} displayText='News and Updates' fontSize='28pt' buttonWidth={600} buttonHeight={100}/>
                            </Grid>
                        }
                    /> */}
                    </Grid>
                </Grid>

                <WatchedElement 
                        key={'artBySection'}
                        animationType={'zoom'}
                        direction={'left'}
                        index={0}
                        child={
                            <Grid container style={{ marginLeft:'auto', marginRight:'auto', textAlign:'center', marginTop:'100px', justifyContent:'center', alignItems:'center', marginBottom:'0px'}}>
                                <Grid item xs = {10} style={{display:'flex', flexDirection:'column',  width:'65vw', maxWidth:'1200px'}}>
                                    <img alt='Art by Imad Awan' src={width >= 600 ? getArt('ArtByInfographic'):getArt('ArtByInfographicMobile')}/>
                                </Grid>
                            </Grid>
                        }
                />

                <Grid item xs = {12} className = {styles.section2Container} style={{alignContent:'center'}} >
                    <WatchedElement 
                        key={'infoGraphics'}
                        animationType={'fade'}
                        index={0}
                        child={
                            <Grid container style={{maxWidth:'1600px', marginLeft:'auto', marginRight:'auto', textAlign:'center', justifyContent:'center', alignItems:'center'}}>
                                    <img onClick={()=>history.push('/store')} className='interactiveInfographic' alt="Play &amp; Earn Exile Tokens" src={getArt("BuyOpenInfographic")} />  
                                    <a  href="https://opensea.io/collection/arena-of-exiles-official" target="_blank" rel="noreferrer noopener">
                                        <img alt="Buy &amp; Sell on OpenSea" src={getArt("BuySellInfographic")} className='interactiveInfographic'/>  
                                    </a>
                            </Grid>
                        }
                    />
                </Grid>
  

            {/* 
                                <Grid item xs = {10} sm = {10} md = {4} lg = {4} style={{display:'flex', flexDirection:'column', height:'100%'}}>
                                    <img alt="Play &amp; Earn Exile Tokens" src={getArt("PlayEarnInfographic")} />
                                </Grid>
            */}
                    
                <Grid item xs = {12} style={{zIndex:300, marginBottom:'50px'}}>

                    <Grid container className="flexButtonContainer" style={{justifyContent:'center', alignItems:'center'}}>
        {/* 
                        <Grid item style={{height:'100px', width:width>600?'600px':'85vw'}}>
                            <SvgButton onClick={()=>history.push("/store")} displayText='Buy Now' fontSize='36pt' buttonWidth={600} buttonHeight={100}/>
                        </Grid> 
        */}

                        <div className="flexButtonSeparator" style={{ height: '20px' }} />

                        <Grid item style={{height:'100px', width:width>600?'400px':'65vw'}}>
                            <SvgButton onClick={()=>history.push("/lore")} displayText='Learn More' fontSize='24pt' buttonWidth={400} buttonHeight={100}/>
                        </Grid>

                        <div className="flexButtonSeparator" style={{ height: '20px' }} />

                        <Grid item style={{height:'100px', width:width>600?'500px':'75vw'}}>
                            <SvgButton onClick={()=>history.push("/game")} displayText='Play The Game' fontSize='28pt' buttonWidth={500} buttonHeight={100}/>
                        </Grid>
                    </Grid>
                </Grid>

                



        {/*
                <Grid container style={{marginLeft:'auto', marginRight:'auto', maxWidth:'1200px', textAlign:'center', justifyContent:'center', alignItems:'center'}}>

                    <Grid item xs = {10} style={{ marginTop: '200px', marginBottom: 40, maxWidth:'1200px' }}>
                        <Typography variant={width >= 600 ? "h2":"h4"} className = {`${styles.textWhite} ${styles.textCentered}`}>
                            Future of The Arena 
                        </Typography>
                    </Grid>

                    <Grid item xs={10}  style={{display:'flex', flexDirection:'column'}}>
                        <img alt='Milestones' src={width >= 600 ? getArt('Milestones'):getArt('MilestonesMobile')}/>
                    </Grid>
                </Grid>
        */}



                

        {/*                 
                <Grid item xs = {12} style={{zIndex:300, marginTop: 40, marginBottom: 25}}>
                    <Grid container className="flexButtonContainer" style={{justifyContent:'center', alignItems:'center'}}>
                        <Grid item style={{height:'100px', width:width>600?'600px':'85vw'}}>
                            <SvgButton onClick={()=>history.push("/store")} displayText='Buy Now' fontSize='36pt' buttonWidth={600} buttonHeight={100}/>
                        </Grid>
                    </Grid>
                </Grid>
        */}

            <Grid item xs = {12}>
                <SocialMediaFooter/>
            </Grid>

        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({

    section2Container:{
        marginTop:'30px',
        zIndex: 300
    },

    textWhite: {
        color: "white",
    },

    textCentered: {
        textAlign: "center"
    },

    socialsBar: {
        display: "flex",
        alignContent: "center",
        zIndex: 100,
        width: "100vw",
        height: "50px",
        backgroundColor: "rgba(0,80,150, 1)",
    },

}));

export default HomePage
