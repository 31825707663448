import React, { } from 'react';
import { makeStyles, Popover, Fab, Grid } from '@material-ui/core';
import CloseIcon  from '@material-ui/icons/Close';
import { rarityColors } from "../helpers/GlobalStore";

//DisableClick tells the popover that the pointer events should be disabled until an event happens.
//DisableStatus is a bool that tells lets the popover know when the close option should be visible.
const CardPopover = ({contents, rarity, popover, closePopover, dropShadow, disableClick, disableStatus, hideBackground}) => {

    const styles = useStyles();

    const handleClose = (event, reason) => {
        if(reason === undefined || reason !== 'backdropClick')
            closePopover()
    }

    const getPaperStyle = () => {
        if(hideBackground)
            return styles.hideBackground
        else if(dropShadow)
            return styles.popPaper
        else return ""
    }

    const getFilterStyle = () => {
        if(dropShadow && !disableClick)
            return `drop-shadow( 0px 0px 25px rgba(${rarityColors[rarity]}, 1))`
        else if(hideBackground)
            return ""
        else return `drop-shadow( 0px 0px 25px rgba(255,255,255,1))`
    }

    const getBackgroundColor = () => {
        if(hideBackground)
            return "rgba(0,0,0,0)"
        else return "rgba(0,0,0,1)"
    }

    return (
        <Popover
        id="card-click-popover"
        anchorReference="anchorPosition"
        open={popover}
        classes = {{paper: getPaperStyle() }}
        anchorPosition={{top:window.innerHeight/2, left:window.innerWidth/2}}
        transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
            }}
        onClose={disableClick ? handleClose : closePopover}
        style={{ filter: getFilterStyle()}}
        BackdropProps={{ invisible: (disableClick) ? false : true}}
        >
            <Grid container style = {{backgroundColor: getBackgroundColor()}}>
                {disableClick && disableStatus &&
                <Grid item xs = {12} style = {{display: "flex", justifyContent: "right", backgroundColor: getBackgroundColor()}}>
                    <Fab 
                    size="small" 
                    aria-label="remove" 
                    color="secondary"
                    style={{ margin: 10, scale: "0.70" }}
                    onClick = {(event, reason) => handleClose(event, reason)}
                    disabled = {!disableStatus}
                    >
                        <CloseIcon style = {{scale: "1.25"}} />
                    </Fab>
                </Grid>
                }
                <Grid item xs = {12}>
                    {contents}
                </Grid>
            </Grid>
        </Popover>
    )
}

const useStyles = makeStyles((theme) => ({

    popover:{
        pointerEvents:'none',
        alignItems:'center',
        justifyContent:'center'
    },

    popPaper:{
        boxShadow: "none",
        backgroundColor:'rgba(0,0,0,0.75)',

    },

    hideBackground: {
        boxShadow: "none",
        backgroundColor:'transparent',
    }
  }));

export default CardPopover
