//This component combines duplicate behavior from the Store and Inventory Packs (whats inside feature) page into one shared component.
//Returns a grid container housing all the elements that should be displayed in the popover.

import React, { useState, useEffect } from "react";
import { makeStyles, Grid, Typography, Zoom, CircularProgress } from '@material-ui/core';
import StyledCardContainer from "../components/StyledCardContainer"
import { rarityColors } from "../helpers/GlobalStore"
import SlotChance from '../components/SlotChance';
import CompendiumUtil from "../pages/compendium/CompendiumUtil"
import { useBackendAPI } from '../helpers/BackendAPI';
import HashMap from 'hashmap';
import CardPopover from "./CardPopover";

const WhatsInsideHandler = ({pack, popoverClose}) => {

    const styles = useStyles()
    const {getRaritySlots, getPromoDrops} = useBackendAPI();
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState()
    const [popover, setPopover] = useState(false)

    const getData = async () => {
        //The pack type is standard, so move forward with standard rarity slot behavior.
        if(pack.type === 0 || pack.type === 3){

            await getRaritySlots(pack.packId).then((res) => {
                setData(res)
                setIsLoading(false)
                setPopover(true)
            })
            .catch(err => {
                
            })
        }

        else if(pack.type === 1){
            setIsLoading(false)
            setPopover(true)
        }

        else if(pack.type === 2){
            await getPromoDrops(pack.packId).then(res => {
                setData(res)
                setIsLoading(false)
                setPopover(true)
            })
            .catch(err => {
                
            })
        }
    }

    useEffect(() => {

        //Load data from the database if the selected pack isn't the Starter Pack, which always gives a predefined set of cards.
        if(data === undefined)
            getData()
    }, [])


    const renderData = () => {

        let element

        //Card pack type chosen is standard which fits the standard rarity slot  data model.
        if(pack.type === 0){
            element =
            <Grid id = "DataContainer" container className={styles.slotsContainer}>
                {
                data.map((slot, index) => (
                    <Zoom 
                    in = {popover}
                    style = {{
                        transitionDelay: `${150 + ((index + 1) * 200)}ms`
                    }}
                    key = {`pack-${pack.packId}-slot-${index}-zoom`}
                    >
                        <Grid 
                        item
                        container
                        key = {`pack-${pack.packId}-slot-${index}-containerItem`}
                        style = {{
                            display: "flex",
                            justifyContent: "center",
                            width: 200, 
                            height: "auto", 
                            marginTop: 0, 
                            marginBottom: 0,
                            marginLeft: 10,
                            marginRight: 10,
                            padding: 0,
                        }}
                        >
                            <Grid item key = {`pack-${pack.packId}-slot-${index}-text`}>
                                <Typography variant="h6" style = {{color: "white"}}>{`Card ${index + 1}`}</Typography>
                            </Grid>
    
                            <Grid item xs = {12} key = {`pack-${pack.packId}-slot-${index}-chanceItem`}>
                                <SlotChance slot={slot}/>
                            </Grid>
                        </Grid>
                    </Zoom>
                ))
                }
            </Grid>
            
        }

        //Card pack type chosen is the starter pack.  Load and display the pre-determined set of cards.
        else if(pack.type === 1){

            const cards = [
                {
                    cardName: "Blind Mystic",
                    mediaHash: "QmRAYzqb1WuJy6RwSxcLPa3PSE6HenJ2ECsyXWsSAc2hGR"
                },

                {
                    cardName: "Werewolf",
                    mediaHash: "QmZfMozUEWnhPav8D6UJdibEgQdGv8hFWdBJ9PFM54aqkr"
                },

                {
                    cardName: "Phia",
                    mediaHash: "QmWp8tdHMcCFyMdsEmseKKCLqmYs75qfbKsjrWttqh96nF"
                },

                {
                    cardName: "Evalyn",
                    mediaHash: "QmZ2eeXb9Mf6qNoTSbXJ61qQcwcv12FdzXGr96Qt9U7tgE"
                },

                {
                    cardName: "Simple Tonic",
                    mediaHash: "Qmeawa3G3e1BNVq7CJ5yb697GrN4tYXeouv9oEopJd78xd"
                }
            ]

            element = 
            <Grid id = "DataContainer" container className={styles.slotsContainer}>
                {
                cards.map((card, index) => (
                    <Zoom 
                    in = {popover}
                    style = {{
                        transitionDelay: `${150 + ((index + 1) * 200)}ms`
                    }}
                    key = {`pack-${pack.packId}-card-${index}-zoom`}
                    >
                        <Grid 
                        item
                        key = {`pack-${pack.packId}-card-${index}-containerItem`}
                        style = {{
                            width: 200, 
                            height: 350, 
                            margin: 25, 
                            padding: 0,
                        }}
                        >
                            <Grid container style = {{width: 200, height: 350, padding: 0, justifyContent:'center'}}>
                                <Grid item xs = {12} key = {`pack-${pack.packId}-card-${index}-chanceItem`}>
                                    <StyledCardContainer
                                    imgName = {card.mediaHash}
                                    rarity = {16}
                                    count = {1}
                                    zIndex={800}
                                    width = "200px"
                                    height = "320px"
                                    />
                                </Grid>

                                <Grid item xs = {12}>
                                    <Typography variant = "h6" style = {{textAlign: "left", color:'white'}}>
                                        x3
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Zoom>
                ))
                }
            </Grid>
                
        }

        //Card pack type chosen is a promo format.  All cards listed are equally likely to drop.
        if(pack.type === 2){
            element =
            <Grid id = "DataContainer" container className={styles.slotsContainer}>
                {
                data.map((card, index) => (
                    <Zoom 
                    in = {popover}
                    style = {{
                        transitionDelay: `${150 + ((index + 1) * 200)}ms`
                    }}
                    key = {`pack-${pack.packId}-card-${index}-zoom`}
                    >
                        <Grid 
                        item
                        key = {`pack-${pack.packId}-card-${index}-containerItem`}
                        style = {{
                            width: 200, 
                            height: 320, 
                            margin: 25, 
                            padding: 0,
                        }}
                        >
                            <StyledCardContainer
                            imgName = {card.mediaHash}
                            rarity = {card.rarity}
                            count = {1}
                            zIndex={800}
                            width = "200px"
                            height = "320px"
                            />
                        </Grid>
                    </Zoom>
                ))
                }
            </Grid>
            
        }

        else if(pack.type === 3){

            let slot = data[0]

            element =
            <Grid item xs = {12}>
                <SlotChance slot={slot}/>
            </Grid>
        }

        return element
    }

    const renderLegend = () => {

        let element

        if(pack.type === 0 || pack.type === 3){

            let legendSlots = []
    
            let idToNameMapping = new HashMap()
            idToNameMapping.set(16, "Common")
            idToNameMapping.set(8, "Uncommon")
            idToNameMapping.set(4, "Rare")
            idToNameMapping.set(2, "Epic")
            idToNameMapping.set(1, "Legendary")
            //outer loop iterates through all the card slots for the card pack.
            data.forEach(slot => {
                
                //inner loop iterates through each of the rarities that can be generated from the card slot.
                slot.colors.forEach(rarity => {
    
                    //If the legend doesn't have this type of rarity yet, add it using the first item in the rarity slot tuple.
                    if(!legendSlots.find(element => element && element.rarity && element.rarity === rarity.item1)){
    
                        legendSlots.push({
                            rarity: rarity.item1,
                            rarityName: idToNameMapping.get(rarity.item1),
                            chance: rarity.item2
                        })
                    }
                })
            })
    
            element =
            <Grid id = "LegendContainer" item container style = {{display: "flex", justifyContent: (pack.type === 0) ? "center" : "left", padding: 0, margin: 5}}>
                {legendSlots.length > 0 &&
                legendSlots.map(rarity => (
                    <Grid item xs = {(pack.type === 0) ? "auto" : 12} style = {{marginTop: 5, marginBottom: 5, marginRight: 10, marginLeft: 10}}>
                        <Grid container style = {{display: "flex", justifyContent: (pack.type === 0) ? "center" : "left", alignItems: "center"}}>
                            <Grid 
                            item
                            style = {{
                                width: 20,
                                height: 20,
                                backgroundColor: `rgb(${rarityColors[rarity.rarity]})`,
                                marginRight: 5
                            }}
                            />

                            <Grid item>
                                <Typography variant = "body1" style = {{color: `rgb(${rarityColors[rarity.rarity]})`}}>
                                    {(pack.type === 0) ? rarity.rarityName : `${rarity.rarityName}: ${rarity.chance}%`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ))
                }
            </Grid>
            
        }

        //Pack type is the starter pack.  Let the user know that all cards are guaranteed.
        else if(pack.type === 1) {
            element = 
            <Grid item>
                <Typography variant = "h6" style = {{textAlign: "center", color: "white"}}>
                    All cards listed are guaranteed drops
                </Typography>
            </Grid>
        }

        //Pack type is a promo pack.  Let the user know that all cards are equally likely to drop.
        else if(pack.type === 2) {
            element = 
            <Grid item xs = {11}>
                <Typography variant = "h6" style = {{textAlign: "center", color: "white"}}>
                    You are guaranteed to get to get one of these items
                </Typography>
            </Grid>
        }

        return element
    }

    const renderPopover = () => {

        var element
        var grouping

        if(isLoading)
            grouping = 
            <Grid container style={{justifyContent:'center', alignItems:'center', padding: 10, overflowY: "hidden", width: "auto", height: "auto"}}>
                <Grid item xs = {12} style = {{marginBottom: 10}}>
                    <Typography variant = "h6" style = {{textAlign: "center", color: "white", textEmphasis: "bold"}}>Loading...</Typography>
                </Grid>

                <Grid item>
                    <CircularProgress/>
                </Grid>
            </Grid>

        else if (pack.type === 3) 
            grouping = 
            <Grid container style = {{width: 450}}>
                <Grid item md = {6}>
                    {renderData()}
                </Grid>

                <Grid item md = {6}>
                    {renderLegend()}
                </Grid>
            </Grid>

        else grouping = 
        <Grid container>
            <Grid item xs = {12}>
                {renderLegend()}
            </Grid>

            <Grid item xs = {12}>
                {renderData()}
            </Grid>
        </Grid>

        element =
        <Grid id = "GroupingContainer" container className = {styles.groupingContainer}>
            {grouping}
        </Grid>

        return element
    }

    return (
        <CardPopover
        rarity = {pack.rarity}
        count = {1}
        contents = {renderPopover()}
        popover = {popover}
        closePopover = {popoverClose}
        dropShadow
        />
    )
}


const useStyles = makeStyles((theme) => ({

    groupingContainer: {
        display: "flex",
        justifyContent: "center",
        overflowX: "hidden",
        overflowY: "auto",
        width: 'auto',
        height: "auto",
        maxWidth: "75vw",
        maxHeight: "70vh",
        margin: 0,
        padding: 15,
        backgroundColor:'#181c24',
        color:'white'
    },

    slotsContainer: {
        display: "flex",
        justifyContent: "center",
        width: 'auto',
        height: "auto",
        margin: 0,
    },

}))

export default WhatsInsideHandler