import React from 'react';
import {  makeStyles, Grid } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styled from 'styled-components'

 const StyledTab = styled(Tab) `
    font-size:24px;
    color: white!important;
    border-bottom: none!important;
    
 
    border-bottom-right-radius :15px;
    border-bottom-left-radius: 15px;
    opacity: 1!important;
    
    &:hover{
        background-color:#3f020b;
    }
    &.Mui-selected{
     
        background-image: linear-gradient(#1f020b, #5f222b);
        font-weight: bold;
    
    }
 `


const TabBar = ({tabNames, tabContents, tabStates}) => {
    const styles = useStyles();
    const [value, setValue] = React.useState(0);
     
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid container spacing = {4}  style = {{width: "100%", justifyContent:'center'}}>
            <Grid item>
                    <Tabs
                    className={styles.tabs}
                    value={value}
                    onChange={handleChange}
                    centered
                    >
                        {(Array.isArray(tabNames) && tabNames.length > 0) &&
                            tabNames.map((name, index) => (
                                <StyledTab 
                                label = {name} 
                                disabled = {!tabStates[index]}
                                key = {`tabheader-name-${name}`}/>
                            ))
                        }
                    </Tabs>
            </Grid>

            <Grid item xs = {12}>

                {(Array.isArray(tabContents) && tabContents.length > 0) &&
                    tabContents.map((content, index) => (
                        <div
                        role="tabpanel"
                        hidden={value !== index}
                        key={`tabContents-${index}`}
                        >
                            {value === index && (
                                content
                            )}
                        </div>
                    ))
                }

            </Grid>

        </Grid>
    );
}


const useStyles = makeStyles((theme) => ({


    tabs: {

        "& .MuiTabs-indicator": {
          display: "none"
          //backgroundColor: "orange"
        }
      
      }
      

}))

export default TabBar;