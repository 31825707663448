import React, { useState, useEffect } from "react";
import { Button, makeStyles, TextField, Grid, Typography, Divider, MenuItem, FormControl, InputLabel, Select } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { useBackendAPI } from '../helpers/BackendAPI';
import { useStore } from "../helpers/GlobalStore"
import { useContractAPI } from "../helpers/ContractAPI";
import CONFIG from "../config/config"
import StyledCardContainer from "./StyledCardContainer"; 

const AdminCardPackComponent = () => {
    
    const styles = useStyles();
    
    const {getPaymentManager} = useContractAPI()
    const {createCardPack, getBaseCards, addPackDistribution} = useBackendAPI();
    
    const { cardPacks, setCardPacks, paymentManager, baseCards, childUpdateNeeded} = useStore()
        
    const [isPageLoaded, setIsPageLoaded] = useState(false)
        
    const Web3 = require('web3');
    let web3 = new Web3(CONFIG.ETH_NODE);

    /*Determines which subview to show.
    1 = card pack basic info view
    2 = included cards section
    3 = part 2 of the second view, opens a subsection that lets the admin select from a list of cards to add.
    */
    const [viewIndex, setViewIndex] = useState(1)

    //Used to record and pass in a local media file.
    const [reader, setReader] = useState(new FileReader());
    const [file, setFile] = useState();
    const [imgThumb, setImgThumb] = useState();

    const [packName, setPackName] = useState("")
    const [price, setPrice] = useState(1.0)
    const [numCards, setNumCards] = useState(1)
    const [maxCapacity, setMaxCapacity] = useState(0)
    const [description, setDescription] = useState("")
    const [rarity, setRarity] = useState("")
    const [season, setSeason] = useState(1)
    const [maxAllowedPurchases, setMaxAllowedPurchases] = useState(0)

    const [includedCards, setIncludedCards] = useState([])

    const loadBaseCards = async () => {
        await getBaseCards()
    }

    const loadPaymentManager = async () => {
        await getPaymentManager()
    }

    useEffect(() => {


        if (!isPageLoaded){

            if(baseCards.length === 0){
                loadBaseCards()
            }

            if(paymentManager === null || paymentManager === undefined){
                loadPaymentManager()
            }

            setIsPageLoaded(true)
        }
        

    }, [isPageLoaded, baseCards.length, paymentManager])


    const validateNumberInput = (input, min) => {

        if (typeof input != "string") 
            return false 
        
        let isValid = !isNaN(input) && // use type coercion to parse the _entirety_ of the string (`parseInt` alone does not do this)...
               !isNaN(parseInt(input)) // ...and ensure strings of whitespace fail
    
        if(!isValid)
            return false
    
        if (parseInt(input) < min)
            return false
    
        return true
    }

    //Prompts the user to select an image file stored locally on thier machine.
    //Selected file is read and buffered, then passed to the IPFS uploader.
    const captureFile = (event) => {
        const file = event.target.files[0];

        var arrayBuffer = new FileReader();

        arrayBuffer.onloadend = () => {
            setReader(arrayBuffer);
            setFile(file);
        }

        arrayBuffer.readAsArrayBuffer(file);

        var urlBuffer = new FileReader();

        urlBuffer.onloadend = () => { 
            setImgThumb(urlBuffer.result)
        }

        urlBuffer.readAsDataURL(file)
    }

    const getFloatPrecision = (value) => {

        const valueStr = value.toString().split(".")

        let precision = 0;

        if(valueStr.length === 1){
            precision = 1
        }
        else{
            precision = valueStr[1].length
        }

        return precision
    }

    const handleCreateClick = async () => {

        try{

            const pack = {
                packName: packName,
                price: parseFloat(price).toFixed(getFloatPrecision(price)),
                numCards: numCards,
                description: description,
                season: season,
                capacity: maxCapacity,
                rarity: rarity,
                maxAllowedPurchases: maxAllowedPurchases
            }
    
            let wei = web3.utils.toWei(price.toString());
    
            var contractTransaction = await paymentManager.addCardPackConfig(wei, parseInt(numCards), parseInt(maxCapacity), parseInt(maxAllowedPurchases), packName)
            var contractResult = await contractTransaction.wait()
    
            //Contract upload succeeded, now upload to API.
            if(contractResult.status === 1){
    
                var cardPack = await createCardPack(pack)
                .then(res => {
                    setPackName("")
                    setDescription("")
                    setPrice(0.0)
                    setMaxCapacity(0)
                    setMaxAllowedPurchases(0)
                    setFile(undefined)
                    setReader(new FileReader())
                    setImgThumb(undefined)
                    return res.data
                })
                .catch(err => {
                    console.log(`API upload failed for card pack:  ${packName}`)
                    return undefined
                })
    
                if(cardPack !== undefined){
                    let distributions = includedCards.map(card => {
                        return {
                            CardPackId: cardPack.packId,
                            BaseCardId: card.baseCardId,
                            Weight: card.weight
                        }
                    })
        
                    addPackDistribution(distributions)
                    .then(() => {
                        console.log("API pack distribution creations succeeded.")
                
                        let tempPacks = [...cardPacks]
                        tempPacks.push(cardPack)
                        setCardPacks(tempPacks)
                    })
                    .catch(() => console.log("API pack distribution creations failed."))
                }
            }
            else{
                console.log(`Contract upload failed for card pack:  ${packName}`)
            }
        }

        catch(err){
            console.log(err)
        }
        
        setViewIndex(1)
    }

    const isCreateDisabled = () => {
        if(file === null || file === undefined)
            return true

        if(packName === "")
            return true

        if(description === "")
            return true

        return false
    }

    const updateSelection = (index, field, value) => {
        let items = [...includedCards];
        let item = items[index]

        if (field === "id")
            item.baseCardId = value

        else if (field === "hash")
            item.mediaHash = value

        else if (field === "weight")
            item.weight = value

        items[index] = item

        setIncludedCards(items)
    }

    const addCardButtonClick = () => {
        setViewIndex(3)
    }

    const selectCard = (card) => {

        let tempSelection = {
        cardName: card.cardName,
        baseCardId: card.baseCardId,
        mediaHash: card.mediaHash,
        weight: 0
        }

        let tempSelections = [...includedCards]
        tempSelections.push(tempSelection)
        setIncludedCards(tempSelections)

        setViewIndex(2)
    }

    const cardDeleteClick = (card) => {
        let tempSelections = [...includedCards]
        tempSelections = tempSelections.filter(c => c.mediaHash !== card.mediaHash)

        setIncludedCards(tempSelections)
    }

    const stage1 = (
        <Grid container justify = "center" spacing = {2} style = {{width: "100%", margin: 25}}>

            <Grid item xs = {6} sm = {5} md = {4} lg = {3}>
                {(file === null || file === undefined) ?
                    <Button 
                    className = "buttonRibbon"
                    component="label"
                    >
                        Select File
                        <input type='file' id='file' hidden onChange={captureFile}/>
                    </Button>
                    :
                    <img
                        alt="Thumbnail"
                        className = {styles.thumbnail}
                        src = {imgThumb}
                    />
                }
            </Grid>
            
            {file !== undefined &&
            <Grid item xs = {6} sm = {7} md = {8} lg = {9}>
                {(file !== null && file !== undefined) &&

                    <Grid container spacing = {2}>

                        <Grid item xs = {12}>
                            <TextField
                            key = "cardpackname-input"
                            label="Card Pack Name"
                            size="small"
                            value = {packName}
                            onChange = {(e) => setPackName(e.target.value)}
                            style = {{width: "42ch"}}
                            className = {styles.textFieldProp}
                            InputProps = {{className: styles.textFieldProp}}
                            InputLabelProps = {{className: styles.textFieldLabel}}
                            multiline
                            rowsMax={4}
                            required
                            error = {packName.length < 3 || packName.length > 42}
                            helperText = {(packName.length < 3 || packName.length > 42) ?
                                "Pack Name must be between 3 and 42 characters!"
                                :
                                " "
                            }
                            />
                        </Grid>

                        <Grid item xs = {12}>
                            <TextField
                            key = "cardpackdescription-input"
                            label="Card Pack Description"
                            size="small"
                            value = {description}
                            onChange = {(e) => setDescription(e.target.value)}
                            style = {{width: "75ch"}}
                            className = {styles.textFieldProp}
                            InputProps = {{className: styles.textFieldProp}}
                            InputLabelProps = {{className: styles.textFieldLabel}}
                            required
                            error = {description.length < 3 || description.length > 200}
                            helperText = {(description.length < 3 || description.length > 200) ?
                                "Pack Description must be between 3 and 200 characters!"
                                :
                                ""
                            }
                            />
                        </Grid>

                        <Grid item xs = {10} md = {3} lg = {2}>
                            <FormControl fullWidth>
                                <InputLabel className = {styles.textWhite}>Rarity</InputLabel>
                                <Select
                                value={rarity}
                                label="Rarity"
                                onChange={(e) => setRarity(e.target.value)}
                                className = {styles.textFieldProp}
                                inputProps = {{classes: {
                                    root: styles.textFieldProp,
                                    icon: styles.textFieldLabel
                                }}}
                                >
                                    <MenuItem value={"common"}>Common</MenuItem>
                                    <MenuItem value={"uncommon"}>Uncommon</MenuItem>
                                    <MenuItem value={"rare"}>Rare</MenuItem>
                                    <MenuItem value={"epic"}>Epic</MenuItem>
                                    <MenuItem value={"legendary"}>Legendary</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs = {12}>
                            <TextField
                            key = "buyLimitInput"
                            label="Max Allowed Purchases"
                            size="small"
                            value = {maxAllowedPurchases}
                            onChange = {(e) => setMaxAllowedPurchases(e.target.value)}
                            style = {{width: "22ch"}}
                            className = {styles.textFieldProp}
                            InputProps = {{className: styles.textFieldProp}}
                            InputLabelProps = {{className: styles.textFieldLabel}}
                            required
                            />
                        </Grid>

                        <Grid item xs = {12}>
                            <TextField
                            label = "# Cards"
                            type="number"
                            value={numCards}
                            style = {{width: "14ch"}}
                            className = {styles.textFieldProp}
                            InputProps = {{className: styles.textFieldProp}}
                            InputLabelProps = {{className: styles.textFieldLabel}}
                            onChange={(e) => setNumCards(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs = {12}>
                            <TextField
                            label = "Maximum Capacity"
                            type="number"
                            value={maxCapacity}
                            style = {{width: "24ch"}}
                            className = {styles.textFieldProp}
                            InputProps = {{className: styles.textFieldProp}}
                            InputLabelProps = {{className: styles.textFieldLabel}}
                            onChange={(e) => setMaxCapacity(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs = {12}>
                            <TextField
                            label = "Price (Eth)"
                            value={price}
                            style = {{width: "20ch"}}
                            className = {styles.textFieldProp}
                            InputProps = {{className: styles.textFieldProp}}
                            InputLabelProps = {{className: styles.textFieldLabel}}
                            onChange={(e) => setPrice(e.target.value)}
                            />
                        </Grid>

                        {(file !== null && file !== undefined) &&
                        <Grid item>
                            <Button 
                            className = "buttonRibbon"
                            component="label"
                            >
                                Select File
                                <input type='file' id='file' hidden onChange={captureFile}/>
                            </Button>
                        </Grid>
                        }

                        <Grid item>
                            <Button
                            className = "buttonRibbon"
                            onClick = {() => setViewIndex(2)}
                            disabled = {isCreateDisabled()}
                            >
                                Next
                            </Button>
                        </Grid>
                    </Grid>
                }

            </Grid>
            }


        </Grid>
    )

    const stage2 = (
        <Grid container spacing = {2} justify = "center" style = {{width: "100%", margin: 25}}>
            <Grid item xs = {11}>
                <Typography variant = "h4" className = {`${styles.textWhite} ${styles.textCentered}`}>{packName}</Typography>
            </Grid>

            <Grid item xs = {11}>
                <Typography variant = "body1" className = {`${styles.textWhite} ${styles.textCentered}`}>{price}</Typography>
            </Grid>

            <Grid item xs = {11}>
                <Typography variant = "body1" className = {`${styles.textWhite} ${styles.textCentered}`}>{rarity}</Typography>
            </Grid>

            <Grid item xs = {11}>
                <Typography variant = "body2" className = {`${styles.textWhite} ${styles.textCentered}`}>{description}</Typography>
            </Grid>

            <Grid item xs = {11}>
                <Grid container spacing = {4} justify = "center">
                    <Grid item>
                        <Button
                        className = "buttonRibbon"
                        onClick = {() => setViewIndex(1)}
                        >
                            Back
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button
                        className = "buttonRibbon"
                        onClick = {(viewIndex === 2) ? () => addCardButtonClick() : () => setViewIndex(2)}
                        >
                            {viewIndex === 2 ?
                            "Add Card" :
                            "Cancel"
                            }
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button
                        className = "buttonRibbon"
                        onClick = {() => handleCreateClick()}
                        disabled = {includedCards.length === 0}
                        >
                            Create Card Pack
                        </Button>
                    </Grid>

                </Grid>

            </Grid>

            {viewIndex === 3 &&
            <Grid item xs = {11}>
                <Grid container spacing = {4} justify = "center" className = {styles.selectionListContainer}>
                    <Grid item xs = {12}>
                        <Divider
                        style = {{
                            backgroundColor: 'white'
                        }}
                        />
                    </Grid>

                    <Grid item xs = {12}>
                        <Typography variant = "h4" className = {`${styles.textWhite} ${styles.textCentered}`}>Select Card</Typography>
                    </Grid>

                    <Grid item xs = {12}>
                        <Grid container spacing = {4} justify = "center">
                        {baseCards.length > 0 &&

                        baseCards.map((card) => (
                            <Grid 
                            key = {`sel-${card.mediaHash}`}
                            item
                            >
                                <StyledCardContainer
                                imgName = {card.mediaHash}
                                rarity = {card.rarity}
                                count = {1}
                                onClick = {() => selectCard(card)}
                                hover
                                zIndex={990}
                                width="100px"
                                height="150px"
                                />
                            </Grid>
                        ))
                        }
                        </Grid>
                    </Grid>

                    <Grid item xs = {12}>
                        <Divider
                        style = {{
                            backgroundColor: 'white'
                        }}/>
                    </Grid>

                </Grid>
            </Grid>
            }
            <Grid item xs = {12}>
                <Typography variant = "h4" className = {`${styles.textWhite} ${styles.textCentered}`}>Included Cards</Typography>
            </Grid>

            <Grid item xs = {12}>

                <Grid container spacing = {2} justify = "center">


                {includedCards.length > 0 &&
                includedCards.map((card, index) => (
                    <Grid item className = {styles.selectionContainer}>
                        <Grid container spacing = {2} justify = "center">

                            <Grid item style = {{
                                width: 250,
                                height: 350,
                                marginBottom: 25
                            }}>
                                <StyledCardContainer
                                imgName = {card.mediaHash}
                                rarity = {card.rarity}
                                count = {1}
                                zIndex={800}
                                />
                            </Grid>

                            <Grid item>
                                <DeleteIcon 
                                className = {styles.removeCardButton}
                                onClick = {() => cardDeleteClick(card)}
                                />
                            </Grid>

                            <Grid item>
                                <TextField 
                                id="standard-textarea"
                                label = "Weight"
                                variant="outlined"
                                size = "small"
                                type="tel"
                                style={{width: '12ch'}}
                                className = {styles.textFieldProp}
                                InputProps = {{className: styles.textFieldProp}}
                                InputLabelProps = {{className: styles.textFieldLabel}}
                                required
                                error = {!validateNumberInput(includedCards[index].weight, 1)}
                                onChange = {(e) => {
                                    if(e.target.value > 0)
                                        updateSelection(index, "weight", e.target.value)
                                }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                ))

                }
                </Grid>

            </Grid>

        </Grid>
    )

    return (
        (viewIndex <= 1) ? 
        stage1 : stage2
    )

}

const useStyles = makeStyles((theme) => ({
    thumbnail: {
        height: "auto",
        width: "100%"
    },

    selectionContainer: {
        width: 250,
        height: 450,
        margin: theme.spacing(2)
    },

    cardImg: {
        width: 175,
        height: 225
    },

    selectionImg: {
        width: 250,
        height: 350,
        cursor: "pointer"
    },

    removeCardButton:{
        height: 30,
        width: 30,
        padding: 0,
        margin: 0,
        color: "red",
        cursor: "pointer"
    },

    selectionListContainer:{
        backgroundColor: "transparent",
        color: "white",
        borderRadius: 5,
    },

    textFieldProp: {
        color: "white",
        borderBottom: "1px solid white"
    },

    textFieldLabel:{
        color: "white"
    },

    textWhite: {
        color: "white"
    },

    textCentered: {
        textAlign: "center"
    }
}))

export default AdminCardPackComponent;