import React from "react";
import styled from 'styled-components'
import {Grid} from '@material-ui/core';

const StyledWalletButton = styled(Grid)`
    font-weight: bold;
    height:auto;
    font-size: 3vh;
    color: white;
    opacity: 0.7;
    background-color: rgba(0,0,0,0);
    width:inherit;
    cursor: pointer;

    &:hover{
        opacity: 1;
        background-color:black;
    }
`



const WalletButton = ({onLoginCallback, walletConfig, onDismissCallback, onDisconnectCallback}) => {
    return(
        <StyledWalletButton
  
        id={`wallet-button-${walletConfig.title}`}
        onClick={()=>{
      
            onLoginCallback(walletConfig.connectorType, ()=>onDisconnectCallback());
            onDismissCallback();
        }}>
            <Grid container style={{alignItems:'center', justifyContent:'center', padding:'10px'}}>
                <Grid item  style={{marginRight:'10px'}}>  
                {walletConfig.icon}
                </Grid>
                <Grid item >
                {walletConfig.title} 
                </Grid>
            </Grid>
        </StyledWalletButton>
    )
}

export default WalletButton