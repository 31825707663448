import { io } from 'socket.io-client';
import CONFIG from "../../config/config"

// Events generated by the server and pushed to clients
const HIGH_CARD_EVENT = 'high_card_event';
const YOUR_TURN_EVENT = 'your_turn_event';
const ENEMY_TURN_EVENT = 'enemy_turn_event';
const ENEMY_USE_ITEM_EVENT = 'enemy_use_item_event';
const ENEMY_ATTACK_EVENT = 'enemy_attack_event';
const ENEMY_SUMMON_EVENT = 'enemy_summon_event';
const ENEMY_FORFEIT_EVENT = 'enemy_forfeit_event';
const YOU_WON_EVENT = 'you_won_event';
const YOU_LOST_EVENT = 'you_lost_event';
const YOU_TIED_EVENT = 'you_tied_event';
const REJOIN_EVENT = 'rejoin_event';

// Interactions initiated by the client
const REGISTRATION_REQUEST = 'registration_request';
const REGISTRATION_RESPONSE = 'registration_response';
const CANCEL_MATCH_MAKING_REQUEST = 'cancel_match_making_request';
const CANCEL_MATCH_MAKING_RESPONSE = 'cancel_match_making_response';
const MULLIGAN_REQUEST = 'mulligan_request';
const MULLIGAN_RESPONSE = 'mulligan_response';
const DISCARD_REQUEST = 'discard_request';
const DISCARD_RESPONSE = 'discard_response';
const USE_ITEM_REQUEST = 'use_item_request';
const USE_ITEM_RESPONSE = 'use_item_response';
const ATTACK_REQUEST = 'attack_request';
const ATTACK_RESPONSE = 'attack_response';
const SUMMON_REQUEST = 'summon_request';
const SUMMON_RESPONSE = 'summon_response';
const END_TURN_REQUEST = 'end_turn_request';
const END_TURN_RESPONSE = 'end_turn_response';
const FORFEIT_REQUEST = 'forfeit_request';
const FORFEIT_RESPONSE = 'forfeit_response';

const ServerUtil = (handlers, token, unauthorizedErrorHandler) => {
    const jwtBearer = token ? `Bearer ${token}` : '';
    const authHeader = {
        extraHeaders: { Authorization: jwtBearer }
    };
    
    const socket = io.connect(CONFIG.GAME_SERVER_ENDPOINT, authHeader);

    // Catch all error handler
    socket.on('connect_error', handleUnauthorizedRequestEvent);
    
    console.log(`connecting to: ${CONFIG.GAME_SERVER_ENDPOINT}`);

    /**   Register handlers for game server messages  */
    //Asynchronous events from the server
    socket.on(HIGH_CARD_EVENT, handlers.highCardEventHandler);
    socket.on(YOUR_TURN_EVENT, handlers.yourTurnEventHandler);
    socket.on(ENEMY_TURN_EVENT, handlers.enemyTurnEventHandler);
    socket.on(ENEMY_USE_ITEM_EVENT, handlers.enemyUseItemEventHandler);
    socket.on(ENEMY_ATTACK_EVENT, handlers.enemyAttackEventHandler);
    socket.on(ENEMY_SUMMON_EVENT, handlers.enemySummonEventHandler);
    socket.on(ENEMY_FORFEIT_EVENT, handlers.enemyForfeitEventHandler);
    socket.on(YOU_WON_EVENT, handlers.youWonEventHandler);
    socket.on(YOU_LOST_EVENT, handlers.youLostEventHandler);
    socket.on(YOU_TIED_EVENT, handlers.youTiedEventHandler);
    socket.on(REJOIN_EVENT, handlers.rejoinEventHandler);
    socket.on("disconnect", (reason) => {
        console.log("This client has been disconnected from the game server.");
        socket.removeAllListeners();
        socket.close();
        handlers.disconnectEventHandler();
    });

    //Responses from the server to our requests
    socket.on(REGISTRATION_RESPONSE, handlers.registrationHandler);
    socket.on(CANCEL_MATCH_MAKING_RESPONSE, handlers.cancelMatchMakingResponseHandler);
    socket.on(MULLIGAN_RESPONSE, handlers.mulliganResponseHandler);
    socket.on(DISCARD_RESPONSE, handlers.discardResponseHandler);
    socket.on(USE_ITEM_RESPONSE, handlers.useItemResponseHandler);
    socket.on(ATTACK_RESPONSE, handlers.attackResponseHandler);
    socket.on(SUMMON_RESPONSE, handlers.summonResponseHandler);
    socket.on(END_TURN_RESPONSE, handlers.endTurnResponseHandler);
    socket.on(FORFEIT_RESPONSE, handlers.forfeitResponseHandler);

    function handleUnauthorizedRequestEvent(error) {
        if (error && error.data && error.data.type === 'UnauthorizedError') {
            // redirect user to login page perhaps
            socket.disconnect();
            if (unauthorizedErrorHandler) {
                unauthorizedErrorHandler(error.data);
            }
        }
    }

    async function attemptRegisterPlayer(userId, deckId){
        socket.emit(REGISTRATION_REQUEST, { userId: userId, deckId: deckId });
    };

    async function attemptCancelMatchMaking(userId){
        socket.emit(CANCEL_MATCH_MAKING_REQUEST, { userId: userId });
    };

    async function attemptMulligan(){
        socket.emit(MULLIGAN_REQUEST);
    };

    async function attemptDiscard(cardUniqueId){
        socket.emit(DISCARD_REQUEST, { uniqueId: cardUniqueId });
    };

    async function attemptUseItem(cardId, uniqueId){
        socket.emit(USE_ITEM_REQUEST, { cardId: cardId, uniqueId: uniqueId });
    };

    async function attemptAttack(cardId, uniqueId, laneNumber){
        socket.emit(ATTACK_REQUEST, { cardId: cardId, uniqueId: uniqueId, laneNumber: laneNumber })
    };

    async function attemptSummon(cardId, uniqueId, laneNumber){
        socket.emit(SUMMON_REQUEST, { cardId: cardId, uniqueId: uniqueId, laneNumber: laneNumber });        
    };

    async function attemptEndTurn(){
        socket.emit(END_TURN_REQUEST);
    };

    async function attemptForfeit(){
        socket.emit(FORFEIT_REQUEST);
    };

    return {
        attemptRegisterPlayer: attemptRegisterPlayer,
        attemptCancelMatchMaking: attemptCancelMatchMaking,
        attemptMulligan: attemptMulligan,
        attemptDiscard: attemptDiscard,
        attemptUseItem: attemptUseItem,
        attemptAttack: attemptAttack,
        attemptSummon: attemptSummon,
        attemptEndTurn: attemptEndTurn,
        attemptForfeit: attemptForfeit
    };
}

export default ServerUtil;
