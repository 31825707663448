const CONFIG = {
    //The endpoint used for the calls to the asp net api.
    API_ENDPOINT : "https://api.arenaofexiles.com/api",

    //The base URL for our frontend.
    BASE_URL : "https://arenaofexiles.com/",

    //The ethereum node used for web3.
    ETH_NODE : "https://speedy-nodes-nyc.moralis.io/69d13ef42bd87b1153364857/eth/mainnet",

    //ETH MAINNET chain id
    CHAIN_ID : "1",

    //Chain Name
    CHAIN_NAME: "Ethereum",

    //Game Server URL
    GAME_SERVER_ENDPOINT : 'wss://gameserver.arenaofexiles.com/',

    CORS_ORIGIN : 'https://arenaofexiles.com/',
    
    //Enables client-side features only meant for debugging and development
    DEBUG_MODE : false,

    //Switch Price between Actual and TBD
    SHOW_PRICE: true
}

export default CONFIG;