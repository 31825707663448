import React, { createContext, useContext } from "react";
import {useStore} from "./GlobalStore"
import {  getUserAddress, 
          getMediaTokenContract, 
          getPaymentManagerContract,
          getClaimManagerContract,
          getPromoManagerContract,
          getProvider
         } from '../contracts/useContract';

export const ContractAPIContext = createContext();

export const ContractAPIProvider = ({children}) => {

  const { ZERO_ADDRESS,
          mediaToken, setMediaToken,
          paymentManager, setPaymentManager,
          claimManager, setClaimManager,
          promoManager, setPromoManager,
          userAddress, setUserAddress} = useStore();

  const getWeb3Provider = async () => {
    return await getProvider()
    .then(res => {
      return res
    })
    .catch(err => {
      console.error(err)
      return null
    })
  }

  const initMetamask = async () => {
    if (userAddress === undefined || userAddress === null || userAddress === ZERO_ADDRESS){
      
      return await getUserAddress().then((res) => {
        setUserAddress(res.uAddr[0]);
        return res
      })
      .catch(error => {
        console.error(error);
        return undefined;
      });
    }

    else return userAddress
  }

  const truncateAddress = () => {

    if (userAddress === undefined || userAddress === null)
        return "";

    var addr = "";
    addr = addr.concat(userAddress)

    return addr.substring(0,6) + "..." + addr.substring(addr.length - 4, addr.length)
  }


  const initMediaToken = async () => {
    if (mediaToken === null || mediaToken === undefined){

      return await getMediaTokenContract().then((res) => {
          setMediaToken(res.contract); 
          return res.contract;
      })
      .catch(error => {
        console.error(error);
        return undefined;
      });

    }

    else return mediaToken;
  }

  const addMediaTokenAdmin = async (address) => {
      await mediaToken.addAdmin(address)
          .then(res => res)
          .catch(error => console.error(error))

  }

  const addMediaTokenCreator = async (address) => {
      await mediaToken.addCreator(address)
          .then(res => res)
          .catch(error => console.error(error))
  }

  const mintNft = async (address, meta, media, season) => {

    const token = await mediaToken.mintNft(address, meta, media, season)
    .then(res => res)
    .catch(error => console.error(error))
    
    return token
  }

  const getOwnerOfToken = async (tokenId) => {

    const owner = await mediaToken.getOwnerOf(tokenId)
    .then(res => res)
    .catch(error => console.error(error))

    return owner;
  }

  const getPaymentManager = async () => {
    if(paymentManager === undefined || paymentManager === null){
      return await getPaymentManagerContract().then((res) => {
        setPaymentManager(res.contract);
        return res.contract
      })
      .catch(error => {
        console.error(error)
        return undefined;
      });
    }
    else return paymentManager;
  }
  
  const getClaimManager = async () => {
    if(claimManager === undefined || claimManager === null){
      return await getClaimManagerContract().then((res) => {
        setClaimManager(res.contract);
        return res.contract;
      })
      .catch(error => {
        console.error(error);
        return undefined;
      });
    }
  }

  const getPromoManager = async () => {
    return await getPromoManagerContract().then((res) => {
      setPromoManager(res.contract);
      return res.contract;
    })
    .catch(error => {
      console.error(error);
      return undefined;
    });
  }

  return (
      <ContractAPIContext.Provider value = {
      {
        initMediaToken,
        initMetamask,
        truncateAddress,
        mintNft,
        addMediaTokenAdmin,
        addMediaTokenCreator,
        getOwnerOfToken,
        getPaymentManager,
        getClaimManager,
        getPromoManager,
        getWeb3Provider
      }
      }
      >
  
        {children}
  
      </ContractAPIContext.Provider>
    )
}

export const useContractAPI = () => useContext(ContractAPIContext);