
export const arenaFuture = [
    {
        title: 'Single Player Mode',
        content: 'Looking to test your new deck against an AI player? Look no farther, an aggressive AI will test your mettle before you jump back in the arena with other Exiles.',
        date: 'July 2022',
    },
    {
        title: 'Official Cross-Chain Release',
        content: 'Alpha and Beta testing phases were incredible. The feedback has been rolled in and we are preparing to launch on BSC alongside Ethereum. More chains are in the works.',
        date: 'Aug 2022',
    },
    {
        title: 'Season 2 Release',
        content: 'Forty Brand new champions will be added to Valmotia! When season 2 launches, the season one champions will only be available from the Feeling Lucky store option.',
        date: 'Nov 2022',
    },
    {
        title: 'New Game Modes',
        content: 'We are planning to add a brand new game mode that implements some new features and mechanics- elemental attack variants among others.',
        date: 'Nov 2022',
    },
]