
/*
    art = {
        atlas: "atlas", (optional)
        artName: "art",
        artNameOnHover: "art-hover",
        artNameOnDisabled: "art-disabled"
    }

*/
const GenericButton = (buttonId, scene, art, scale, position, onClick, cardBack) => {

    const CARD_FLIP_SCALE_Y = 1.2;
    const CARD_FLIP_SPEED_MS = 300;

    var genericButton;
    var buttonOnClick = onClick;
    var isButtonDisabled = false;
    var isFlipping = false;

    var flipTween;
    var flipBackTween

    //Add image to the scene which will act as a button
    if(!cardBack){
        if (art.atlas){
            genericButton = scene.add.image(position.x, position.y, art.atlas, art.artName)
            .setDepth(position.z)
            .setOrigin(0.5)
            .setScale(scale)
            .setInteractive({ useHandCursor: true });
        } else {
            genericButton = scene.add.image(position.x, position.y, art.artName)
            .setDepth(position.z)
            .setOrigin(0.5)
            .setScale(scale)
            .setInteractive({ useHandCursor: true });
        }
    }
    else{
        genericButton = scene.add.image(position.x, position.y, cardBack)
        .setDepth(position.z)
        .setOrigin(0.5)
        .setScale(scale)
        .setInteractive({ useHandCursor: true });
        

        flipTween = scene.tweens.create({
            targets: genericButton,
            scaleY: CARD_FLIP_SCALE_Y * scale,
            scaleX: 0,
            duration: CARD_FLIP_SPEED_MS / 2,
            ease: 'Linear'
        });
    
    
        flipBackTween = scene.tweens.create({
            targets: genericButton,
            scaleY: scale,
            scaleX: scale,
            duration: CARD_FLIP_SPEED_MS / 2,
            ease: 'Linear'
        });

        if(!isFlipping){
            isFlipping = true;
            genericButton.removeInteractive();
            isButtonDisabled = true;
            animateFlip();
        }
    }
   

    /* We have to do all this pointer up and down bullshit because 
        fucking Phaser 3 doesn't god damn have onClick */
    var genericButtonDown = false;
    genericButton.on('pointerdown', function(pointer) {
        if (isButtonDisabled) return;

        genericButtonDown = true;
    });
    genericButton.on('pointerout', function(pointer){
        if (isButtonDisabled) return;

        genericButtonDown = false;
        art.artNameOnHover && genericButton.setTexture(art.artName); //if a hover art was provided, change it back to non-hover
    });
    genericButton.on('pointerover', function(pointer){
        if (isButtonDisabled) return;

        art.artNameOnHover && genericButton.setTexture(art.artNameOnHover); //if a hover art was provided, change to it
    });
    genericButton.on('pointerup', function(pointer) {
        if (isButtonDisabled) return;

        if (!genericButtonDown)
            return;
            
        genericButtonDown = false;

        buttonOnClick(buttonId); //Let the callback handler know which button was pressed
    });

    function disableButton(){
        if (!art.artNameOnDisabled || !scene) return; //If no disabled art was provided, don't allow disabling
        isButtonDisabled = true;
        genericButton.setTexture(art.artNameOnDisabled);
        genericButton.removeInteractive()
    }

    function enableButton(){
        if (!art.artNameOnDisabled || !scene) return; //If no disabled art was provided, don't allow disabling
        isButtonDisabled = false;
        genericButton.setTexture(art.artName);
        genericButton.setInteractive({ useHandCursor: true });
    }
    
    function makeNonInteractive(){
        genericButton.removeInteractive();
    }

    function visiblize(){
        genericButton.setVisible(true);
    }
    function invisiblize(){
        genericButton.setVisible(false);
    }
    function isVisible(){
        return genericButton.visible;
    }
    function destroy() {
        genericButton.destroy();
    }
    function getPosition(){
        return {
            x: genericButton.x, 
            y: genericButton.y, 
            z: genericButton.depth
        }
    }
    function setPosition(x, y){
        genericButton.setPosition(x, y);
    }
    function setDepth(z) {
        genericButton.setDepth(z);
    }
    function getDimensions(){
        return {
            width: genericButton.width * scale,
            height: genericButton.height * scale
        }
    }

    function setCallback(callback){
        buttonOnClick = callback;
    }

    function animateFlip(){
        // First tween: We raise and flip the card

        if(!cardBack){
            console.error("GenericButton: This button is not flippable");
            return;
        }

        if(genericButton === null || genericButton === undefined){
            console.error("Who is button?");
            return;
        }

        // start the first of the two flipping animations
        if(!flipTween.isPlaying() && !flipBackTween.isPlaying()){
            flipTween.play();
        }

        // Second tween: we complete the flip and lower the card
        flipTween.on('complete', () => {
            genericButton.setTexture(art.atlas, art.artName);
            flipBackTween.play();
        });
        flipBackTween.on('complete', () => {
            isFlipping = false; 
            isButtonDisabled = false;
        });
        genericButton.setInteractive({ useHandCursor: true });
    }

    //Use very carefully
    function getUnderlyingImageObjects() {
        return [genericButton];
    }

    return {
        buttonId: buttonId,
        disableButton: disableButton,
        enableButton: enableButton,
        makeNonInteractive: makeNonInteractive,
        visiblize: visiblize,
        invisiblize: invisiblize,
        isVisible: isVisible,
        getPosition: getPosition,
        setPosition: setPosition,
        setDepth: setDepth,
        getDimensions: getDimensions,
        setCallback: setCallback,
        getUnderlyingImageObjects: getUnderlyingImageObjects,
        destroy: destroy,
    }

}

export default GenericButton;
