import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Typography, Tooltip, Button, TextField, Divider, FormControl, MenuItem, Select, Fab, Popover, BackDrop, Backdrop } from "@material-ui/core"
import AddIcon from '@material-ui/icons/Add';
import { useStore } from "../helpers/GlobalStore"
import { useBackendAPI } from '../helpers/BackendAPI';
import StyledCardContainer from "../components/StyledCardContainer"
import { rarityColors } from '../helpers/GlobalStore';
import CompendiumUtil from "../pages/compendium/CompendiumUtil"
import BackgroundManager from '../components/BackgroundImageManager'
import ArtLoader from "../assets/CardArtProvider"
import RemoveIcon from '@material-ui/icons/Remove';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import CardsInDeck from '../components/CardsInDeckWidget';
import { Close } from '@material-ui/icons';
import CardPopover from '../components/CardPopover';
import CardBlowUpWidget from '../components/CardBlowUpWidget';
import SvgButton from "../components/SvgButton";
import nextId from "react-id-generator";

const DecksPage = () => {

    const styles = useStyles();
    var compendiumUtil = CompendiumUtil();

    const { userRoles, 
            decks,
            deckCardCounts,
            compendium,
            childUpdateNeeded,
            setChildUpdateNeeded
        } = useStore();

    const { getDecks, 
            getCardCountsInDeck, 
            createDeck, addCardToDeck, 
            removeCardFromDeck, 
            deleteDeck, 
            getCardCounts, 
            editDeckName} = useBackendAPI()

    const {getArt} = ArtLoader()

    const [pageIndex, setPageIndex] = useState("main")
    const [isPageLoaded, setIsPageLoaded] = useState(false)
    const [createDeckName, setCreateDeckName] = useState("")
    const [editNameState, setEditNameState] = useState(false)
    const [editNameText, setEditNameText] = useState("")

    const [selectedDeck, setSelectedDeck] = useState()

    const [nameSearch, setNameSearch] = useState("")
    const [editionSearch, setEditionSearch] = useState()
    const [sortBy, setSortBy] = useState("name")
    const [filterBy, setFilterBy] = useState("all")

    const [totalInvalid, setTotalInvalid] = useState(0)

    const [deckRequestInProgress, setDeckRequestInProgress] = useState(false)
    const [popoverEnabled, setPopoverEnabled] = useState(false)
    const [selectedCard, setSelectedCard] = useState(null)
    const [createDeckPopoverEnabled, setCreateDeckPopoverEnabled] = useState(false)
    const [createMode, setCreateMode] = useState(true) // True sets the mode to the create deck popover, false sets it to the edit name popover

    useEffect(() => {    
        if(!isPageLoaded && userRoles !== ''){
            loadRequiredData()
            setIsPageLoaded(true)
        }
    }, [userRoles])

    useEffect(() => {
        if(childUpdateNeeded)
            setChildUpdateNeeded(false)
    }, [childUpdateNeeded, setChildUpdateNeeded])

    const loadRequiredData = async () => {
        await getDecks()
        await getCardCounts()
    }

    const handleCreateClick = async () => {

        const request = {
            DeckName: createDeckName
        }

        await createDeck(request).then(deckId => {
            setCreateDeckName("")
            setSelectedDeck(decks.get(deckId))
            handleEditClick(deckId)
            setCreateDeckPopoverEnabled(false)
        })
    }

    const setSelectedCardCallback = ({cardId}) => {
        let card = compendium.get(cardId)
        setSelectedCard(card)
        setPopoverEnabled(true)
    }

    const handleDeckClick = async (deck) => {
        if(selectedDeck !== undefined && deck.deckId === selectedDeck.deckId)
            setSelectedDeck(undefined)
        else  setSelectedDeck(deck)
    }

    const handleDeleteDeckClick = async (deckId) => {
        
        await deleteDeck(deckId).then(() => {
            setSelectedDeck(undefined)
        })
    }

    const handleCardAdd = async (card) => {
        if(card.playable === 1) {
            setDeckRequestInProgress(true)
            await addCardToDeck({deckId: selectedDeck.deckId, cardId: card.cardId}).then(() => {
                setDeckRequestInProgress(false)
            })
        }
    }

    const handleCardRemove = async (card) => {
        setDeckRequestInProgress(true)
        const deckItem = deckCardCounts.get(card.cardId)
        const request = {deckId: selectedDeck.deckId, cardId: card.cardId, IsInvalid: deckItem.invalidCount > 0}
        console.log(selectedDeck)
        console.log(decks.get(selectedDeck.deckId))
        console.log(deckCardCounts)
        await removeCardFromDeck(request).then(() => {
            if(request.IsInvalid > 0){
                setTotalInvalid(totalInvalid - 1)
            }
            setDeckRequestInProgress(false)
        })
    }
    
    const deckWidgetRemoveCardCallback = async (card, deckId) =>{
        setDeckRequestInProgress(true)
        const request = {deckId: deckId, cardId: card.cardId, IsInvalid: card.invalidCount > 0}
        await removeCardFromDeck(request).then(() => {
            if(request.IsInvalid > 0){

                setTotalInvalid(totalInvalid - 1)
            }
            setDeckRequestInProgress(false)
        })
    }

    const handleEditClick = async (deckId) => {
        setTotalInvalid(0)
        deckCardCounts.clear()
        setSelectedDeck(decks.get(deckId))
        await getCardCountsInDeck(deckId).then(() => {
            setPageIndex("editDeck")
            let ti = 0
            deckCardCounts.forEach(card => {
                let cardQuantityInDeck = getCardCountInDeck(card)

                if((card.count - card.invalidCount < cardQuantityInDeck)){
                    ti += card.invalidCount
                }
            })
            setTotalInvalid(ti)
        })
    }

    const handleBackClick = () => {
        setCreateDeckName("")
        setPageIndex("main")
        setSelectedDeck(undefined)
    }

    const getCardCountInDeck = (card) => {
        let cardCount = deckCardCounts.get(card.cardId)
        if(cardCount !== null && cardCount !== undefined)
            return cardCount.count
        else return 0
    }

    const getCardInvalidCount = (card) => {
        let cardCount = deckCardCounts.get(card.cardId)
        if(cardCount !== null && cardCount !== undefined)
            return cardCount.invalidCount
        else return 0
    }

    const getCardCountOwned = (card) => {
        let ownedCard = compendium.get(card.cardId)
        if (ownedCard !== null && ownedCard !== undefined)
            return ownedCard.count
        else return 0
    }

    const shouldDisableAddCard = (card) => {

        //First check if the deck has already reached its card capacity.
        if (selectedDeck.numCards >= selectedDeck.capacity)
            return true

        //Check if there are any invalid cards.  If there are any, don't allow the user to add any more until the invalid ones are removed.
        if(getCardInvalidCount(card) > 0)
            return true

        const addedCount = getCardCountInDeck(card) 
        const ownedCount = getCardCountOwned(card)
        return deckRequestInProgress || addedCount >= ownedCount
    }

    const shouldDisableRemoveCard = (card) => {
        const addedCount = getCardCountInDeck(card)
        return deckRequestInProgress || addedCount <= 0
    }

    const handleEditNameClick = () => {
        setCreateMode(false);
        setEditNameText(selectedDeck.deckName)
        setCreateDeckPopoverEnabled(true)
    }

    const handleEditNameCancel = () => {
        setEditNameText("")
        setEditNameState(false)
        setCreateDeckPopoverEnabled(false)
    }

    const handleSaveName = async () => {
        await editDeckName({deckId: selectedDeck.deckId, deckName: editNameText})
        .then(() => {
            setEditNameState(false)
            setChildUpdateNeeded(false)
            setEditNameText("")
            setCreateDeckPopoverEnabled(false);
        })
    }

    const handleCreateAndEditPopoverClose = () => {
        setEditNameText("")
        setCreateDeckName("")
        setCreateDeckPopoverEnabled(false);
    }

    const disableCreateDeckButton = () => {
        return createDeckName.length < 1 || deckNameTooLong() || deckNameAlreadyInUse();
    }

    const deckNameAlreadyInUse = () => {
        if (!decks)
            return false;

        let decksArray = decks.values();

        for (let i = 0; i<decksArray.length; i++){
            let deck = decksArray[i];
            if (deck.deckName.toLowerCase() === createDeckName.toLowerCase() || ((deck.deckName.toLowerCase() === editNameText.toLowerCase()) && (deck.deckId !== selectedDeck.deckId)))
                return true;
        }

        return false;
    }

    const deckNameTooLong = () => {
        return createDeckName.length > 45;
    }

    const editDeckNamePopoverContents = (

        <Grid item xs={10}>
            <Grid container style={{justifyContent:'center', alignItems:'center'}} spacing = {2}>
                <Grid item>
                    <Fab 
                    size="small" 
                    classes={{root: styles.fabGreenStyle }}
                    onClick = {()=>handleSaveName()}
                    disabled = {deckNameTooLong() || deckNameAlreadyInUse() || editNameText.length <= 0}
                    >
                        <CheckIcon 
                        style = {{
                            fill: "white",
                            width: 30,
                            height: 30
                        }}
                        />
                    </Fab>
                </Grid>

                <Grid item>
                    <Fab 
                    size="small" 
                    style = {{
                        backgroundColor: "red"
                    }}
                    onClick = {()=>handleEditNameCancel()}
                    >
                        <ClearIcon 
                        style = {{
                            fill: "white",
                            width: 30,
                            height: 30
                        }}
                        />
                    </Fab>
                </Grid>

                <Grid item xs={11} md = {9} lg = {8}>
                    <TextField
                    value = {editNameText}
                    onChange = {(e) => setEditNameText(e.target.value)}
                    InputProps = {{className: styles.editNameField}}
                    error = {deckNameTooLong() || deckNameAlreadyInUse()}
                    helperText = {deckNameAlreadyInUse() ? "That deck name is already in use, choose a unique name." : (deckNameTooLong() ? "The deck name can't be more than 45 characters" : "")}
                    fullWidth
                    />
                </Grid>
            </Grid>
        </Grid>
    )

    const createDeckPopupContents = (
  
        <Grid container  style={{justifyContent:'center', alignItems:'center', verticalAlign:'center', height:'auto'}}>
            <Grid item xs={11}  style={{justifyContent:'center', alignItems:'center', verticalAlign:'center', height:'auto'}} >
                <TextField
                variant = "outlined"
                type = "search"
                size="small"
                label = "New Deck Name"
                value = {createDeckName}
                onChange = {(e) => setCreateDeckName(e.target.value)}
                className = {styles.textFieldProp}
                InputProps = {{className: styles.textFieldProp}}
                InputLabelProps = {{className: styles.textFieldLabel}}
                error = {deckNameTooLong() || deckNameAlreadyInUse()}
                helperText = {deckNameAlreadyInUse() ? "That deck name is already in use, choose a unique name." : (deckNameTooLong() ? "The deck name can't be more than 45 characters" : "")}
                fullWidth
                />
            </Grid>
            <Tooltip title={disableCreateDeckButton() ? (deckNameAlreadyInUse() ? "That deck name is already in use, choose a unique name." : "The deck name must be at least 1 character long and no longer than 45 characters") : ""}>
                <Grid item xs={11} style={{ marginBottom:'25px', marginTop:'30px', justifyContent:'center', alignItems:'center'}}>
                        <Grid item style={{height:'64px', width:'320px', textAlign:'center', alignItems:'center', margin:'auto'}}>
                            <SvgButton 
                                onClick={() => handleCreateClick()} 
                                displayText='Create Deck' 
                                fontSize='18pt' 
                                buttonWidth={320} 
                                buttonHeight={64}
                                disabled = {disableCreateDeckButton()}
                            />
                        </Grid>
                    
                
                </Grid>
            </Tooltip>
        </Grid>
    )
    

    const renderMainView = (
        <Grid container style={{justifyContent:'center', alignItems:'center'}}>
            <Grid item xs={12}>
                <Grid 
                container 
                className = "newdc" 
                >
                    <Grid item xs={12} style={{marginBottom:'25px'}}>

                        <Grid item style={{height:'75px', width:'340px', textAlign:'center', alignItems:'center', margin:'auto'}}>
                            <SvgButton 
                                onClick = {() => { setCreateMode(true); setCreateDeckPopoverEnabled(true)}}
                                displayText='Create New Deck' 
                                fontSize='16pt' 
                                buttonWidth={340} 
                                buttonHeight={75}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
            <Grid item>
            {decks && decks.size !== 0 &&
                <Grid container style={{justifyContent:'center', marginTop:'20px'}} >
                {decks.values().map(deck => (
                    <Grid item className = {styles.deckContainer} key = {`deck-${deck.deckId}`} >
                        <Grid container style={{justifyContent:'center'}} spacing = {2}>
                            <Grid item style={{ width: 300, height: 420, marginTop:'20px' }}>
                                <StyledCardContainer
                                imgName = {"common-card-back"}
                                rarity = {(selectedDeck !== undefined && selectedDeck.deckId === deck.deckId) ? 8 : 16}
                                count = {1}
                                onClick = {handleEditClick.bind(this, deck.deckId)}
                                hover
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography 
                                variant = "h5" 
                                className = {`${styles.deckNameHeader} ${(selectedDeck !== undefined && selectedDeck.deckId === deck.deckId) ? styles.textSelected : styles.textNotSelected}`}
                                >
                                    {deck.deckName}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography 
                                variant = "body1" 
                                className = {`${styles.textCentered} ${(selectedDeck !== undefined && selectedDeck.deckId === deck.deckId) ? styles.textSelected : styles.textWhite}`}
                                >
                                    {`Cards:  ${deck.numCards} / ${deck.capacity}`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style = {{marginTop: 25, marginBottom: 25}}>
                            <Grid container spacing = {4} style={{justifyContent:'center'}}>
                                <Grid item>
                                    <Button 
                                    variant = "outlined" 
                                    className = "buttonRibbon"
                                    onClick = { handleDeleteDeckClick.bind(this, deck.deckId)}
                                    >
                                        Delete Deck
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ))
                }
                </Grid>
            }
            </Grid>
        </Grid>
    )

    const checkFilters = (item) => {
        if(item.playable === 0)
            return false
        
        if(item.count === 0)
            return false

        let nameFlag = false
        let editionFlag = false
        let filterFlag = false

        const edition = parseInt(editionSearch)

        if(nameSearch === "")
            nameFlag = true

        else if(nameSearch !== "" && item.name.toLowerCase().includes(nameSearch.toLowerCase()))
            nameFlag = true

        if(edition === 0 || edition === NaN || editionSearch === "" || editionSearch === undefined)
            editionFlag = true

        else if(edition !== 0 && edition === item.edition)
            editionFlag = true

        const numInDeck = getCardCountInDeck(item)

        if(filterBy === "all")
            filterFlag = true

        else if(filterBy === "only in deck" && numInDeck > 0)
            filterFlag = true

        else if(filterBy === "not in deck" && numInDeck === 0)
            filterFlag = true

        if(nameFlag && editionFlag && filterFlag)
            return true

        else return false
    }

    const renderAvailableCards = () => {
        let elements = []
        let data = []

        compendium.values().forEach(card => {
            if(checkFilters(card) === true)
                data.push(card)
        })
        
        if(sortBy === "rarity") {
            data = data.sort((a,b) => {
                if(a.rarityId < b.rarityId )
                    return 1
                else if(a.rarityId > b.rarityId )
                    return -1
                else return 0
            })
        }

        else if(sortBy === "name") {
            data = data.sort((a,b) => {
                if(a.name.toLowerCase() > b.name.toLowerCase() )
                    return 1
                else if(a.name.toLowerCase() < b.name.toLowerCase() )
                    return -1
            })
        }

        else if(sortBy === "# owned"){
            data = data.sort((a,b) => {
                if(a.count < b.count)
                    return 1
                else if(a.count > b.count)
                    return -1
                else return 0
            })
        }

        else if(sortBy === "# in deck"){
            data = data.sort((a,b) => {
                let aCount = getCardCountInDeck(a)
                let bCount = getCardCountInDeck(b)
                if(aCount < bCount)
                    return 1
                else if(aCount > bCount)
                    return -1
                else return 0
            })
        }

        data.map(card => {

            let invalidCardCount = getCardInvalidCount(card)
            let ownedCardCount = getCardCountOwned(card)
            let ownedCountStr = `${ownedCardCount}`

            if(ownedCardCount > 99)
                ownedCountStr = "99+"

            elements.push(
                <Grid id="ItemMainGridItem" item key = {`cardContainer-${nextId()}`} className = {styles.cardImage}>
                    <Grid 
                    container 
                    id="ItemGridContainer"
                    style = {{
                        height: "auto"
                    }}
                    >
                        <Grid 
                        id="ImageGrid" 
                        item 
                        xs={12}
                        style = {{
                            marginBottom: 10
                        }}
                        onClick={()=>{setSelectedCard(card); setPopoverEnabled(true)}}
                        >
                            <StyledCardContainer
                            imgName = {card.cardId}
                            rarity = {(invalidCardCount === 0) ? card.rarityId : "0"}
                            count = {card.count}
                            width="225px"
                            height="325px"
                            hover
                            />
                        </Grid>

                        <Grid item style = {{marginRight: 25, marginBottom: 10}}>
                            <Typography variant = "body1" style = {{color: (invalidCardCount === 0) ? "white" : "red", textAlign: "left"}}>{`In Deck: ${getCardCountInDeck(card)}`}</Typography>
                            <Typography variant = "body1" style = {{color: (invalidCardCount === 0) ? "white" : "red", textAlign: "left"}}>{`Owned:   ${ownedCountStr}`}</Typography>
                            {getCardInvalidCount(card) > 0 &&
                            <Typography variant = "body1" style = {{color: "red", textAlign: "left"}}>{`# Invalid: ${invalidCardCount}`}</Typography>
                            }
                        </Grid>

                        <Grid item>
                            <Fab 
                                size="small" 
                                aria-label="remove" 
                                color="primary"
                                style={{ marginRight: '10px' }}
                                onClick = {() => handleCardAdd(card)}
                                disabled = {shouldDisableAddCard(card)}
                            >
                                <AddIcon />
                            </Fab>
                        </Grid>

                        <Grid item>
                            <Fab 
                                size="small" 
                                color="secondary" 
                                aria-label="remove" 
                                onClick = {() => handleCardRemove(card)}
                                disabled = {shouldDisableRemoveCard(card)}
                            >
                                <RemoveIcon />
                            </Fab>
                        </Grid>
                        
                    </Grid>
                </Grid>
            )
        })


        return elements
    }

    

    const renderEditView = (
        (selectedDeck !== undefined && pageIndex === "editDeck") ?

        <Grid container spacing = {6} style = {{marginBottom: 100, justifyContent:'center'}}>

            <Grid 
            item 
            xs={12} 
            style = {{
                display: "flex",
                justifyContent: "center"
            }}
            >
                <Button
                variant = "outlined"
                className = "buttonRibbon"
                onClick = {() => handleBackClick()}
                >
                    Back To Decks
                </Button>
            </Grid>

            <Grid item xs={12}>
                <Grid container style={{justifyContent:'center', alignItems:'center'}} spacing = {4}>
                    <Grid item>

                        <Fab 
                        size="small" 
                        classes={{root: styles.fabGreenStyle }}
                        onClick = {()=>handleEditNameClick()}
                        >
                            <EditIcon 
                            style = {{
                                fill: "white",
                                width: 30,
                                height: 30
                            }}
                            />
                        </Fab>
                    </Grid>

                    <Grid item>
                        <Typography variant = "h3" className = {`${styles.textWhite} ${styles.textCentered}`}>{selectedDeck.deckName}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container style={{justifyContent:'center', alignItems:'flex-end'}} spacing = {4}>
                    <Typography variant = "h4" className = {`${styles.textWhite} ${styles.textLeft}`}>{`Cards in deck: ${selectedDeck.numCards} / ${selectedDeck.capacity}`}</Typography>
                </Grid>
            </Grid>

            <Grid item xs={11}>
                <Divider style = {{
                    backgroundColor: "white"
                }}/>
            </Grid>

            {totalInvalid > 0 &&
            <Grid item key={nextId()} className = {styles.invalidContainer}>
                <Grid container spacing = {2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" style = {{color: "red", textAlign: "center"}}>
                            This deck has cards that you don't own anymore:
                        </Typography>
                    </Grid>

                    <Grid  key={nextId()} item xs={12}>
                        <Grid 
                        container 
                        wrap="nowrap" 
                        style = {{
                            display: "flex", 
                            justifyContent: "center",
                            maxWidth: "auto", 
                            maxHeight: 225, 
                            overflowY: "hidden",
                            overflowX: "auto", 
                            marginTop: 25,
                            marginBottom: 25
                        }}>
                        {deckCardCounts.values().map(card => (
                            (card.invalidCount > 0) &&
                            <Grid item style = {{margin: 10}}  key={nextId()}>
                                <StyledCardContainer
                                imgName = {card.cardId}
                                count = {1}
                                rarity = {"0"}
                                width="100px"
                                height="160px"
                                />
                            </Grid>
                        ))

                        }
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" style = {{color: "red", textAlign: "center"}}> 
                        To play with this deck, please remove the invalid cards.
                    </Typography>
                </Grid>
            </Grid>
            }

            <Grid item xs={12}>                
                <Grid container style={{justifyContent:'center', alignItems:'flex-end'}} spacing = {4}>
                    <Grid item>
                        <FormControl component="fieldset">
                            <div className = {styles.textWhite}>Sort By:</div>
                            <Select
                                value={sortBy}
                                variant = "outlined"
                                label="Sort By"
                                onChange={(e) => setSortBy(e.target.value)}
                                className = {styles.filterFieldProp}
                                inputProps = {{classes: {
                                    icon: styles.textFieldLabel
                                }}}
                            >
                                <MenuItem value={"name"}>Name</MenuItem>
                                <MenuItem value={"rarity"}>Rarity</MenuItem>
                                <MenuItem value={"# owned"}># Owned</MenuItem>
                                <MenuItem value={"# in deck"}># In Deck</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item>
                        <FormControl component="fieldset">
                            <div className = {styles.textWhite}>Show:</div>
                            <Select
                                value={filterBy}
                                variant = "outlined"
                                label="Show:"
                                onChange={(e) => setFilterBy(e.target.value)}
                                className = {styles.filterFieldProp}
                                inputProps = {{classes: {
                                    icon: styles.textFieldLabel
                                }}}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={"only in deck"}>Only In Deck</MenuItem>
                                <MenuItem value={"not in deck"}>Not In Deck</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item>
                        <TextField
                        label = "Card Name"
                        value = {nameSearch}
                        style = {{width: "32ch"}}
                        onChange = {(e) => setNameSearch(e.target.value)}
                        variant = "outlined"
                        type = "search"
                        size="small"
                        className = {styles.textFieldProp}
                        InputProps = {{className: styles.textFieldProp}}
                        InputLabelProps = {{className: styles.textFieldLabel}}
                        fullWidth
                        />
                    </Grid>

                    
                </Grid>
            </Grid>

            <Grid item xs={12}>
                {compendiumUtil.size !== 0 &&
                <Grid 
                container 
                style={{justifyContent:'center'}}
                >
                    {renderAvailableCards()}
                </Grid>
                }
            </Grid>

        </Grid>
        :
        <div></div>
    )
    
    return (
        <Grid container id="DeckPage-MainGrid" style = {{width: "100vw", height: "auto", marginTop: 50, justifyContent:'center', alignItems:'flex-start',overflowX:'hidden'}}>
           
            <BackgroundManager sourceImage={getArt('ProfileBG')} blur='0px' />
           
            {userRoles === "" &&
                <Grid item>
                    <Typography variant = "h2" className = {`${styles.textWhite} ${styles.textCentered}`}>Log in to see your decks</Typography>
                </Grid>
            }
            
            {pageIndex === "main" && userRoles !== "" &&
                renderMainView
            }

            {pageIndex === "editDeck" && selectedDeck !== undefined  && userRoles !== "" &&
            <>
                <Grid item xs={12} style={{marginBottom:'300px'}}>
                    {renderEditView}
                </Grid>
                <Grid item xs={12} style={{width:'100%'}} >
                    <CardsInDeck 
                    deckId={selectedDeck.deckId} 
                    deckCardCounts={deckCardCounts} 
                    total={selectedDeck.numCards} 
                    capacity={selectedDeck.capacity}
                    removeCardCallback={deckWidgetRemoveCardCallback} 
                    serverRequestInProgress={deckRequestInProgress}
                    setSelectedCardCallback={setSelectedCardCallback}
                    />
                </Grid>
                {selectedCard && selectedCard!==undefined && selectedCard !== null &&
                 <Backdrop open={popoverEnabled} style={{backgroundColor:'rgba(0,0,0,.8)', zIndex:'700'}}>
                    <CardPopover
                    contents={<CardBlowUpWidget card={selectedCard}/>}
                    rarity = {selectedCard.rarityId}
                    popover = {popoverEnabled}
                    closePopover = {()=>setPopoverEnabled(false)}
                    dropShadow = {true}
                    hideBackground
                    />
                </Backdrop>
                }
            </>
            }
             <Backdrop open={createDeckPopoverEnabled} style={{backgroundColor:'rgba(0,0,0,.8)', zIndex:'700'}}>
            <Popover
            id="create-deck-popover"
            anchorReference="anchorPosition"
            open={createDeckPopoverEnabled}
            classes = {{paper: styles.popPaper }}
            anchorPosition={{top:window.innerHeight/2, left:window.innerWidth/2}}
            BackdropProps={{ invisible: true }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
                }}
            onClose={()=>handleCreateAndEditPopoverClose()}
            >
                <Grid container style={{height:'70vh', width:'90vw', maxHeight:'300px', maxWidth:'700px', border:'solid 1px white', justifyContent:'center',alignItems:'flex-start'}}>
                    <Grid item xs={12} style = {{height:'60px', display: "flex", justifyContent: "right", backgroundColor: 'rgba(0,0,0,.5)'}}>
                        <Fab 
                        size="small" 
                        aria-label="remove" 
                        color="secondary"
                        style={{ margin: 10, scale: "0.70" }}
                        onClick = {()=>handleCreateAndEditPopoverClose()}
                        >
                            <Close style = {{scale: "1.25"}} />
                        </Fab>
                    </Grid>
                    <Grid container style={{justifyContent:'center', alignItems:'center'}}>
                        {createMode? 
                            createDeckPopupContents:
                            editDeckNamePopoverContents
                        }
                    </Grid>
                </Grid>
            </Popover>
            </Backdrop>
        </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
    popPaper:{
        boxShadow: "none",
        backgroundColor:'rgba(0,0,0,0.85)',

    },
    addIcon: {
        height: 30,
        width: 30,
        borderRadius: 15,
        cursor: "pointer",
        backgroundColor: "white"
    },

    deckContainer:{
        width: 400,
        height: "auto",
        backgroundColor: "rgba(0,0,0,0.7)"
    },

    deckNameHeader:{
        fontWeight: "bold",
        textAlign: "center",
        textDecorationLine: "underline",
        color: "white"
    },

    textSelected: {
        color: `rgb(${rarityColors[8]})`
    },

    textWhite:{
        color: "white"
    },

    textCentered: {
        textAlign: "center"
    },

    textLeft: {
        textAlign: "left"
    },

    textFieldProp: {
        color: "white",
        background: "rgba(232, 241, 250, 0.05)",
        borderBottom: "1px solid #808080"
    },

    textFieldLabel:{
        color: "white",
        fontSize: 18,
        textEmphasis: "bold",
    },

    editNameField: {
        color: "white",
        borderBottom: "1px solid white",
        fontSize: 40,
        textEmphasis: "bold"
    },

    numFieldWidth: {
        width: "10ch"
    },

    cardImage: {
        width: 225, 
        height: "auto",
        marginLeft: 25,
        marginRight: 25,
        marginTop: 0,
        marginBottom: 50,
        padding: 0
    },
    filterFieldProp: {
        height: '42px',
        color: "white",
        background: "rgba(232, 241, 250, 0.05)",
        borderBottom: "1px solid #808080"
    },

    invalidContainer: {
        overflowX: "hidden",
        overflowY: "hidden",
        maxWidth: "50vw",
        width: 'auto',
        height: "auto",
        padding:'10px',
        margin:'0px',
        borderRadius: 10,
        backgroundColor: "rgba(0,0,0,0.7)"
    },
    fabGreenStyle:{
        background:'#008000',
        bgcolor:'#008000',
        '&:hover':{
            background:'#00d000',
            bgcolor:'#00d000',
        },
        '&:disabled':{
            background:'rgba(80,80,80,.6)',
            bgcolor:'rgba(80,80,80,.6)',
        }

    }

}))

export default DecksPage;
