import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";

import { Drawer, AppBar, Toolbar, List, IconButton, ListItem, ListItemIcon, ListItemText, Grid, Typography, Button } from '@material-ui/core';
import { makeStyles, useTheme, createStyles } from '@material-ui/core/styles';
import ArtLoader from "../assets/CardArtProvider"
import { Menu, ChevronLeft, ChevronRight, Book, LibraryAdd, Receipt, Collections, LibraryBooks, Store, Home, SportsEsports, AccountCircle, SupervisorAccount } from "@material-ui/icons";
import { useStore } from "../helpers/GlobalStore";
import { useContractAPI } from "../helpers/ContractAPI";
import { useBackendAPI } from "../helpers/BackendAPI";

import ConnectModal from "./connect-modal/ConnectModal";
import useWallet from "../helpers/useWallet";
import { useWeb3React } from "@web3-react/core";
import CONFIG from "../config/config";
import { getLibrary, getSupportedChainId , requestNetworkSwitch} from "../helpers/web3ReactUtil";
import useIsWindowFocused from '../helpers/tabActivityHook'
import detectEthereumProvider from '@metamask/detect-provider';
import { useWindowDimensions } from "../helpers/windowDimentionProvider";

const drawerWidth = 240;

const { getArt } = ArtLoader();


const NavBar = () => {
    
    const styles = useStyles();
    const history = useHistory();
    const { width, height } = useWindowDimensions();
    const { userRoles,
            childUpdateNeeded, setChildUpdateNeeded,
            connectionText, setConnectionText,
            connectionErrorMsg, setConnectionErrorMsg,
            userAddress, setUserAddress,
            currentChainId, setCurrentChainId,
            walletType,
            userAuthExists,
        } = useStore();
        
    const { truncateAddress } = useContractAPI();
        
    const { signIn, signOut, isAuthorized } = useBackendAPI();
    const windowIsActive = useIsWindowFocused();
	 	 
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [isSigningIn, setIsSigningIn] = useState(false)
    const [connectModalOpen, setConnectModalOpen] = useState(false)
    const { login, getCurrentChainId, logout, addDisconnectEventListener, enableWalletListeners, disableWalletListeners } = useWallet();
    const { account, chainId, active, error, connector, activate } = useWeb3React()
    const [localChainId, setLocalChainId] = useState(null);
    const [reloadPageRequested, setReloadPageRequested] = useState(false);
 

    const handleChainChange = async (chainId) => {
        setCurrentChainId(chainId)
        if(userAuthExists){
            await handleSignOut()
        }
    }

    const handleAccountChange = async (accounts) => {
        const localStorage = window.localStorage;
        const oldUserAddr = localStorage.getItem('currentUser')
        
        if(oldUserAddr && oldUserAddr !== accounts[0]){
            await handleSignOut()
        }
    }

    const handleUserWalletDisconnect = async () =>{
        await signOut();
        setConnectionText("Connect");
        setReloadPageRequested(true);
    }

    const handleSignOut = async () => {
        await logout();
        await signOut();
        setConnectionText("Connect");
        setReloadPageRequested(true);
    }

    const getChain = async () => {
        await getCurrentChainId()
    }
    
    const checkAuth = async () => {
        return await isAuthorized()
    }


    const displayConnectModal = () => {
        setConnectModalOpen(true);
    }

    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };

    useEffect(()=>{
        if(reloadPageRequested === true){
            setReloadPageRequested(false)
            
            window.location.reload() 
        }
    },[reloadPageRequested])


    useEffect(()=>{
        if(userAddress !== null && userAddress !== undefined){
            if(userAuthExists === false){
                handleSignIn()
            }
        }
    },[userAddress, userAuthExists])



    useEffect(()=>{
        if(userAddress === null || userAddress === undefined){
            Promise.resolve(checkAuth())
            addDisconnectEventListener(()=>handleUserWalletDisconnect())
        }   
    },[])

    useEffect(()=>{
        if(account !== null && account !== undefined){
                setUserAddress(account)
        }
    },[account])



    
    useEffect(()=>{
        var localWalletType = walletType;
        var localStorage = window.localStorage
        const provider = window.ethereum
        if(provider){
            if(localWalletType === undefined || localWalletType === null){
                localWalletType = localStorage.getItem('userWalletType')
            }
            if(localWalletType && parseInt(localWalletType) === 0){
                if(windowIsActive){
                    disableWalletListeners()
                    enableWalletListeners(provider, handleChainChange, handleAccountChange.bind(userAddress))
                }
                else{
                    disableWalletListeners()
                }
            }
        }
    },[windowIsActive])

    


    const handleSignIn = async (selectedWalletType) => {
        setIsSigningIn(true)

        if(userAddress !== null && userAddress !== undefined){

            const localStorage = window.localStorage
            const localWalletType = localStorage.getItem("userWalletType")

            var w3Signer = undefined;

            if(parseInt(localWalletType) === 1){
                w3Signer = null;
                
            }
            else{
                const provider = window.ethereum
                const lib = await getLibrary(provider)
                w3Signer = await lib.getSigner(userAddress)
            }

            try{
                await signIn(userAddress, w3Signer)
            }
            catch(err){
                setConnectionText("Connect")
               // setConnectionErrorMsg("Our services are down.  Please let us know if this error persists.")
                setIsSigningIn(false)
            }
        }

        setIsSigningIn(false)

    };


    const handleNavClick = (page) => {

        setOpen(false)

        if(page === "home")
            history.push("/")

        else if(page === "inventory")
            history.push("/inventory")

        else if(page === "deck")
            history.push("/decks")

        else if(page === "store")
            history.push("/store")

        else if(page === "game")
            history.push("/game")

        else if(page === "admin")
            history.push("/admin")

        else if(page === "profile")
            history.push("/profile")

        else if(page === "lore")
            history.push("/lore")
        else if(page === "guide")
            history.push("/guide")
        else if(page === "credits")
            history.push("/credits")
        else history.push("/")
    }

    return (
        <AppBar
        position = "fixed"
        className={styles.appBar}
        elevation={0}
        id="Main Appbar"
        >
            <ConnectModal onLoginCallback={login} onDisconnectCallback={()=>handleUserWalletDisconnect()} onDismissCallback={()=>{setConnectModalOpen(false)}} isPopoverDisplayed={connectModalOpen}/>
            <Toolbar id="Toolbar">
                <Grid container alignItems = "center" className = {styles.controlsContainer} id="Toolbar Container">
                    <Grid item xs = {1}>

                        <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={`${styles.menuButton} ${open && styles.hide}`}
                        >
                            <Menu />
                        </IconButton>
                    </Grid>

                    <Grid container xs={9} style={{justifyContent:'center', alignItems:'center'}}>
                        <img alt='Arena of Exiles Menu Logo' src={width>1200?getArt("AppbarLogoFull"):width<600?getArt("AppbarLogoSmall"):getArt("AppbarLogoMedium")}/>            
                    </Grid>


                    <Grid item xs = {2}>
                        {userRoles && userRoles !== undefined && userRoles !== null && userRoles !== ""?
                            
                            <Button 
                            className = "buttonRibbon"
                            variant="contained" 
                            onClick={()=>handleSignOut()}
                            style={{float:'right', width:'150px', marginRight:'5%'}}
                            onMouseOver = {() => {
                                if(!isSigningIn && connectionErrorMsg === undefined)
                                    setConnectionText("Sign Out?") 
                            }}
                            onMouseLeave = {() => {
                                if(!isSigningIn && connectionErrorMsg === undefined)
                                    setConnectionText(truncateAddress())
                            }}
                            >
                                <Typography variant = "body2">{connectionText}</Typography>
                            </Button>
                      
                            :

                            <Button 
                            className = "buttonRibbon"
                            variant="contained" 
                            onClick={()=>displayConnectModal()}
                            style={{float:'right', width:width<600?'100px':'150px', marginRight:'5%'}}
                            >
                                <Typography variant = "body2">Connect</Typography>
                            </Button>
                        }
                        
                    </Grid>

                </Grid>

            </Toolbar>
            
            <Drawer
            className={styles.drawer}
            variant="temporary"
            anchor="left"
            open={open}
            onClose={() => setOpen(false)}
            classes={{
            paper: styles.drawerPaper,
            }}
            >
                <div className={styles.drawerHeader}>
                    <IconButton 
                    onClick={handleDrawerClose} 
                    className = {styles.colorWhite}
                    >
                        {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                    </IconButton>
                </div>

                <List>
                    <ListItem
                    onClick = {() => handleNavClick("home")}
                    className = {styles.navLink}
                    >

                        <ListItemIcon >
                            <Home style = {{fill: "white"}}/>
                        </ListItemIcon>
                        
                        <ListItemText primary = "Home"/>

                    </ListItem>

                    <ListItem
                    onClick = {() => handleNavClick("store")}
                    className = {styles.navLink}
                    >
                        <ListItemIcon>
                            <Store style = {{fill: "white"}}/>
                        </ListItemIcon>

                        <ListItemText primary = "Store"/>
                        
                    </ListItem>

                    <ListItem
                    onClick = {() => handleNavClick("inventory")}
                    className = {styles.navLink}
                    >
                        <ListItemIcon>
                            <Collections style = {{fill: "white"}}/>
                        </ListItemIcon>
                        
                        <ListItemText primary = "My Inventory"/>
                        
                    </ListItem>

                    {(userRoles !== undefined && userRoles !== null && userRoles !== "") &&
                    <ListItem
                    onClick = {() => handleNavClick("deck")}
                    className = {styles.navLink}
                    >
                        <ListItemIcon>
                            <LibraryAdd style = {{fill: "white"}}/>
                        </ListItemIcon>
                        
                        <ListItemText primary = "My Decks"/>
                        
                    </ListItem>
                    }

                    <ListItem
                    onClick = {() => handleNavClick("game")}
                    className = {styles.navLink}
                    >
                        <ListItemIcon>
                            <SportsEsports style = {{fill: "white"}}/>
                        </ListItemIcon>
                        
                        <ListItemText primary = "Game"/>
                        
                    </ListItem>
                    <ListItem
                    onClick = {() => handleNavClick("guide")}
                    className = {styles.navLink}
                    >
                        <ListItemIcon>
                            <LibraryBooks style = {{fill: "white"}}/>
                        </ListItemIcon>
                        
                        <ListItemText primary = "Guide"/>
                        
                    </ListItem>
                    <ListItem
                    onClick = {() => handleNavClick("lore")}
                    className = {styles.navLink}
                    >
                        <ListItemIcon>
                            <Book style = {{fill: "white"}}/>
                        </ListItemIcon>
                        
                        <ListItemText primary = "Lore"/>
                        
                    </ListItem>
                    <ListItem
                    onClick = {() => handleNavClick("credits")}
                    className = {styles.navLink}
                    >
                        <ListItemIcon>
                            <Receipt style = {{fill: "white"}}/>
                        </ListItemIcon>
                        
                        <ListItemText primary = "Credits"/>
                        
                    </ListItem>

                    {userRoles !== undefined && userRoles !== '' &&
                    <ListItem
                    onClick = {() => handleNavClick("profile")}
                    className = {styles.navLink}
                    >
                        <ListItemIcon>
                            <AccountCircle style = {{fill: "white"}}/>
                        </ListItemIcon>
                        
                        <ListItemText primary = "Profile"/>
                        
                    </ListItem>
                    }

                    {(userAddress !== undefined && userRoles === "admin" )&&
                    <ListItem
                    onClick = {() => handleNavClick("admin")}
                    className = {styles.navLink}
                    >
                        <ListItemIcon>
                            <SupervisorAccount style = {{fill: "white"}}/>
                        </ListItemIcon>
                        
                        <ListItemText primary = "Admin"/>
                        
                    </ListItem>
                    }

                </List>

            </Drawer>
        </AppBar>
    )
}

const useStyles = makeStyles((theme) =>
    createStyles({
        appBar: {
            display: "flex",
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            backgroundColor: "#1f020b",
            height: 65,
            filter: 'drop-shadow(0 .4rem .5rem rgba(125, 0, 0, .5) )'
        },
        
        menuButton: {
            marginRight: theme.spacing(2)
        },

        hide: {
            display: 'none',
        },

        drawer: {
            width: drawerWidth,
            flexShrink: 0
        },

        drawerPaper: {
            width: drawerWidth,
            backgroundColor: "#1f020b",
            color: "white"
        },

        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },

        navLink: {
            textDecoration: "none",
            cursor: "pointer",
            borderBottom: "1px solid #1f020b",
            '&:hover': {
                backgroundColor: "#360313",
                borderBottom: "1px solid #6b0022",
                transition: "0.5s"
              }
        },

        controlsContainer: {
            maxWidth: "100vw"
        },

        creditsIcon: {
            height: 40,
            width: 40,
            borderRadius: 50
        },

        fillWhite: {
            fill: "white"
        },

        colorWhite: {
            color: "white"
        }
    }),
);


export default NavBar;
