import { useCallback, useState } from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { InjectedConnector } from '@web3-react/injected-connector'
import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
// import {
//     UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
//     WalletConnectConnector,
// } from '@web3-react/walletconnect-connector'
import { simpleRpcProvider, getLibrary, requestNetworkSwitch, getSupportedChainId, supportedChainId } from './web3ReactUtil'
import { ethers } from 'ethers'
import CONFIG from '../config/config'
import { useStore } from './GlobalStore'
import Web3 from "web3";
import detectEthereumProvider from '@metamask/detect-provider';

const injectedConnector = new InjectedConnector({ supportedChainIds: [parseInt(CONFIG.CHAIN_ID)] })

// const walletconnect = new WalletConnectProvider({
//     rpc: {
//         [parseInt(CONFIG.CHAIN_ID)]: CONFIG.ETH_NODE },
//         pollingInterval: 12000,
//         qrcode: true,
// })

export const connectorObjectsByName = {
    'injected': injectedConnector,
}

export var connnectorObjectArray = [injectedConnector]


// const WALLET_CONNECT_CONNECTOR_ID = 1;
const INJECTED_CONNECTOR_ID = 0;

const useWallet = () => {
    const { chainId, activate, deactivate, account, library } = useWeb3React()
    const { setCurrentChainId, currentChainId, walletType, setWalletType, setUserAddress, userAddress, setUserAccountChanged} = useStore()


    const setupNetwork = async () => {

        const provider = await detectEthereumProvider({
            mustBeMetaMask: false
          })
        if (provider) {
            const supportedChainID = getSupportedChainId();

            const localChainId = await provider.request({ method: 'eth_chainId' }).then(async  (res) => {
                setCurrentChainId(res)
                return(res)
            })

            if(localChainId === `0x${supportedChainID}`){
                return true;
            }

            else{
                try {
                    await provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [{
                            chainId: `0x${CONFIG.CHAIN_ID}`,
                            chainName: `${CONFIG.CHAIN_NAME}`,
                            nativeCurrency: {
                                name: 'Ethereum',
                                symbol: 'ETH',
                                decimals: 18,
                            },
                            rpcUrls: simpleRpcProvider,
                            blockExplorerUrls: [`https://etherscan.io/`],
                        }, ],
                    })
                    return true
                } catch (error) {
                    if(error.name === 'DataCloneError'){
                        await provider.request({ method: 'wallet_switchEthereumChain', params:[{chainId: `0x${supportedChainId}`}]}).then(res => {
                            return res;
                        })
                        .catch(error => console.log(error.message))
                    }
                    console.error('Failed to Add the network in Metamask:', error)
                    return false
                }
            }
        } else {
            console.error("Can't setup the ETH network on metamask because window.ethereum is undefined")
            return false
        }
    }


    const login = useCallback( async (connectorID, onDisconnectCallback) => {
       
        const supportedChainID = getSupportedChainId();
        const localStorage = window.localStorage
        var localConnectorId = connectorID;
        
        if(localConnectorId === undefined || localConnectorId === null){
            localConnectorId = parseInt(localStorage.getItem('userWalletType'))
        }
        else{
            localStorage.setItem('userWalletType', localConnectorId.toString())
        }
        

        const connector = connnectorObjectArray[parseInt(localConnectorId)]
       
        if (connector) {
            setWalletType(parseInt(localConnectorId))
            
            if(localConnectorId === INJECTED_CONNECTOR_ID){
                
                const provider = await detectEthereumProvider({
                    mustBeMetaMask: false
                })
                if(!provider){
                    console.warn('No metamask found')
                    return;
                }
                
                const supportedChainID = getSupportedChainId();

                const localChainId = await provider.request({ method: 'eth_chainId' }).then(async  (res) => {
                    setCurrentChainId(res)
                    return(res)
                })
                if(localChainId &&  localChainId !== `0x${supportedChainID}`){
                    Promise.resolve( requestNetworkSwitch())
                }

                activate(connector, async (error) => {
                    if (error instanceof UnsupportedChainIdError) {

                        const hasSetup = await setupNetwork()
                        if (hasSetup) {
                            activate(connector)
                        }
                        else{
                            if(error.name === 'DataCloneError'){
    
                                Promise.resolve (requestNetworkSwitch())
                                return true;
                            }
                        }
                    } else {

                        if (error instanceof NoEthereumProviderError) {
                            console.log('Provider Error: No provider was found')
                        } else if (
                            error instanceof UserRejectedRequestErrorInjected ||
                            error instanceof UserRejectedRequestErrorWalletConnect
                        ) {
                            if (connector instanceof WalletConnectConnector) {
                                const walletConnector = connector
                                walletConnector.walletConnectProvider = null
                            }
                            console.log('Authorization Error: Please authorize to access your account')
                        } else {
                            console.log(error.name, error.message)
                        }
                    }
                })
            }
        }
        //     else if( localConnectorId === WALLET_CONNECT_CONNECTOR_ID){
        //         const newWalletConnectConnector = new WalletConnectProvider({
        //             rpc: {
        //                 [parseInt(CONFIG.CHAIN_ID)]: CONFIG.ETH_NODE },
        //                 pollingInterval: 12000,
        //                 qrcode: true,
        //         })
                
        //         try{

        //             await newWalletConnectConnector.enable().then((userAddr)=>{
        //                 setUserAddress(userAddr[0]);
        //                 newWalletConnectConnector.wc.on(
        //                     'disconnect',  
        //                     onDisconnectCallback.bind(this)
        //                 );
        //                 connnectorObjectArray[WALLET_CONNECT_CONNECTOR_ID] = newWalletConnectConnector;
        //                 return userAddr[0];
        //             });
                    
        //         }
        //         catch(err){
        //             console.log(err.message)
        //             if(err.message === 'User closed modal'){
        //                 await newWalletConnectConnector.qrcodeModal.close();
        //                 await newWalletConnectConnector.close();
        //                 newWalletConnectConnector = null;
        //             }
        //         }
        //     }
        // } 
        else {
            console.log('Unable to find connector: The connector config is wrong')
        }
    }, [activate, setupNetwork])


    const logout = useCallback( async () => {
        var localWalletType = walletType;
        const localStorage = window.localStorage
        if(localWalletType === null || localWalletType === undefined){
            localWalletType = localStorage.getItem('userWalletType')
        }

        if(parseInt(localWalletType) === INJECTED_CONNECTOR_ID){
            await deactivate()
        }
        else if(parseInt(localWalletType) === WALLET_CONNECT_CONNECTOR_ID){
        // This localStorage key is set by @web3-react/walletconnect-connector
            try{
                await connnectorObjectArray[WALLET_CONNECT_CONNECTOR_ID].disconnect()

            }
            catch(error){
                console.log(error.message)
            }
            try{
               await connnectorObjectArray[WALLET_CONNECT_CONNECTOR_ID].close()

            }
            catch(error){
                console.log(error.message)
            }

           
            connnectorObjectArray[WALLET_CONNECT_CONNECTOR_ID].walletConnectProvider = null
        }
    }, [deactivate])

    const signAuthenticationMessage = (publicAddress, nonce, signer) => {

        if (publicAddress !== userAddress) {
            return null
        }

        let message =
            "Hello! Welcome to the Arena, Exile! Sign this message to prove you have access to this wallet and we’ll log you in." +
            " This process is crucial to stopping hackers from using your wallet, and it's free." +
            " Here’s a unique message ID they can’t guess: " +
            nonce;

        const localStorage = window.localStorage;
        var localWalletType = walletType;

        if(localWalletType === undefined || localWalletType === null){
            localWalletType = parseInt(localStorage.getItem('userWalletType'))
        }
        
        if (localWalletType === INJECTED_CONNECTOR_ID) {
            //injected wallets like metamask
            return new Promise(async (resolve, reject) => {
                const signature = await signer.signMessage(message).then(res => {
                        return res;
                    })
                    .catch(error => console.log(error.message))

                resolve(signature);
            })

        } else if (localWalletType === WALLET_CONNECT_CONNECTOR_ID) {
            //WalletConnect wallets
            const wcConnector = connnectorObjectArray[localWalletType];
       
            //WalletConnect wallets
            if (wcConnector) {
                return new Promise(async (resolve, reject) => {

                    const rpcSigRequest = {
                        method:"personal_sign",
                        params:[
                            message, 
                            userAddress
                        ],
                    }

                    const signature = await wcConnector.request(rpcSigRequest).then(res => {
                        const verified = ethers.utils.verifyMessage(message, res);
                        return res;
                    }).catch(error => console.log(error.message))

                    resolve(signature)
                })
            }
        } else {
            console.log(`Wallet type invalid: ${walletType}`)
            return null;
        }
    }

    const getCurrentChainId = async () => {
      
        if(!window.ethereum){
            return
        }
        const provider = window.ethereum
        try{
          await provider.request({ method: 'eth_chainId' }).then(async  (res) => {
              setCurrentChainId(res)
              return(res)
          })
        }
        catch(err){
          console.log(err.message)
        }
        return(null)
    }


    const hasUserAccountChanged = async (userAddress) => {
        const provider = await detectEthereumProvider({
            mustBeMetaMask: false
        })
        const supportedChainID = getSupportedChainId();

        const ethAccounts = await provider.request({ method: 'eth_accounts' }).then(async  (res) => {
            return(res)
        }).catch(error=>{
            console.log(error.message)
        })
        if(userAddress && userAddress !== ethAccounts[0]){
            return true;
        }
        else{
            return(false)
        }
    }

    const addDisconnectEventListener = async (handleUserWalletDisconnect) => {
        const localStorage = window.localStorage;
        var localWalletType = walletType;

        if(localWalletType === undefined || localWalletType === null){
            localWalletType = parseInt(localStorage.getItem('userWalletType'))
        }
        
        // if(localWalletType === WALLET_CONNECT_CONNECTOR_ID){
        //     connnectorObjectArray[localWalletType].wc.on(
        //         'disconnect',  
        //         handleUserWalletDisconnect.bind(this)
        //     );
        // }


    }

    const disableWalletListeners = (provider) => {
        provider?.removeAllListeners(["networkChanged"])
        provider?.removeAllListeners(["chainChanged"])
        provider?.removeAllListeners(["accountsChanged"])
    }
    
    const enableWalletListeners = (provider, handleChainChange, handleAccountChange) => {
        provider?.on('chainChanged',  (chainId) => {
            handleChainChange(chainId)
        });
        provider?.on('accountsChanged',  (newacct) => {
          handleAccountChange(newacct)
        });
      
    }

    return { 
        login, 
        logout, 
        signAuthenticationMessage, 
        getCurrentChainId, 
        hasUserAccountChanged, 
        addDisconnectEventListener, 
        disableWalletListeners, 
        enableWalletListeners
    }
}

export default useWallet