import Slot from './Slot'

/*
    position: {
        x: x_value, 
        y: y_value, 
        z: depth
    }
    //{x: slot.x, y: slot.y, z: 10}

    slotOwner: 'PLAYER' | 'ENEMY'
*/
const PlayField = (scene, bannerArt, scale, position, cardScale) => {

    const CARD_ART_SCALE = cardScale;

    const fieldId = "play_field";

    var playfieldArtImage = scene.add.image(position.x, position.y, bannerArt)
        .setDepth(position.z)
        .setOrigin(0.5)
        .setScale(scale);

    var fieldWidth = playfieldArtImage.width * scale;
    var fieldHeight = playfieldArtImage.height * scale;

    var leftEdgeOfField = position.x - fieldWidth/2;
    var topEdgeOfField = position.y - fieldHeight/2;


    //create and display field  lanes on screen
    var playerLane1 = Slot('player_lane_1', scene, 'card-detail-view-slot', 0.65 * scale, {x: leftEdgeOfField + fieldWidth*0.16, y: topEdgeOfField + fieldHeight*0.74, z: 4});
    var playerLane2 = Slot('player_lane_2', scene, 'card-detail-view-slot', 0.65 * scale, {x: leftEdgeOfField + fieldWidth*0.50, y: topEdgeOfField + fieldHeight*0.74, z: 4});
    var playerLane3 = Slot('player_lane_3', scene, 'card-detail-view-slot', 0.65 * scale, {x: leftEdgeOfField + fieldWidth*0.84, y: topEdgeOfField + fieldHeight*0.74, z: 4});

    var enemyLane1 = Slot('enemy_lane_2', scene, 'card-detail-view-slot', 0.65 * scale, {x: leftEdgeOfField + fieldWidth*0.16, y: topEdgeOfField + fieldHeight*0.26, z: 4});
    var enemyLane2 = Slot('enemy_lane_1', scene, 'card-detail-view-slot', 0.65 * scale, {x: leftEdgeOfField + fieldWidth*0.50, y: topEdgeOfField + fieldHeight*0.26, z: 4});
    var enemyLane3 = Slot('enemy_lane_3', scene, 'card-detail-view-slot', 0.65 * scale, {x: leftEdgeOfField + fieldWidth*0.84, y: topEdgeOfField + fieldHeight*0.26, z: 4});

    function hasEmptySlot(){
        return playerLane1.isEmpty() || playerLane2.isEmpty() || playerLane3.isEmpty();
    }

    function isEmpty(){
        return playerLane1.isEmpty() && playerLane2.isEmpty() && playerLane3.isEmpty();
    }


    //returns an array containing all empty lanes to the caller
    function getEmptyLanes(){
        var emptyLanes = [];

        if(playerLane1.isEmpty()){
            emptyLanes.push({
                lane:1,
                position:{
                    x: playerLane1.position.x,
                    y: playerLane1.position.y,
                    z: playerLane1.position.z
                }
            })
        }
        if(playerLane2.isEmpty()){
            emptyLanes.push({
                lane:2,
                position:{
                    x: playerLane2.position.x,
                    y: playerLane2.position.y,
                    z: playerLane2.position.z
                }
            })
        }
        if(playerLane3.isEmpty()){
            emptyLanes.push({
                lane:3,
                position:{
                    x: playerLane3.position.x,
                    y: playerLane3.position.y,
                    z: playerLane3.position.z
                }
            })
        }

        return emptyLanes;
    }

    //returns an array containing all occupied lanes to the caller
    function getOccupiedLanes(){
        var occupiedLanes = [];

        if(!playerLane1.isEmpty()){
            occupiedLanes.push({
                lane:1,
                position:{
                    x: playerLane1.position.x,
                    y: playerLane1.position.y,
                    z: playerLane1.position.z
                }
            })
        }
        if(!playerLane2.isEmpty()){
            occupiedLanes.push({
                lane:2,
                position:{
                    x: playerLane2.position.x,
                    y: playerLane2.position.y,
                    z: playerLane2.position.z
                }
            })
        }
        if(!playerLane3.isEmpty()){
            occupiedLanes.push({
                lane:3,
                position:{
                    x: playerLane3.position.x,
                    y: playerLane3.position.y,
                    z: playerLane3.position.z
                }
            })
        }

        return occupiedLanes;
    }

    //returns the Slot() lane object belonging to the players lanes with the given laneNumber
    function getPlayerLaneByNumber(laneNumber){
        switch (laneNumber) {
            case 1:
                return playerLane1;
            case 2:
                return playerLane2;
            case 3:
                return playerLane3;
            default:
                console.error('INVALID LANE REQUESTED')
                break;
        }
    }

    //returns the Slot() lane object belonging to the enemy's lanes with the given laneNumber
    function getEnemyLaneByNumber(laneNumber){
        switch (laneNumber) {
            case 1:
                return enemyLane1;
            case 2:
                return enemyLane2;
            case 3:
                return enemyLane3;
            default:
                console.error('INVALID LANE REQUESTED')
                break;
        }
    }

    //demolishes the card within the cardSlot
    function destroyDeadCard(cardSlot){
        if (cardSlot){
            var card = cardSlot.getObject();
            cardSlot.destroyObject()
            card && card.destroy();
        }
    }

    function decrementRemainingAttacksOnCard(laneNumber, playerCardUniqueId){
        var slot = getPlayerLaneByNumber(laneNumber);
        var card = slot.getObject();

        if (card.cardMetaData.uniqueId !== playerCardUniqueId){
            console.error("Your monster that you tried to damage is not in the lane you thought it was in.");
            return;
        }
        card.decrementRemainingAttacks();
    }

    function resetAllRemainingAttacks(){
        var playerCardInLane1 = playerLane1.getObject();
        var playerCardInLane2 = playerLane2.getObject();
        var playerCardInLane3 = playerLane3.getObject();

        if (playerCardInLane1)
            playerCardInLane1.resetRemainingAttacks();
        if (playerCardInLane2)
            playerCardInLane2.resetRemainingAttacks();
        if (playerCardInLane3)
            playerCardInLane3.resetRemainingAttacks();
    }

    //places a card into the specicied player lane
    function placePlayerCard(card, lane){

        var emptyLane;

        switch (lane) {
            case 1:
                if (!playerLane1.isEmpty())
                    playerLane1.destroyObject();
                emptyLane = playerLane1;
                break;
            case 2:
                if (!playerLane2.isEmpty())
                    playerLane2.destroyObject();
                emptyLane = playerLane2;
                break;
            case 3:
                if (!playerLane3.isEmpty())
                    playerLane3.destroyObject();
                emptyLane = playerLane3;
                break;
            default:
                console.error("Attempted to place card into play field but it failed.");
                return;
        }

        //Draw the card and then place it in the selected empty slot
        card.draw(CARD_ART_SCALE, {x: emptyLane.position.x, y: emptyLane.position.y, z: 10}, true, true);
        emptyLane.placeObject(card);
    }

    //places a card into the specicied enemy lane 
    function placeEnemyCard(card, lane){

        var emptyLane;

        switch (lane) {
            case 1:
                if (!enemyLane1.isEmpty())
                    enemyLane1.destroyObject();
                emptyLane = enemyLane1;
                break;
            case 2:
                if (!enemyLane2.isEmpty())
                    enemyLane2.destroyObject();
                emptyLane = enemyLane2;
                break;
            case 3:
                if (!enemyLane3.isEmpty())
                    enemyLane3.destroyObject();
                emptyLane = enemyLane3;
                break;
            default:
                console.error("Attempted to place card into play field but it failed.");
                return;
        }

        //Draw the card and then place it in the selected empty slot
        card.draw(CARD_ART_SCALE, {x: emptyLane.position.x, y: emptyLane.position.y, z: 10}, true, false);
        emptyLane.placeObject(card);
    }

    function removeCardWithId(cardIdToRemove){
        var enemyCardInLane1 = enemyLane1.getObject();
        var enemyCardInLane2 = enemyLane2.getObject();
        var enemyCardInLane3 = enemyLane3.getObject();

        var playerCardInLane1 = playerLane1.getObject();
        var playerCardInLane2 = playerLane2.getObject();
        var playerCardInLane3 = playerLane3.getObject();

        if (enemyCardInLane1 && (enemyCardInLane1.cardMetaData.uniqueId === cardIdToRemove)){
            enemyLane1.destroyObject();
        } else if (enemyCardInLane2 && (enemyCardInLane2.cardMetaData.uniqueId === cardIdToRemove)){
            enemyLane2.destroyObject();
        } else if (enemyCardInLane3 && (enemyCardInLane3.cardMetaData.uniqueId === cardIdToRemove)){
            enemyLane3.destroyObject();
        } else if (playerCardInLane1 && (playerCardInLane1.cardMetaData.uniqueId === cardIdToRemove)){
            playerLane1.destroyObject();
        } else if (playerCardInLane2 && (playerCardInLane2.cardMetaData.uniqueId === cardIdToRemove)){
            playerLane2.destroyObject();
        } else if (playerCardInLane3 && (playerCardInLane3.cardMetaData.uniqueId === cardIdToRemove)){
            playerLane3.destroyObject();
        } else {
            console.error("Couldn't find card to remove from field with uniqueId: " + cardIdToRemove);
        }
    }

    function damageEnemyCard(laneNumber, enemyCardUniqueId, damageAmount){
        var slot = getEnemyLaneByNumber(laneNumber);
        var card = slot.getObject();

        if (card.cardMetaData.uniqueId !== enemyCardUniqueId){
            console.error("The enemy you tried to damage is not in the lane you thought it was in.");
            return;
        }
        card.damageCard(damageAmount);
    }

    function damagePlayerCard(laneNumber, playerCardUniqueId, damageAmount){
        var slot = getPlayerLaneByNumber(laneNumber);
        var card = slot.getObject();

        if (card.cardMetaData.uniqueId !== playerCardUniqueId){
            console.error("Your monster that you tried to damage is not in the lane you thought it was in.");
            return;
        }
        card.damageCard(damageAmount);
    }

    // Returns the player lane in which a card with the specified uniqueId exists, -1 if it doesn't.
    function getPlayerLaneNumberOfUniqueId(uniqueId){

        var playerCardInLane1 = playerLane1.getObject();
        var playerCardInLane2 = playerLane2.getObject();
        var playerCardInLane3 = playerLane3.getObject();


        if (playerCardInLane1 && (playerCardInLane1.cardMetaData.uniqueId === uniqueId)){
            return 1;
        } else if (playerCardInLane2 && (playerCardInLane2.cardMetaData.uniqueId === uniqueId)){
            return 2;
        } else if (playerCardInLane3 && (playerCardInLane3.cardMetaData.uniqueId === uniqueId)){
            return 3;
        } else {
            return -1;
        }
    }

    //returns card object  within specified lane.
    function getCardInLane(lane){
        if(lane > 0 && lane <= 3){
            if (lane === 1 && !playerLane1.isEmpty()){
                return playerLane1.getObject();
            } else if (lane === 2  && !playerLane2.isEmpty()){
                return playerLane2.getObject();
            } else if (lane === 3  && !playerLane3.isEmpty()){
                return playerLane3.getObject();
            } else {
                return null;
            }

        }
        else{
            console.error('Invalid lane requested');
        }
    }

     //returns card object  within specified lane.
     function getCardInEnemyLane(lane){
        if(lane > 0 && lane <= 3){
            if (lane === 1 && !enemyLane1.isEmpty()){
                return enemyLane1.getObject();
            } else if (lane === 2  && !enemyLane2.isEmpty()){
                return enemyLane2.getObject();
            } else if (lane === 3  && !enemyLane3.isEmpty()){
                return enemyLane3.getObject();
            } else {
                return null;
            }

        }
        else{
            console.error('Invalid lane requested');
        }
    }

    function getTopEdgeOfField(){
        return topEdgeOfField;
    }

    function getLeftEdgeOfField(){
        return leftEdgeOfField;
    }

    function getCenterOfField(){
        return leftEdgeOfField + fieldWidth/2;
    }

    return {
        fieldId: fieldId,
        cardScale: CARD_ART_SCALE,
        hasEmptySlot: hasEmptySlot,
        isEmpty: isEmpty,
        placePlayerCard: placePlayerCard,
        placeEnemyCard: placeEnemyCard,
        removeCardWithId: removeCardWithId,
        damageEnemyCard: damageEnemyCard,
        damagePlayerCard: damagePlayerCard,
        destroyDeadCard: destroyDeadCard,
        decrementRemainingAttacksOnCard: decrementRemainingAttacksOnCard,
        resetAllRemainingAttacks: resetAllRemainingAttacks,
        getEmptyLanes: getEmptyLanes,
        getOccupiedLanes: getOccupiedLanes,
        getPlayerLaneByNumber: getPlayerLaneByNumber,
        getEnemyLaneByNumber: getEnemyLaneByNumber,
        getCardInLane: getCardInLane,
        getCardInEnemyLane: getCardInEnemyLane,
        getPlayerLaneNumberOfUniqueId: getPlayerLaneNumberOfUniqueId,
        getTopEdgeOfField: getTopEdgeOfField,
        getLeftEdgeOfField: getLeftEdgeOfField,
        getCenterOfField: getCenterOfField
    };

}

export default PlayField;
