import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { ethers } from 'ethers'
import CONFIG from '../config/config'
import detectEthereumProvider from '@metamask/detect-provider';

const POLLING_INTERVAL = 12000
const rpcUrl = CONFIG.ETH_NODE
export const supportedChainId = CONFIG.CHAIN_ID

export const getSupportedChainId = () => {
  return supportedChainId
}



export const simpleRpcProvider = new ethers.providers.StaticJsonRpcProvider(rpcUrl)


const injectedConnector = new InjectedConnector({ supportedChainIds: [supportedChainId] })

const walletconnect = new WalletConnectConnector({
  rpc: { [supportedChainId]: simpleRpcProvider },
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
})

export const requestNetworkSwitch = async (logoutCallback) => {

    const provider = await detectEthereumProvider({
      mustBeMetaMask: false
    })
    const lib = await getLibrary(provider)
    if(provider)
      return new Promise(async (resolve, reject) => {
        const hasNetworkSwapped = await provider.request({ method: 'wallet_switchEthereumChain', params:[{chainId: `0x${supportedChainId}`}]}).then(res => {
                return res;
            })
            .catch(error => console.log(error))
        resolve(hasNetworkSwapped);
        //provider.on('chainChanged', (_chainId) => window.location.reload());
      })

}

export const connectorObjectsByName = {
  'injected': injectedConnector,
  'walletconnect': walletconnect,
}

export const connnectorObjectArray = [injectedConnector, walletconnect]

export const getLibrary = async ()=> {
  
  const provider = await detectEthereumProvider();
  const library = new ethers.providers.Web3Provider(provider)
  library.pollingInterval = POLLING_INTERVAL
  return library
}


export const signMessage = async (provider, account, message) => {


  if (provider.provider?.wc) {
    const wcMessage = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message))
    const signature = await provider.provider?.wc.signPersonalMessage([wcMessage, account])
    return signature
  }

  return provider.getSigner(account).signMessage(message)
}

export const disableWalletListeners = (provider) => {
  provider?.removeAllListeners(["networkChanged"])
  provider?.removeAllListeners(["chainChanged"])
  provider?.removeAllListeners(["accountsChanged"])
}

export const enableWalletListeners = (provider, handleChainChange, handleAccountChange) => {
  provider?.on('chainChanged',  (chainId) => {
      handleChainChange(chainId)
  });
  provider?.on('accountsChanged',  (newacct) => {
    handleAccountChange(newacct)
  });
}