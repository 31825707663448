import React, { useEffect, useState } from 'react'

import { useWindowDimensions } from '../helpers/windowDimentionProvider'

const BackgroundManager = (p) => {

    const { width } = useWindowDimensions()

    return(
        
      <div style={{width:'100%'}}>
       {width<=1080 ? (
      <div 
      alt="Background"
      
      style={{
          backgroundImage:`url(${p.sourceImage})`,  
          position: 'fixed',
          top: '50%',
          left: '50%',
          minWidth: '100%',
          minHeight: '100%',
          width: 'auto',
          height: 'auto',
          zIndex: '-1',
          transform: 'translate(-50%, -50%)',
          backgroundSize: 'cover',
          filter: p.blur?`blur(${p.blur})`:'none',
           WebkitFilter: p.blur?`blur(${p.blur})`:'none',

        }}
      />
       ):(
      <div 
      alt="Background"
      
      style={{
          backgroundImage:`url(${p.sourceImage})`,  
          position: 'fixed',
          top: '50%',
          left: '50%',
          minWidth: '100%',
          minHeight: '100%',
          width: 'auto',
          height: 'auto',
          zIndex: '-1',
          transform: 'translate(-50%, -50%)',
          backgroundSize: 'cover',
          filter: p.blur?`blur(${p.blur})`:'none',
           WebkitFilter: p.blur?`blur(${p.blur})`:'none',

        }}
      />)
      }
      </div>
    )

      
  
}
export default BackgroundManager