import React, { createContext, useContext, useState } from "react";
var HashMap = require('hashmap');

export const StoreContext = createContext();

export const rarityColors = {
  64: '0,255,255',
  32: '255,0,0',
  16:'130,130,130',
  8:'0,255,42',
  4:'0,119,255',
  2:'132,0,255',
  1:'255,196,0',
  0: '251,72,196'
}

export const StoreProvider = ({children}) => {


  //Represents an invalid address from an error in solidity.
  const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

  const [webToken, setWebToken] = useState();

  //Contains the wallet address of the connected users metamask userAddress.
  const [userAddress, setUserAddress] = useState();
  const [userName, setUserName] = useState("")
  const [userRoles, setUserRoles] = useState('');
  const [connectionText, setConnectionText] = useState("Connect")
  const [connectionErrorMsg, setConnectionErrorMsg] = useState(undefined)
  const [userRank, setUserRank] = useState(0)

  //MediaToken will be the JSON object returned from the blockchain contract.
  //Contains all functions and members that belong to the MediaToken contract.
  const [mediaToken, setMediaToken] = useState();

  //Contains all blockchain functions that are responsible for processing purchase payments on the contract.
  const [paymentManager, setPaymentManager] = useState()

  //Contains all blockchain funtions that are responsible for processing nft minting operations on the contract.
  const [claimManager, setClaimManager] = useState()

  //Contains all blockchain funtions that are responsible for processing promo pack operations on the contract.
  const [promoManager, setPromoManager] = useState()

  const [childUpdateNeeded, setChildUpdateNeeded] = useState(false)

  const [baseCards, setBaseCards] = useState([])

  //Contains all of the NFT's that the user owns, including their hash, metadata, and media from IPFS.
  //Also used in the decks page to get a list of cards that can be added to a deck.
  //Doesn't indicate whether any cards are invalid or not.
  const [compendium, setCompendium] = useState(new HashMap())
  const [ownedPacks, setOwnedPacks] = useState(new HashMap())
  const [myPromoPacks, setMyPromoPacks] = useState([])
  const [hasPacks, setHasPacks] = useState(false)
  const [lootRewards, setLootRewards] = useState([])

  //Lists all of the decks the user has created.  
  //Only shows the basic information of the deck.  Doesn't contain any of the card contents in the deck.
  const [decks, setDecks] = useState(new HashMap())
  const [validDecks, setValidDecks] = useState(new HashMap())

  //Used to display how many of each card only belong in the currently editing deck.
  //Also indicates how many (if any exist) cards are invalid within the deck. 
  const [deckCardCounts, setDeckCardCounts] = useState(new HashMap())

  const [selectedGameDeck, setSelectedGameDeck] = useState(0)

  //GamePage states
  const [isMatchMaking, setIsMatchMaking] = useState(false)

  //Used on the store page for the different dispaly sections.
  const [playerPacksSection, setPlayerPacksSection] = useState(new HashMap())
  const [collectorPacksSection, setCollectorPacksSection] = useState(new HashMap())
  const [feelingLuckySection, setFeelingLuckySection] = useState(new HashMap())
  const [promotionalSection, setPromotionalSection] = useState(new HashMap())

  //Maps all the backend cardpacks to their relevant front-end store page sections.
  //Key should be the database pack name, value should be the store page section hashmap that it should go in.
  const [packToSectionMap, setPackToSectionMap] = useState(new HashMap())
  packToSectionMap.set("AoE-Common-Pack-Small", playerPacksSection)
  packToSectionMap.set("AoE-Uncommon-Pack-Small", playerPacksSection)
  packToSectionMap.set("AoE-Rare-Pack-Small", playerPacksSection)
  packToSectionMap.set("AoE-Epic-Pack-Small", collectorPacksSection)
  packToSectionMap.set("AoE-Legendary-Pack-Small", collectorPacksSection)
  packToSectionMap.set("Feeling Lucky", feelingLuckySection)

  //Holds translation of DB name 'AOE-COMMON-PACK-SMALL' to 'Arena of Exiles: Common Pack' etc
  const [packNameDBToUINameMap, setPackNameDBToUINameMap] = useState(new HashMap())
  packNameDBToUINameMap.set("AoE-Common-Pack-Small", "Common Pack")
  packNameDBToUINameMap.set("AoE-Uncommon-Pack-Small", "Uncommon Pack")
  packNameDBToUINameMap.set("AoE-Rare-Pack-Small", "Rare Pack")
  packNameDBToUINameMap.set("AoE-Epic-Pack-Small", "Epic Pack")
  packNameDBToUINameMap.set("AoE-Legendary-Pack-Small", "Legendary Pack")
  packNameDBToUINameMap.set("Feeling Lucky", "Feeling Lucky?")
  packNameDBToUINameMap.set("Promo Pack - Beta", "Promo Pack - Beta")
  packNameDBToUINameMap.set("Promo Pack - Sketch", "Promo Pack - Sketch")

  const [currentChainId, setCurrentChainId] = useState(undefined)
  const [walletType, setWalletType] = useState(undefined)
  const [userAuthExists, setUserAuthExists] = useState(false)
  const [userAccountChanged, setUserAccountChanged] = useState(false)

  //Game running states
  const [isGameLoaded, setIsGameLoaded] = useState(false)
  const [gameLoading, setGameLoading] = useState(false)
  
  return (
    <StoreContext.Provider value = {
    {
      ZERO_ADDRESS,
      childUpdateNeeded, setChildUpdateNeeded,
      webToken, setWebToken,
      userAddress, setUserAddress, 
      userRoles, setUserRoles,
      userName, setUserName,
      connectionText, setConnectionText,
      connectionErrorMsg, setConnectionErrorMsg,
      userRank, setUserRank,
      mediaToken, setMediaToken, 
      paymentManager, setPaymentManager,
      claimManager, setClaimManager,
      promoManager, setPromoManager,
      baseCards, setBaseCards,
      compendium, setCompendium,
      hasPacks, setHasPacks,
      lootRewards, setLootRewards,
      decks, setDecks,
      validDecks, setValidDecks,
      deckCardCounts, setDeckCardCounts,
      ownedPacks, setOwnedPacks,
      myPromoPacks, setMyPromoPacks,
      selectedGameDeck, setSelectedGameDeck,
      isMatchMaking, setIsMatchMaking,
      playerPacksSection, setPlayerPacksSection,
      collectorPacksSection, setCollectorPacksSection,
      feelingLuckySection, setFeelingLuckySection,
      packToSectionMap, setPackToSectionMap,
      packNameDBToUINameMap, setPackNameDBToUINameMap,
      currentChainId, setCurrentChainId,
      walletType, setWalletType,
      userAuthExists, setUserAuthExists,
      userAccountChanged, setUserAccountChanged,
      isGameLoaded, setIsGameLoaded,
      gameLoading, setGameLoading,
    }
      }>

      {children}

    </StoreContext.Provider>
  )

}
export const useStore = () => useContext(StoreContext);