import React from "react";
import {  makeStyles, Popover, Grid } from '@material-ui/core';
import WalletConfig from "./walletConfig";
import WalletButton from "./WalletButton";

const ConnectModal = ({onLoginCallback, onDismissCallback, isPopoverDisplayed, onDisconnectCallback}) => {
    const styles = useStyles();
    const randomBool = false;
    return(
        <Popover 
        open={isPopoverDisplayed} 
        id="connect-popover"
        anchorReference="anchorPosition"
        anchorPosition={{top:window.innerHeight/2, left:window.innerWidth/2}}
        transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
            }}
        PaperProps={{
            style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
            },
            }}
        onClose={()=>onDismissCallback()}>
            <Grid 
            container 
            style={{background: 'rgba(0,0,0,0.7)', color: 'white', justifyContent:'center', alignItems:'center', height:'auto', width:'65vw', minHeight:'50vh', border:'1px solid white'}}
            classes = {{paper: (randomBool) ? styles.popPaper : "" }}
            >
                {
                    WalletConfig.map((walletType) =>
                        <Grid item xs={12}  key={walletType.title}>
                            <WalletButton 
                            onLoginCallback={onLoginCallback} 
                            walletConfig={walletType} 
                            onDisconnectCallback={()=>onDisconnectCallback()}
                            onDismissCallback={()=>onDismissCallback()}/>
                        </Grid>

                    )
                }
            </Grid>
        </Popover>
    )
}

const useStyles = makeStyles((theme) => ({

    popover:{
        pointerEvents:'none',
        alignItems:'center',
        justifyContent:'center'
    },

    popPaper:{
        boxShadow: "none",
        backgroundColor:'rgba(0,0,0,0.5)',

    }
  }));


export default ConnectModal