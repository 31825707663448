import React, { useState, useEffect } from 'react'

import { makeStyles, Grid, Typography, Button, TextField, Divider, CircularProgress, Zoom} from '@material-ui/core';

import { useStore } from "../helpers/GlobalStore"
import { useBackendAPI } from '../helpers/BackendAPI';
import { useContractAPI } from '../helpers/ContractAPI';
import StyledCardContainer from '../components/StyledCardContainer';
import CardPopover from '../components/CardPopover';
import { rarityColors } from "../helpers/GlobalStore"
import CONFIG from "../config/config"
import ethImg from "../assets/images/EthImage.png"
import BackgroundManager from '../components/BackgroundImageManager'
import ArtLoader from "../assets/CardArtProvider"
import SlotChance from '../components/SlotChance';
import CompendiumUtil from "../pages/compendium/CompendiumUtil"
import HashMap from 'hashmap';
import WhatsInsideHandler from '../components/WhatsInsideHandler';

const StorePage = () => {

    const styles = useStyles();
    var compendiumUtil = CompendiumUtil();
    const RELOAD_TIMER_MS = 12000
    const SHOW_SALE_LIMIT = true
    const commonPackKey = 'AoE-Common-Pack-Small';
    const rarePackKey = 'AoE-Rare-Pack-Small';
    const epicPackKey = 'AoE-Epic-Pack-Small';
    const legendaryPackKey = 'AoE-Legendary-Pack-Small';

    const Web3 = require('web3');
    let web3 = new Web3(CONFIG.ETH_NODE);

    const { getCardPacks, addCardsToInventory, feelingLucky, getUserRole, updatePackStats } = useBackendAPI()
    const { userAddress, 
            userRoles,
            paymentManager, 
            ownedPacks,
            packNameDBToUINameMap, 
            playerPacksSection, collectorPacksSection, feelingLuckySection, 
            childUpdateNeeded, setChildUpdateNeeded, 
            lootRewards, setLootRewards
        } = useStore();
    const {getPaymentManager} = useContractAPI();
    const {getArt} = ArtLoader()
    
    const [selectedPack, setSelectedPack] = useState();
    const [buyQuantity, setBuyQuantity] = useState(1);
    const [isProcessing, setIsProcessing] = useState(false)
    const [popover, setPopover] = useState(false)
    const [showDrops, setShowDrops] = useState(false)
    const [purchaseSuccess, setPurchaseSuccess] = useState(undefined)
    const [txMsg, setTxMsg] = useState("")
    const [canExitModal, setCanExitModal] = useState(false)
    const [shouldRefresh, setShouldRefresh] = useState(false)
    const [generatedCard, setGeneratedCard] = useState()
    const [userPackPurchaseCounts, setUserPackPurchaseCounts] = useState(new HashMap())

    const loadPacks = async () => {
        await getCardPacks()
    }

    const loadPayManagerContract = async () => {
        await getPaymentManager()
    }

    const loadUserPackPurchaseCountsAsync = async () => {
        const localPaymentManager = await getPaymentManager()
        if (localPaymentManager && userAddress) {
            const commonPackPurchaseCount = await localPaymentManager.getUserPurchaseCount(userAddress, commonPackKey)
            const rarePackPurchaseCount = await localPaymentManager.getUserPurchaseCount(userAddress, rarePackKey)
            const epicPackPurchaseCount = await localPaymentManager.getUserPurchaseCount(userAddress, epicPackKey)
            const legendaryPackPurchaseCount = await localPaymentManager.getUserPurchaseCount(userAddress, legendaryPackKey)
            
            userPackPurchaseCounts.set(commonPackKey, commonPackPurchaseCount.toNumber())
            userPackPurchaseCounts.set(rarePackKey, rarePackPurchaseCount.toNumber())
            userPackPurchaseCounts.set(epicPackKey, epicPackPurchaseCount.toNumber())
            userPackPurchaseCounts.set(legendaryPackKey, legendaryPackPurchaseCount.toNumber())
            setChildUpdateNeeded(true)
        }
    }

    useEffect(() => {
        loadPacks()
        loadUserPackPurchaseCountsAsync()

        if (!SHOW_SALE_LIMIT) {
            const updateInterval = setInterval(() => {
                updatePackStatsAsync()
            }, RELOAD_TIMER_MS)
            return () => {
                clearInterval(updateInterval)
            }
        }
    }, [])

    useEffect(() => {
        if(childUpdateNeeded) {
            setChildUpdateNeeded(false)
        }
    }, [childUpdateNeeded])
    
    useEffect(() => {
        if((paymentManager === undefined || paymentManager === null) && (userRoles !== null && userRoles !== undefined && userRoles !== '')){
            loadPayManagerContract()
        }
        loadUserPackPurchaseCountsAsync()

    }, [userRoles])

    const updatePackStatsAsync = async () => {
        console.log('updating pack stats')
        updatePackStats()
    }

    const handlePackClick = async (pack) => {
        setSelectedPack(pack)
        setShowDrops(false)
        setPurchaseSuccess(undefined)
        setPopover(true)
    }
    
    const showPrices = () => {
        return CONFIG.SHOW_PRICE || userRoles === "admin" || userRoles === "betatester";
    }

    const getFloatPrecision = (value) => {

        const valueStr = value.toString().split(".")
        let precision = 0;

        if(valueStr.length === 1){
            precision = 1
        }
        else{
            precision = valueStr[1].length
        }

        return precision
    }

    const handleBuyClick = async () => {

        try{
            const buyQuantityParsed = parseInt(buyQuantity)

            const precision = getFloatPrecision(selectedPack.price)
            const floatPrice = parseFloat(selectedPack.price).toFixed(precision)
            const totalPrice = ((floatPrice * buyQuantityParsed).toFixed(precision + 1)).toString()
    
            let wei = web3.utils.toWei(totalPrice);
    
            let purchaseRequest

            //User requested to buy a standard pack (not Feeling Lucky)
            if(selectedPack.packName !== "Feeling Lucky"){
                purchaseRequest = await paymentManager.purchaseCardPack(buyQuantityParsed, selectedPack.packName, "", {value: wei})

                setShouldRefresh(false)
                setIsProcessing(true)
                setTxMsg("In Progress")
                setCanExitModal(false)

                const purchaseResult = await purchaseRequest.wait()
        
                if(purchaseResult.status === 1){
                    // Update local pack purchase count
                    const purchaseCount = userPackPurchaseCounts.get(selectedPack.packName) + buyQuantityParsed;
                    userPackPurchaseCounts.set(selectedPack.packName, purchaseCount)

                    setChildUpdateNeeded(true)
                    const mintedNftsEvent = purchaseResult.events.find((evt) => evt.event === 'NftsBulkMinted');
                    
                    const newlyMintedIDs = mintedNftsEvent.args['newlyMintedIDs'];
                    
                    const mintIdStrings = newlyMintedIDs.map(id => {
                        return id.toString()
                    })
                    
                    const addRequest = {
                        PackId: selectedPack.packId,
                        UniqueIds: mintIdStrings
                    }
                    await addCardsToInventory(addRequest).then(res => {
                        setSelectedPack(undefined)
                        setIsProcessing(false)
                        setBuyQuantity(1)
                        setCanExitModal(true)

                        if(lootRewards && lootRewards.length > 0){
                            //Success case
                            setPurchaseSuccess(undefined) //don't want to show a success/failure message, just the results
                        }
                        else {
                            //Failure case
                            setTxMsg("Purchase Failed!  Something went wrong in our server.  Please try again.")
                            setPurchaseSuccess(false)
                        }

                        setShouldRefresh(true)
                    })

                }
                else{
                    setIsProcessing(undefined)
                    setSelectedPack(undefined)
                    setTxMsg("Purchase Failed!")
                    setCanExitModal(true)
                    setPurchaseSuccess(false)
                    console.log("Transaction failed within the blockchain.")
                }
            }
            
            //User requested to buy a Feeling Lucky pack.  Call a separate api endpoint and skip logging the purchase.
            else {
                purchaseRequest = await paymentManager.feelingLucky({value: wei})

                setShouldRefresh(false)
                setIsProcessing(true)
                setTxMsg("In Progress")
                setCanExitModal(false)
        
                const purchaseResult = await purchaseRequest.wait()
                if(purchaseResult.status === 1){
                    
                    const tokensClaimedEvent = purchaseResult.events.find((evt) => evt.event && evt.event === 'NftMinted');

                    //if(tokensClaimedEvent !== undefined)
                    const newlyMintedID = tokensClaimedEvent.args['uniqueId'];
                    const request = {
                        PackId: selectedPack.packId,
                        UniqueIds: [newlyMintedID.toString()]
                    }

                    await feelingLucky(request).then(res => {

                        //Generation succeeded, retrieved a single card.
                        if(res.length > 0){
                            setIsProcessing(undefined)
                            setTxMsg("")
                            setCanExitModal(true)
                            setSelectedPack(undefined)
                            setPurchaseSuccess(undefined)
                            setShowDrops(undefined)
                            setGeneratedCard(res)
                        }

                        //Generation Failed.  Nothing given back.
                        else {
                            setIsProcessing(undefined)
                            setSelectedPack(undefined)
                            setTxMsg("Something failed in our server.  Please go to the profile page and sync your wallet.")
                            setCanExitModal(true)
                            setPurchaseSuccess(false)
                        }

                        setShouldRefresh(true)
                    })
                }
                else{
                    setSelectedPack(undefined)
                    setTxMsg("Purchase Failed!")
                    setCanExitModal(true)
                    setPurchaseSuccess(false)
                    console.log("Transaction failed within the blockchain.")
                }
            }

            
        }
        catch(err){
            let message;

            if (err && err.error && err.error.message){
                message = err.error.message
            } else if (err && err.message){
                message = err.message
            } else if (err && err.data && err.data.message) {
                message = err.data.message
            } else {
                message = "An unknowable error occured"
            }

            message = message.replace("execution reverted: ", "")

            //If the user requests to buy past their limit, give a more helpful error.
            if(message === "This account has reached the purchase limit for this pack"){
                let max
                let ownedPack = ownedPacks.get(selectedPack.packId)
                
                if(ownedPack === undefined)
                    max = selectedPack.maxAllowedPurchases

                else {
                    max = selectedPack.maxAllowedPurchases - ownedPack.numBought
                }

                message = `You can only buy ${max} of these packs!`
            }

            setSelectedPack(undefined)
            setBuyQuantity(1)
            setCanExitModal(true)
            setTxMsg(message)
            setPurchaseSuccess(false)
            setIsProcessing(false)
        }

    }

    const handlePopoverClose = () => {
        setPopover(false)
        setIsProcessing(false)
        setBuyQuantity(1)
        setSelectedPack(undefined)
        setShowDrops(false)
        setPurchaseSuccess(undefined)
        setCanExitModal(false)
        setTxMsg("")
        setGeneratedCard(undefined)
        setShouldRefresh(true)
        setLootRewards([])
    }

    const isPurchaseLimitReached = () => {
        let pack =  ownedPacks.get(selectedPack.packId)

        if(pack === undefined)
            return false

        let numPacksPurchased = pack.numBought
        return (selectedPack.maxAllowedPurchases > 0 && Number(buyQuantity) + Number(numPacksPurchased) > selectedPack.maxAllowedPurchases)
    }

    const getOwnedPackCount = () => {
        const ownedPack = ownedPacks.get(selectedPack.packId)

        if(ownedPack === undefined)
            return 0

        else return ownedPack.numBought
    }

    const getCardRarity = (card) => {
        let tempCard = compendiumUtil.getCopyOfEntryForCardId(card.mediaHash)
        return tempCard.rarityId
    }

    const getPopoverColor = () => {
        if(purchaseSuccess === undefined && selectedPack)
            return `${selectedPack.rarity}`

        else if(purchaseSuccess === false)
            return "0"

        else if(generatedCard)
            return `${getCardRarity(generatedCard[0])}`

        else return "16"
    }

    const whatsInside = (pack) => {
        setSelectedPack(pack)
        setShowDrops(true)
    }

    const isWhitelisted = () => {
        return true;
    }

    const canUserPurchaseMore = (pack) => {
        if (pack) {
            if (pack.maxAllowedPurchases === 0)
                return true;

            let currentPurchaseCount = userPackPurchaseCounts.get(pack.packName)
            if (currentPurchaseCount === null || currentPurchaseCount === undefined)
                currentPurchaseCount = 0
            const canPurchase = currentPurchaseCount < pack.maxAllowedPurchases
            console.log(`can user purchase ${pack.packName}: ${currentPurchaseCount} < ${pack.maxAllowedPurchases} ? ${canPurchase}.`)
            return canPurchase
        }

        return false
    }

    const playerPacksRender = (
        <Grid item>

            <Grid item style = {{backgroundColor: 'rgba(0,0,0,.7)'}}>
                <Typography variant = "h4" style = {{color: "white", textAlign: "center"}}>Player Packs</Typography>
            </Grid>

            <Grid container style = {{display: "flex", justifyContent: "center"}}>

                {playerPacksSection.values().map(pack => (
                    <Grid item key = {`playerPacks-ItemContainer-${pack.packId}`} style = {{backgroundColor: 'rgba(0,0,0,.7)'}}>
                        <Grid container style={{justifyContent:'center', alignItems:'center'}} className = {styles.packItem} >

                            <Grid item className = {styles.packImage}>
                            {isWhitelisted() && canUserPurchaseMore(pack) ?
                                <StyledCardContainer
                                imgName = {pack.packName}
                                count = {1}
                                rarity = {`${pack.rarity}`}
                                onClick = {() => handlePackClick(pack)}
                                hover
                                />
                                :
                                <StyledCardContainer
                                imgName = {pack.packName}
                                count = {1}
                                rarity = {`${pack.rarity}`}
                                />
                            }
                            </Grid>

                            <Grid item xs = {12}>
                                <Typography variant = "h5" style = {{color: `rgb(${rarityColors[pack.rarity]})`, textAlign: "center", fontWeight: "bold"}}>{packNameDBToUINameMap.get(`${pack.packName}`)}</Typography>
                            </Grid>
                            
                            <Grid item key = {`lootButton-pack-${pack.packId}-container`}>
                                <Button className = "buttonRibbon" disabled = {!canUserPurchaseMore(pack)} onClick = {() => whatsInside(pack)}>
                                    Whats Inside?
                                </Button>
                            </Grid>

                            <Grid item xs = {12}>
                                <Grid container style={{justifyContent:'center', alignItems:'center', marginBottom:'20px'}}>
                                    <Grid item >
                                        <img src={ethImg} alt="Ethereum Icon" className={styles.packPriceIcon} />
                                    </Grid>

                                    <Grid item style = {{marginLeft: 5}}>
                                        <Typography 
                                        variant = "h6" 
                                        style = {{
                                            color: "white",
                                            fontSize:'20pt',
                                            fontWeight:'bold'}}>
                                            {showPrices() ? pack.price : "TBD"}
                                        </Typography>
                                    </Grid>
                                    
                                    {pack.maxAllowedPurchases > 0 ?
                                        <Grid item xs = {12}>
                                            <Typography variant = "body2" style = {{
                                                textAlign: "center", 
                                                color: "white",
                                                fontSize:'12pt'}}
                                            >
                                                {
                                                    userPackPurchaseCounts.get(pack.packName) !== undefined &&
                                                    userPackPurchaseCounts.get(pack.packName) !== null
                                                    ? `User Purchase Limit: ${userPackPurchaseCounts.get(pack.packName)} / ${pack.maxAllowedPurchases}`
                                                    : 'User Purchase Limit: Loading...'
                                                }
                                            </Typography>
                                        </Grid>
                                        :
                                        <></>
                                    }
                                    {pack.capacity > 0 &&
                                    <Grid item xs = {12} style = {{margin: 5}}>
                                        <Typography 
                                        variant = "body2" 
                                        style = {{
                                            textAlign: "center", 
                                            color: "white",
                                            fontSize:'12pt' 
                                        }}
                                        >
                                            {SHOW_SALE_LIMIT ? `${pack.capacity} Sale Limit` : `${pack.amtSold} ${(pack.capacity > 1) ? " / " + pack.capacity : ""} Sold`}
                                        </Typography>
                                    </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )

    const feelingLuckyRender = () => {

        let pack = feelingLuckySection.values()[0]

        return(
            <Grid item>
                <Grid container style={{justifyContent:'center', alignItems:'center', backgroundColor: 'rgba(0,0,0,.7)'}} className = {styles.feelingLuckyItem}>
                    <Grid item className = {styles.feelingLuckyImage}>
                    {isWhitelisted() ?
                        <StyledCardContainer
                        imgName = {pack.packName}
                        count = {1}
                        rarity = {`${pack.rarity}`}
                        onClick = {() => handlePackClick(pack)}
                        hover
                        />
                        :
                        <StyledCardContainer
                        imgName = {pack.packName}
                        count = {1}
                        rarity = {`${pack.rarity}`}
                        />
                    }
                    </Grid>
                    
                    <Grid item key = {`lootButton-pack-${pack.packId}-container`}>
                        <Button className = "buttonRibbon" onClick = {() => whatsInside(pack)}>
                            Whats Inside?
                        </Button>
                    </Grid>

                    <Grid item xs = {12}>

                        <Grid container style={{justifyContent:'center', alignItems:'center', marginBottom:'20px'}}>
                            <Grid item >
                                <img src={ethImg} alt="Ethereum Icon" className={styles.packPriceIcon} />
                            </Grid>

                            <Grid item style = {{marginLeft: 5}}>
                                <Typography 
                                    variant = "h6" 
                                    style = {{
                                        color: "white",
                                        fontSize:'20pt',
                                        fontWeight:'bold' }}>
                                    {showPrices() ? pack.price : "TBD"}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const collectorPacksRender = (
        <Grid item>

            <Grid item style = {{backgroundColor: 'rgba(0,0,0,.7)'}}>
                <Typography variant = "h4" style = {{color: "white", textAlign: "center"}}>Collector Packs</Typography>
            </Grid>

            <Grid container style = {{display: "flex", justifyContent: "center"}}>

                {collectorPacksSection.values().map(pack => (
                    <Grid item key = {`playerPacks-ItemContainer-${pack.packId}`} style = {{backgroundColor: 'rgba(0,0,0,.7)'}}>
                        <Grid container style={{justifyContent:'center', alignItems:'center'}} className = {styles.packItem} >

                            <Grid item className = {styles.packImage}>
                            {isWhitelisted() && canUserPurchaseMore(pack) ?
                                <StyledCardContainer
                                imgName = {pack.packName}
                                count = {1}
                                rarity = {`${pack.rarity}`}
                                onClick = {() => handlePackClick(pack)}
                                hover
                                />
                                :
                                <StyledCardContainer
                                imgName = {pack.packName}
                                count = {1}
                                rarity = {`${pack.rarity}`}
                                />
                            }
                            </Grid>

                            <Grid item xs = {12}>
                                <Typography variant = "h5" style = {{color: `rgb(${rarityColors[pack.rarity]})`, textAlign: "center", fontWeight: "bold"}}>{packNameDBToUINameMap.get(`${pack.packName}`)}</Typography>
                            </Grid>
                            
                            <Grid item key = {`lootButton-pack-${pack.packId}-container`}>
                                <Button className = "buttonRibbon" disabled={!canUserPurchaseMore(pack)} onClick = {() => whatsInside(pack)}>
                                    Whats Inside?
                                </Button>
                            </Grid>

                            <Grid item xs = {12}>

                                <Grid container style={{justifyContent:'center', alignItems:'center', marginBottom:'20px'}}>
                                    <Grid item >
                                        <img src={ethImg} alt="Ethereum Icon" className={styles.packPriceIcon} />
                                    </Grid>

                                    <Grid item style = {{marginLeft: 5}}>
                                        <Typography 
                                            variant = "h6" 
                                            style = {{
                                                color: "white",
                                                fontSize:'20pt',
                                                fontWeight:'bold' }}>
                                            {showPrices() ? pack.price : "TBD"}
                                        </Typography>
                                    </Grid>
                                    {pack.maxAllowedPurchases > 0 ?
                                        <Grid item xs = {12}>
                                            <Typography variant = "body2" style = {{
                                                textAlign: "center", 
                                                color: "white",
                                                fontSize:'12pt'}}
                                            >
                                                {
                                                    userPackPurchaseCounts.get(pack.packName) !== undefined &&
                                                    userPackPurchaseCounts.get(pack.packName) !== null
                                                    ? `User Purchase Limit: ${userPackPurchaseCounts.get(pack.packName)} / ${pack.maxAllowedPurchases}`
                                                    : 'User Purchase Limit: Loading...'
                                                }
                                            </Typography>
                                        </Grid>
                                        :
                                        <></>
                                    }
                                    {pack.capacity > 0 &&
                                    <Grid item xs = {12} style = {{margin: 5}}>
                                        <Typography 
                                        variant = "body1" 
                                        style = {{
                                            textAlign: "center", 
                                            color: "white"
                                        }}
                                        >
                                            {SHOW_SALE_LIMIT ? `${pack.capacity} Sale Limit` : `${pack.amtSold} ${(pack.capacity > 1) ? " / " + pack.capacity : ""} Sold`}
                                        </Typography>
                                    </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
           
        </Grid>
    )

    const popoverContents = () => {

        let elements = null
    
        if (isProcessing)
            elements = 
                <Grid container style={{justifyContent:'center', alignItems:'center', padding: 10, overflowY: "hidden", width: "auto", height: "auto"}}>
                    <Grid item xs = {12} style = {{marginBottom: 10}}>
                        <Typography variant = "h6" style = {{textAlign: "center", color: "white", textEmphasis: "bold"}}>Processing Request</Typography>
                    </Grid>

                    <Grid item>
                        <CircularProgress
                        />
                    </Grid>
                </Grid>

        else if (lootRewards && lootRewards.length > 0){
            elements = 
                <Grid container className = {styles.rewardsContainer }>
                    
                    {lootRewards.map((card, index) => (
                        <Zoom 
                        in = {lootRewards !== undefined && lootRewards.length > 0}
                        style = {{
                            transitionDelay: `${150 + ((index + 1) * 200)}ms`
                        }}
                        key = {`lootItem-${card.mediaHash}-Zoom`}
                        >
                            <Grid item key={`lootItem-${card.mediaHash}-MainContainer`}>
                                <Grid container>
                                    <Grid key = {`lootItem-${card.mediaHash}-CardContainer`}  item className = {styles.rewardItem} style={{height:'210px', width:'150px'}}>
                                        <StyledCardContainer
                                        rarity={getCardRarity(card)} 
                                        count={1} 
                                        imgName={card.mediaHash} 
                                        dropShadow
                                        />
                                    </Grid>

                                    <Grid item key = {`lootItem-${card.mediaHash}-TextContainer`} xs = {12}>
                                    <Typography
                                    variant = "body1"
                                    className = {`${styles.text} ${styles.textCentered}`}
                                    >
                                        {`x ${card.count}`}
                                    </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Zoom>
       
                    ))
            
                    }
                </Grid>
        }

        else if (!isProcessing && (selectedPack !== null && selectedPack !== undefined) && !showDrops && purchaseSuccess === undefined)
            elements = 
                <Grid item className = {styles.popContainer}>
                    <Grid container spacing = {1} style={{justifyContent:'center', alignItems:'center'}}>

                        <Grid item xs = {12} style = {{marginBottom: 10}}>
                            <Typography 
                            variant = "h3" 
                            style={{ 
                                textAlign: "center", 
                                color: `rgb(${rarityColors[selectedPack.rarity]})`}}
                            >
                                {packNameDBToUINameMap.get(selectedPack.packName)}
                            </Typography>
                        </Grid>

                        <Grid item xs = {12}>
                            <Typography variant = "body1" style = {{textAlign: "center"}}>{selectedPack.description}</Typography>
                        </Grid>

                        <Grid item xs = {12} style = {{marginBottom: 10}}>
                            <Divider
                            style = {{backgroundColor: "white"}}
                            />
                        </Grid>

                        <Grid item xs = {12}>
                            <Typography variant = "body1" style = {{textAlign: "center"}}>{`(${selectedPack.numCards} cards per pack)`}</Typography>
                        </Grid>

                        <Grid item xs = {12}>
                            {(userAddress === null || userAddress === undefined) ?
                            
                            <Typography variant = "h4" style = {{textAlign: "center"}}>Login to Buy</Typography>
                            
                            :

                            <Grid container style={{justifyContent:'center', alignItems:'center'}} spacing = {2}>
                                
                                <Grid item>
                                    <Button
                                    className = "buttonRibbon"
                                    variant = "contained"
                                    disabled = {isNaN(+buyQuantity) || isPurchaseLimitReached() || Number(buyQuantity) < 1}
                                    onClick = {() => handleBuyClick()}
                                    >
                                        Buy
                                    </Button>
                                </Grid>

                                {selectedPack.packName !== "Feeling Lucky" && 
                                <Grid 
                                item 
                                style = {{
                                    marginRight: 15,
                                    marginLeft: 20
                                }}
                                >
                                    <TextField 
                                    id="standard-textarea"
                                    label = "Quantity"
                                    variant="outlined"
                                    type="number"
                                    className = {styles.numFieldProp}
                                    InputProps = {{className: styles.numFieldProp}}
                                    InputLabelProps = {{className: styles.numFieldLabel}}
                                    value = {buyQuantity}
                                    error = {selectedPack.maxAllowedPurchases === 0 && isPurchaseLimitReached()}
                                    helperText = {(selectedPack.maxAllowedPurchases === 0 && isPurchaseLimitReached()) ? 
                                                    "You have reached the limit for ownedPacks of this pack!" : ""
                                    }
                                    onChange = {(e) => setBuyQuantity(e.target.value)}
                                    />
                                </Grid>
                                }

                                <Grid item>
                                    <Grid container style={{justifyContent:'center', alignItems:'center'}}>
                                        <Grid item >
                                            <img src={ethImg} alt="Ethereum Icon" className={styles.packPriceIcon}/>
                                        </Grid>

                                        <Grid item>
                                            <Typography variant = "h6">{(Number(selectedPack.price) * Number(buyQuantity)).toFixed(6)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            }
                        </Grid>
                    </Grid>
                </Grid>

        else if(purchaseSuccess !== undefined)
            elements = 
            <Grid item style={{padding: 10}}>
                <Typography variant="h4" style = {{textEmphasis: "bold", color: (purchaseSuccess)? "green" : "red", textAlign: "center"}}>{txMsg}</Typography>
            </Grid>
        
        else if(generatedCard)
            elements = 
            <Grid item style={{padding: 10}}>
                <StyledCardContainer
                imgName = {generatedCard[0].mediaHash}
                count = {1}
                rarity = {`${getCardRarity(generatedCard[0])}`}
                width = "300px"
                height = "420px"
                />
            </Grid>

        return elements
    }

    return (
        <Grid container spacing = {4} style = {{width: "auto", alignItems:'flex-start', justifyContent:'center'}}>
           
            <Grid container>
                <BackgroundManager sourceImage={getArt('DeckPageBG')} blur='0px' />
            </Grid>

            {(isProcessing || (selectedPack !== undefined && !showDrops) || purchaseSuccess !== undefined || generatedCard) &&
            <Grid item>
                <CardPopover
                rarity = {getPopoverColor()}
                count = {1}
                contents = {popoverContents()}
                popover = {popover}
                closePopover = {() => handlePopoverClose()}
                dropShadow
                disableClick = {txMsg !== ""}
                disableStatus = {canExitModal}
                hideBackground = {generatedCard}
                />
            </Grid>
            }

            {showDrops &&
            <Grid item>
                <WhatsInsideHandler pack = {selectedPack} popoverClose={() => handlePopoverClose()}/>
            </Grid>
            }

            {/* <Grid item className = "newdc" style={{backgroundColor:'rgba(0,0,0,.5)', justifyContent:'center', paddingLeft: 25, paddingRight: 25, marginTop: 50}}>
                <Typography variant = "h3" style = {{color: "white"}}>30% Discount until January 20th!</Typography>
            </Grid> */}

            <Grid item xs = {12}/>
            
            <Grid item container spacing = {4} xs = {11} sm = {10} md = {9} lg = {8} style = {{display: "flex", justifyContent:'center', alignItems:'center'}}>
                <Grid item>
                    {playerPacksSection.size > 0 &&
                    playerPacksRender
                    }
                </Grid>
            </Grid>

            <Grid item container spacing = {4} xs = {11} sm = {10} md = {9} lg = {8} style = {{display: "flex", justifyContent:'center', alignItems:'center'}}>
                <Grid item>
                    {collectorPacksSection.size > 0 &&
                    collectorPacksRender
                    }
                </Grid>

            
            </Grid>
            <Grid item container spacing = {4} xs = {11} sm = {10} md = {9} lg = {8} style = {{display: "flex", justifyContent:'center', alignItems:'center'}}>
                    {feelingLuckySection.size > 0 &&
                    feelingLuckyRender()
                    }
            </Grid>


        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({

    packItem: {
        width: 250
    },

    packImage: {
        cursor: "pointer",
        width: 215,
        height: 300,
        margin: 20,
        padding: 0
    },

    feelingLuckyItem:{
        width: 250
    },

    feelingLuckyImage: {
        cursor: "pointer",
        width: 200,
        height: 50,
        margin: 20,
        padding: 0
    },

    packPriceIcon: {
        height: 20,
        width: 20,
        margin: 0,
        padding: 0,
        backgroundColor: 'transparent'
    },

    selectedPackPanel:{
        marginBottom: 10
    },

    disablePointer: {
        pointerEvents: "disable"
    },
    rewardsContainer: {
        overflowX: "hidden",
        overflowY: "auto",
        height:'auto',
        width: 'auto',
        padding:'25px',
        background: '#080c0d',
        boxShadow: "none",
        textAlign:"center",
        justifyContent: "center",
        color:'white'
    },
    popContainer: {
        overflowX: "hidden",
        overflowY: "hidden",
        maxWidth: 800,
        width: 'auto',
        height: "auto",
        padding:'10px',
        margin:'0px',
        backgroundColor:'#181c24',
        color:'white',
    },

    slotsContainer: {
        display: "flex",
        overflowY: "hidden",
        overflowX: "hidden",
        width: 'auto',
        height: "auto",
        padding:'10px',
        alignItems: "center",
        justifyContent: "center"
    },

    loaderContainer: {
        pointerEvents: "none",
        backgroundColor: "#080c0d",
        width: "auto",
        height: "auto",
        margin: 20
    },
    
    rewardItem: {
        width: 250,
        height: "auto",
        margin: 20
    },

    numFieldProp: {
        height: '36px',
        color: "white",
        background: "rgba(232, 241, 250, 0.05)",
        borderBottom: "1px solid #808080"
    },

    numFieldLabel:{
        color: "white"
    },

}))

export default StorePage
