import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AccountsProvider } from './helpers/AccountsProvider';
import { getLibrary } from './helpers/web3ReactUtil';
import { Web3ReactProvider } from '@web3-react/core';
import { StoreProvider } from "./helpers/GlobalStore";

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider
    getLibrary={()=>getLibrary(window.ethereum)}
    >
      <AccountsProvider>
        <StoreProvider>
          <App/>
        </StoreProvider>
      </AccountsProvider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
