import React from 'react'
import { Grid, Typography, makeStyles } from '@material-ui/core';
import BackgroundManager from '../components/BackgroundImageManager'
import ArtLoader from "../assets/CardArtProvider"

const LorePage = () => {

    const styles = useStyles()
    const { getArt } = ArtLoader();
 
    return (
        <div style={{width:'100vw'}}>
            <BackgroundManager sourceImage={getArt('LorePageBG')} blur='0px' />

            <Grid justifyContent='center' container style={{maxWidth:'70vw', padding:'auto', margin:'auto', textAlign:'center', paddingTop:'100px'}}>
                <Grid item xs = {12} style={{marginBottom:'15px'}}>
                    <Typography variant = "h4" className = {`${styles.textWhite} ${styles.textCentered}`}>
                        Welcome Exile! 
                    </Typography>
                </Grid>

                <Grid item  style={{textAlign:'center', width:'100%'}}>
                    <Typography variant = "h6" className = {`${styles.textWhite} ${styles.textCentered}`} style={{marginTop:'1em'}}>
                    ...to this forsaken world of exiled gods such as ourselves.
                    </Typography>
                    <Typography variant = "h6" className = {`${styles.textWhite} ${styles.textCentered}`} style={{marginTop:'1em'}}>
                    I am Lucius the Unforgiven. 
                    My crimes against our godhood were unforgivable and I can never return, but you …you have an opportunity. 
                    The elder gods have proclaimed that those who fight and attain glory in the Arena may be pardoned of their sins.
                    </Typography>
                    <Typography variant = "h6" className = {`${styles.textWhite} ${styles.textCentered}`} style={{marginTop:'2em'}}>
                    Employ the powerful champions of this land. Feed them your godly energy. 
                    Have them fight to gain glory in the Arena of Exiles!
                    </Typography>
                    <Typography variant = "h6" className = {`${styles.textWhite} ${styles.textCentered}`} style={{marginTop:'2em'}}>
                    Only when you climb over the charred remains of your fallen opponents can you ascend to freedom.
                    </Typography>

                </Grid>
                
                <Grid container spacing={3} alignItems="center" style={{
                                maxWidth:'1600px', marginLeft:'auto', marginRight:'auto', textAlign:'center', justifyContent: "center"}}>
                    <Grid item xs={10} sm={10} md={8} lg={8} style={{
                                    display:'flex', flexDirection:'column', height:'100%' }}>
                        <img alt='The Unforgiven' src={getArt('TheUnforgiven')}/>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({

    textWhite: {
        color: "white",
        width: "auto"
    },

    textCentered: {
        textAlign: "center"
    },

}));

export default LorePage
