import React from 'react'

import { Grid} from '@material-ui/core';

import TabBar from "../components/TabBar"
import InventoryCardsComponent from '../components/InventoryCardsComponent';
import InventoryPromosComponent from '../components/InventoryPromosComponent';
import InventorySpecialComponent from '../components/InventorySpecialComponent';
import ArtLoader from "../assets/CardArtProvider";
import BackgroundManager from '../components/BackgroundImageManager';


const InventoryPage = () => {

    const { getArt } = ArtLoader();

    return (
        <Grid container style = {{justifyContent:'center', alignItems:'center'}}>
            <BackgroundManager sourceImage={getArt('InventoryBG')} blur='0px' />
            <TabBar
            tabNames = {["Cards", "Promos", "Special"]}
            tabStates = {[
                true, 
                true,
                true
            ]}
            tabContents = {[
                <InventoryCardsComponent/>, 
                <InventoryPromosComponent/>,
                <InventorySpecialComponent/>
            ]}
            />

        </Grid>
    )
}

export default InventoryPage
