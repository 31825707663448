
// maps from 'AttackAppearance' value in master json to the attack animation key used by phaser

const AttackAppearances = {

    DEFAULT: 'swipe-attack',       // For attacks like werewolf etc.
    FROST: 'frost-attack',         // Ice/Frost element
    FIRE: 'fire-attack',          // Fire element (temporarily using frost)
    DECAY: 'poison-attack',        // Decay, poison and acid will temporarily use the same animation.
    POISON: 'poison-attack',
    ACID: 'poison-attack',
    LIGHT: 'light-attack',         // Light element
    DARK: 'dark-attack',          // Dark element
    LIGHTNING: 'lightning-attack',     // Lightning element

}

AttackAppearances.getEnum = (attackString) => {
    
        switch (attackString){
            case 'DEFAULT':
                return AttackAppearances.DEFAULT;
            case 'FROST':
                return AttackAppearances.FROST;
            case 'FIRE':
                return AttackAppearances.FIRE;
            case 'DECAY':
                return AttackAppearances.DECAY;
            case 'POISON':
                return AttackAppearances.POISON;
            case 'ACID':
                return AttackAppearances.ACID;
            case 'LIGHT':
                return AttackAppearances.LIGHT;
            case 'DARK':
                return AttackAppearances.DEFAULT;
            case 'LIGHTNING':
                return AttackAppearances.LIGHTNING;
            default:
                return AttackAppearances.DEFAULT;
        }
    
}

export default  AttackAppearances