import React from 'react';

import { useStore } from "../helpers/GlobalStore";
import AdminBaseCardComponent from "../components/AdminBaseCardComponent"
import AdminCardPackComponent from '../components/AdminCardPackComponent';
import TabBar from "../components/TabBar"
import { Grid} from '@material-ui/core';

const AdminPage = () => {

    const { userRoles } = useStore();

    return (
        (userRoles === "admin") &&
        <Grid item style = {{marginTop: 100, width: "100vw"}}>
            <TabBar
            tabNames = {[
                "Upload Cards", "Upload Card Packs"]}
    
            tabContents = {[
                <AdminBaseCardComponent/>,
                <AdminCardPackComponent/>]}
    
            tabStates = {[true, true]}
            />
        </Grid>
    )
}


export default AdminPage;